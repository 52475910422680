// project modules
import Popup from '../shared/popup/popup';

// models
import { Account } from '../../models/account';
import AccountsTable from './AccountsTable';

// defines
type Props = {
  closeOnSave?: boolean;
  open: boolean;
  onClose?: () => void;
  onSelect?: (account: Account) => void;
};

export default function AccountPickerModal({ closeOnSave = false, open, onClose, onSelect }: Props) {
  const onModalClose = () => {
    if(onClose) onClose();
  };

  const onItemSelect = (account: Account) => {
    if (onSelect) {
      onSelect(account);
    }

    if (closeOnSave) {
      open = false;

      onModalClose();
    }
  };

  return (
    <Popup
      title="Select Account"
      onCancel={onModalClose}
      onClose={onModalClose}
      noCommandbar={true}
      fixSize="x-large"
    >
      <AccountsTable
        onSelect={onItemSelect} />
    </Popup>
  );
}
