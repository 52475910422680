// 3rd-party modules
import { message } from 'antd';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";

// project modules
import Button from '../../shared/button';
import ConfirmationPopup from '../../shared/popup/confirmationPopup';
import Input from "../../shared/inputs/input";
import Loader from "../../shared/loader";
import Select from "../../shared/inputs/select";
import yup from "../../../plugins/yup";
import { apiCall } from "../../../helpers/apiHelper";
import { trimStringProps } from '../../../helpers/objectHelper';

// apis
import * as AccountLocationApi from '../../../apis/accountLocationApi';
import * as CommonApi from '../../../apis/commonApi';

// models
import { Account } from '../../../models/account';
import { AccountLocation } from '../../../models/accountLocation';
import { accountLocationInsertViewModel } from '../../../models/types/accountLocation';
import { ApiResponse } from "../../../models/response";
import { Country } from '../../../models/country';
import { State } from '../../../models/state';

type Props = {
  refresh: boolean;
  account: Account;
  accountLocation: AccountLocation;
  shouldAddBilling: boolean;
  cancelText?: string;
  okText?: string;
  isFromSignup?: boolean;
  onClose?: () => void;
  onSave?: (accountLocation: any) => void;
  onBillingAddressChange?: () => void;
};

const getCountries = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(CommonApi.getCountries(abortSignal));

  return response.success ? Country.toArrayOfClass(response.data?.value || []) : [];
};

const getStates = async (country: string, abortSignal?: AbortSignal) => {
  const response = await apiCall(CommonApi.getStates(country, abortSignal));

  return response.success ? State.toArrayOfClass(response.data?.value || []) : [];
};

export default function AccountLocationForm({ refresh, account, accountLocation, shouldAddBilling = false, cancelText = "Cancel", okText = "Save Changes", isFromSignup = false, onClose, onSave, onBillingAddressChange }: Props) {
  const schema = yup.object().shape({
    addressLine1: yup.string().label("Address Line 1").max(256).required(),
    addressLine2: yup.string().label("Address Line 2").max(256),
    addressLine3: yup.string().label("Address Line 3").max(256),
    billingAddress: yup.bool().label("Billing Address"),
    city: yup.string().label("City").max(256).required(),
    country: yup.string().label("Country").required(),
    // locationName: yup.string().label("Location Name").max(250).required(),
    stateProvince: yup.string().label("State/Province").required(),
    zipPostal: yup.string().label("Postal Code").max(10).required(),
  });
  const { control, handleSubmit, reset, setValue, trigger } = useForm<accountLocationInsertViewModel | any>({
    defaultValues: useMemo(() => accountLocation, [accountLocation]),
    resolver: yupResolver(schema),
  });
  const [countries, setCountries] = useState<Country[]>([]);
  const [isChangeBillingAdressConfirmationPopupOpen, setIsChangeBillingAdressConfirmationPopupOpen] = useState(false);
  const [states, setStates] = useState<State[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingCountryList, setLoadingCountryList] = useState(false);
  const [loadingStateList, setLoadingStateList] = useState(false);
  const abortController = new AbortController();

  useEffect(() => {
    if (refresh) {
      const getCountriesAsync = async () => {
        setLoadingCountryList(true);
        setCountries(await getCountries(abortController.signal));
        setLoadingCountryList(false);
      }

      getCountriesAsync();

      if (accountLocation.accountLocationId && accountLocation.country) {
        getStatesAsync(accountLocation.country)
      }
    }
  }, [refresh]);

  useEffect(() => {
    reset(); // this is to make sure form clears on new while undefined props ignored by react-hook-form
    reset(accountLocation);
  }, [accountLocation]);

  const getStatesAsync = async (country: string) => {
    setLoadingStateList(true);
    setStates(await getStates(country, abortController.signal));
    setLoadingStateList(false);
  };

  const onCancel = () => {
    abortController.abort();

    if (onClose) onClose();
  };

  const onCountryChange = (countryCode: any) => {
    setValue("country", countryCode);
    trigger("country");
    getStatesAsync(countryCode);
  };

  const onSetBillingAddressClick = () => {
    setIsChangeBillingAdressConfirmationPopupOpen(true);
  };

  const onSubmit: SubmitHandler<accountLocationInsertViewModel> = async (formData: accountLocationInsertViewModel) => {
    let response: ApiResponse;

    setLoading(true);

    formData.accountId = account.accountId!;
    formData.accountLocationId = accountLocation.accountLocationId || 0;

    if (!accountLocation?.accountLocationId) {
      formData.billingAddress = shouldAddBilling;
      response = await apiCall(AccountLocationApi.insertAccountLocation(trimStringProps(formData), abortController.signal));
    }
    else
      response = await apiCall(AccountLocationApi.updateAccountLocation(trimStringProps(formData), abortController.signal));

    if (response.success) {
      message.success(`Location ${!accountLocation?.accountLocationId ? 'added' : 'edited'} successfully.`);


      if (onSave) onSave(AccountLocation.toClass(response.data?.value));

    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  const handleSetAsBillingAddressRequest = async () => {
    setLoading(true);
    const response = await apiCall(AccountLocationApi.setBillingAccountLocation(accountLocation));
    setLoading(false);

    if (response.success) {
      message.success(`Account Billing Location changed successfully.`);
      setIsChangeBillingAdressConfirmationPopupOpen(false);
      if (onBillingAddressChange) {
        onBillingAddressChange();
      }
    }
  };

  return (
    <>
      {loading && <Loader />}

        <view data-scroll="" data-test="" data-border={isFromSignup ? "no" : ""}>
          <group data-space="15" data-gap="10" data-direction="column">
            {/* <group data-gap="10">
              <Input
                control={control}
                name="locationName"
                label="Location Name"
                //dataLength="300"
                size="large"
              />
              <separator horizontal=""></separator>
            </group> */}
            <group  data-direction="column" data-gap="10">
              <Input
                control={control}
                name="addressLine1"
                label="Address"
                size="large"
              />
              <Input
                control={control}
                name="addressLine2"
                size="large"
              />
              <Input
                control={control}
                name="addressLine3"
                size="large"
              />
              <separator horizontal=""></separator>
            </group>
            {/* <Select
              autocomplete={true}
              label="State/Province"
              control={control}
              //dataLength="300"
              loading={loadingStateList}
              name="stateProvince"
              allowSearch={true}
              options={
                states?.map((item) => {
                  return {
                    text: item.stateName!,
                    value: item.stateCode!,
                  };
                }) || []
              }
            /> */}
            <group data-gap="10">
              <Input
                control={control}
                name="city"
                label="City"
                dataLength="260"
                size="large"
              />
              <Input
                control={control}
                name="stateProvince"
                label="State/Province"
                // dataLength="260"
                size="large"
              />
              <Input
                control={control}
                name="zipPostal"
                label="Postal Code"
                dataLength="150"
                size="large"
              />
            </group>
            <Select
              autoComplete=""
              label="Country"
              control={control}
              //dataLength="300"
              onChange={onCountryChange}
              loading={loadingCountryList}
              name="country"
              allowSearch={true}
              options={
                countries?.map((item) => {
                  return {
                    text: item.countryName!,
                    value: item.countryAlpha2Code!,
                  };
                }) || []
              }
            />
          </group>
        </view>
        <group data-space="10" data-gap="10">
        { !!isFromSignup &&
            <Button data-length="forcefit" highlight onClick={onCancel}>
              <text>{ cancelText }</text>
            </Button>
          }
          <Button
            data-length="forcefit"
            primary
            onClick={handleSubmit(onSubmit)}
          >
            <text>{ okText }</text>
          </Button>
          { !isFromSignup &&
            <Button data-length="forcefit" highlight onClick={onCancel}>
              <text>{ cancelText }</text>
            </Button>
          }
          {!!accountLocation?.accountLocationId && !accountLocation?.billingAddress && (
            <Button
              data-position="right"
              outline
              data-length="forcefit"
              onClick={onSetBillingAddressClick}
            >
              Set Billing Address
            </Button>
          )}
        </group>

      {isChangeBillingAdressConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Set as Billing Adress"
          positiveCallback={handleSetAsBillingAddressRequest}
          negativeCallback={() => {
            setIsChangeBillingAdressConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
