// 3rd-party modules
import { AdvancedMarker, APIProvider, Map } from "@vis.gl/react-google-maps";
import { useEffect, useState } from "react";

// project modules
import Config from "../../../config";
import Loader from "../../shared/loader";
import DashboardWidget from "../../global/dashboardWidget";
import { apiCall } from "../../../helpers/apiHelper";

// apis
import * as ReportApi from '../../../apis/reportApi';

// defines
import countriesJson from "../../../assets/countries.json";

const getAccountsPerCountryReport = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(ReportApi.getAccountsPerCountryReport(abortSignal));

  return response.success ? response.data?.value :null;
};

export default function WorldMap() {
  const [loading, setLoading] = useState(false);
  const [accountsPerCountry, setAccountsPerCountry] = useState<any | null>(null);
  const abortController = new AbortController();
  const defaultCenter = { lat: 37.3, lng: -7.5 };

  useEffect(() => {
    const getAccountsPerCountryReportAsync = async () => {
      setLoading(true);

      const accountsPerCountry = (await getAccountsPerCountryReport(abortController.signal)) || [];

      for (const account of accountsPerCountry) {
        const country = countriesJson['features'].find((country: any) => country['properties']['ISO'] === account.country)

        if (country)
          account.coordinates = country['geometry']['coordinates'];
      }

      setAccountsPerCountry(accountsPerCountry);
      setLoading(false);
    }

    getAccountsPerCountryReportAsync();
  }, []);

  return (
    <>
      <group data-space="20" data-border="" data-sticky="top" data-backdrop="">
        <text data-ellipsis="" data-index="1" data-weight="700">
          World Map
        </text>
      </group>
      {loading && <Loader />}
      <group data-space="20">
        <group
          data-radius="20"
          data-background="main-background"
          data-border=""
          data-contain=""
          data-space="20"
        >
          <div style={{ height: "800px", width: "100%" }}>
            <APIProvider apiKey={Config.googleMapsKey}>
              <Map
                mapId={Config.googleMapId}
                defaultCenter={defaultCenter}
                defaultZoom={3}
                disableDefaultUI
              >
                {accountsPerCountry?.map((account: any) => (
                  <AdvancedMarker
                    position={{
                      lat: account["coordinates"][1],
                      lng: account["coordinates"][0],
                    }}
                  >
                    <h2
                      style={{
                        backgroundColor: "red",
                        border: "1px solid red",
                        opacity: "0.65",
                        borderRadius: "50%",
                        textAlign: "center",
                        paddingLeft: "3px",
                        paddingRight: "3px",
                      }}
                    >
                      {account.country_account_count}
                    </h2>
                  </AdvancedMarker>
                ))}
              </Map>
            </APIProvider>
          </div>
        </group>
      </group>
    </>
  );
}
