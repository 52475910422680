// 3rd-party modules
import { message } from 'antd';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import yup from '../../../plugins/yup';

// project modules
import { apiCall } from '../../../helpers/apiHelper';
import { convertToSnakecase } from '../../../helpers/objectHelper';
import Popup from '../../shared/popup/popup';
import Select from '../../shared/inputs/select';
import Loader from '../../shared/loader';

// apis
import * as DashApi from '../../../apis/dashApi';

// models
import { ApiResponse } from '../../../models/response';
import { accountDeviceBlockViewModel } from '../../../models/types/accountDevice';

type Props = {
  accountDeviceId: string;
  accountDeviceName: string;
  blockingData: accountDeviceBlockViewModel;
  closeOnSave?: boolean;
  open: boolean;
  onClose?: () => void;
  onSave?: () => void;
};

// const getDeviceTypes = async (abortSignal?: AbortSignal) => {
//   const response = await apiCall(CommonValueApi.getDeviceTypes(abortSignal));

//   return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
// };

export default function AccountDeviceBlockingModal({ accountDeviceId, accountDeviceName, blockingData, closeOnSave = false, open, onClose, onSave }: Props) {

  const schema = yup.object().shape({
    duration: yup.number().label("Duration").nullable()
  });
  const { control, handleSubmit } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const abortController = new AbortController();

  useEffect(() => {
    if (open) {

    }
  }, [open]);

  const onCancel = () => {
    abortController.abort();

    if(onClose) onClose();
  };

  const onSubmit: SubmitHandler<any> = async (formData: any) => {
    setLoading(true);

    const tempdata: accountDeviceBlockViewModel = {
      ...blockingData,
      minutesBeforeExpiration: formData.duration
    }
    const data = convertToSnakecase(tempdata, ["minutesBeforeExpiration"], []);

    let response: ApiResponse = await apiCall(DashApi.blockClientDevice(accountDeviceId!, data, abortController.signal));

    if (response.success) {
      message.success(`Device blocking status changed successfully.`);

      if (onSave) onSave();
      if (closeOnSave) {
        onCancel();
      }
    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  return (
    <Popup
      title={`On/Off internet for ${accountDeviceName}`}
      onCancel={onCancel}
      onClose={onCancel}
      onSave={handleSubmit(onSubmit)}
      fixSize="small"
    >
      {loading && <Loader />}
      <group data-space="15" data-gap="10" data-direction="column">
      {
        !!blockingData.blocked ?
        <>
          <text data-wrap="wrap">Please select a time duration in minutes for this filteration.</text>
          <Select
            control={control}
            label="Duration"
            labelPosition="left"
            dataLength="auto"
            name="duration"
            allowSearch={true}
            options={["15", "30", "45", "60"]}
            />
        </> :
        <>
          <text data-wrap="wrap">Are you sure?</text>
        </>
      }
      </group>
    </Popup>
  );
}
