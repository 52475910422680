// 3rd-party modules
import moment from "moment";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";

// project modules
import AccountPickerModal from "../../components/accounts/AccountPickerModal";
import Button from "../../components/shared/button";
import Input from "../../components/shared/inputs/input";
import Loader from "../../components/shared/loader";
import Select from "../../components/shared/inputs/select";
import DataTable, { TableColumn } from "../../components/shared/list/dataTable";
import yup from '../../plugins/yup';
import { apiCall } from "../../helpers/apiHelper";
import { DatePicker } from "../../components/shared/inputs/datePicker";
import { formatMoney } from "../../helpers/objectHelper";
import { yupResolver } from '@hookform/resolvers/yup';

// apis
import * as AccountBillingApi from '../../apis/accountBillingApi';
import * as CommonApi from '../../apis/commonApi';

// models
import { Account } from "../../models/account";
import { AccountBilling } from "../../models/accountBilling";
import { Country } from "../../models/country";
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from '../../models/dataSourceRequest';

// defines
import { CONSTANTS } from "../../helpers/defines";
import { Table } from "antd";

const getCountries = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(CommonApi.getCountries(abortSignal));

  return response.success ? Country.toArrayOfClass(response.data?.value || []) : [];
};

export default function AccountBillingPage() {
  const columns: TableColumn<AccountBilling>[] = [
    {
      title: 'Account Id.',
      dataIndex: 'accountId',
      key: 'accountId',
      filterable: true,
      width: 120
    },
    {
      title: 'Account Name',
      dataIndex: 'accountName',
      key: 'accountName',
      filterable: true
    },
    {
      title: 'Payment id.',
      dataIndex: 'paymentId',
      key: 'paymentId',
      filterable: true,
      width: 250
    },
    {
      title: 'Kibosh Device id.',
      dataIndex: 'kiboshDeviceReference',
      key: 'kiboshDevicereference',
      filterable: true,
      width: 200
    },
    {
      title: 'Amount Paid',
      dataIndex: 'amountPaid',
      key: 'amountPaid',
      filterable: true,
      dataType: 'number',
      width: 150,
      render: text => formatMoney(Number(text) / 100, 2),
      summaryText: 'Total Paid',
      renderSummary: text => formatMoney(Number(text) / 100, 2)
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      filterable: true,
      width: 100,
      render: (text: any) => <>{text.toUpperCase()}</>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filterable: true,
      width: 100,
      render: (text: any) => <>{text.toUpperCase()}</>
    },
    {
      title: 'Payment Date',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      filterable: true,
      dataType: 'datetime',
      render: (text: any) => <>{moment(text).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}</>
    }
  ];
  const schema = yup.object().shape({
    accountNumber: yup.number().label("Account Number").max(100).nullable(),
    fromDate: yup.string().label("From Payment Date").nullable(),
    toDate: yup.string().label("To Payment Date").nullable(),
  });
  const { control, handleSubmit, reset, setValue } = useForm<any>({
    resolver: yupResolver(schema),
  });
  const [account, setAccount] = useState<Account | null>(null);
  const [advancedSearchFilters, setAdvancedSearchFilters] = useState<FilterDescriptor[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [dateRange, setDateRange] = useState<string>("custom");
  const [loading, setLoading] = useState(false);
  const [loadingCountryList, setLoadingCountryList] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  const [showAccountPickerModal, setShowAccountPickerModal] = useState(false);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const abortController = new AbortController();

  useEffect(() => {
    const getCountriesAsync = async () => {
      setLoadingCountryList(true);
      setCountries(await getCountries(abortController.signal));
      setLoadingCountryList(false);
    }

    getCountriesAsync();
  }, []);

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    const request = { conditionList, sortList, pagination } as DataSourceRequest;

    setLoading(true);
    const response = await apiCall(account ? AccountBillingApi.getAccountBillings(account?.accountId!, request, abortSignal) : AccountBillingApi.getBillings(request, abortSignal));
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setLoading(false);

    return response.success ? AccountBilling.toArrayOfClass(response.data?.value || []) : [];
  };

  const onAccountSelect = (account: Account) => {
    setAccount(account);
    setReload(true);
  };

  const onAdvancedSearchApplyClick: SubmitHandler<any> = async (formData: any) =>{
    let filters: FilterDescriptor[] = [];

    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        const value = formData[key];

        if (value) {
          let filter: FilterDescriptor = {
            fieldName: key,
            fieldValue: value
          }

          switch (key) {
            case "fromDate":
              filter = {
                fieldName: "payment_from_date",
                fieldValue: value,
                compareMode: "moreOrEqual"
              }

              break;
            case "toDate":
              filter = {
                fieldName: "payment_to_date",
                fieldValue: value,
                compareMode: "less"
              }

              break;
            case "startDate":
              filter = {
                fieldName: "created_at",
                fieldValue: value,
                compareMode: "moreOrEqual"
              }

              break;
            case "endDate":
              filter = {
                fieldName: "created_at",
                fieldValue: value,
                compareMode: "less"
              }

              break;
            default:
              break;
          }

          filters.push(filter)
        }
      }
    }

    setAdvancedSearchFilters(filters);
    setReload(true);
  };

  const onAdvancedSearchCancelClick = () => {
    reset();
    setShowAdvancedSearch(false);
  };

  const onDateRangeChange = (value: any) => {
    setDateRange(value);
    let fromDate = "";
    let toDate = "";
    switch (value) {
      case "currentMonth":
        fromDate = moment().startOf('month').format(CONSTANTS.DEFAULT_DATE_FORMAT);
        toDate = moment().format(CONSTANTS.DEFAULT_DATE_FORMAT);
        break;
      case "currentYear":
        fromDate = moment().startOf('year').format(CONSTANTS.DEFAULT_DATE_FORMAT);
        toDate = moment().format(CONSTANTS.DEFAULT_DATE_FORMAT);

        break;
      case "lastMonth":
        fromDate = moment().subtract(1,'months').startOf('month').format(CONSTANTS.DEFAULT_DATE_FORMAT);
        toDate = moment().subtract(1,'months').endOf('month').format(CONSTANTS.DEFAULT_DATE_FORMAT);
        break;
      case "lastYear":
        fromDate = moment().subtract(1,'year').startOf('year').format(CONSTANTS.DEFAULT_DATE_FORMAT);
        toDate = moment().subtract(1,'year').endOf('year').format(CONSTANTS.DEFAULT_DATE_FORMAT);
        break;
    }

    setValue("fromDate", fromDate);
    setValue("toDate", toDate);

  }

  return (
    <>
      {loading && <Loader />}
      <view className="table_cont" data-background="none">
        <group
          data-space="15"
          data-gap="20"
          data-border=""
          data-background="highlight"
        >
          <group
            data-position="right"
            data-width="auto"
            data-gap="10"
            data-align="center"
          >
            <Button
              material
              icon="search"
              text="Advanced Search"
              outline
              onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
            />
            <separator vertical="" data-height="20"></separator>
            <Button
              data-position="right"
              material
              icon="refresh"
              text="Refresh"
              outline
              onClick={() => setReload(true)}
            />
          </group>
        </group>
        {showAdvancedSearch && (
          <group
            data-space="15"
            data-gap="10"
            data-border=""
            data-background="highlight"
            data-align="center"
          >
            <Input
              control={control}
              name="accountName"
              label="Account"
              labelPosition="left"
              dataLength="360"
              readOnly
              onDoubleClick={() => setShowAccountPickerModal(true)}
              button={
                <Button
                  icon="more_horiz"
                  onClick={() => setShowAccountPickerModal(true)}
                />
              }
            />
            <Select
              autoComplete=""
              label="Country"
              control={control}
              dataLength="300"
              loading={loadingCountryList}
              name="country"
              allowSearch={true}
              allowClear={true}
              labelPosition="left"
              options={
                countries?.map((item) => {
                  return {
                    text: item.countryName!,
                    value: item.countryAlpha2Code!,
                  };
                }) || []
              }
            />
            <Select
              label="Date Range"
              dataLength="320"
              allowSearch={true}
              labelPosition="left"
              onChange={onDateRangeChange}
              value={dateRange}
              options={
                [
                  {
                    text: "Custom",
                    value: "custom"
                  },
                  {
                    text: "This Month",
                    value: "currentMonth"
                  },
                  {
                    text: "This Year",
                    value: "currentYear"
                  },
                  {
                    text: "Last Month",
                    value: "lastMonth"
                  },
                  {
                    text: "Last Year",
                    value: "lastYear"
                  }
                ]
              }
            />
            <DatePicker
              control={control}
              label="From Payment Date"
              labelPosition="left"
              format={CONSTANTS.DEFAULT_DATE_FORMAT}
              dataLength="360"
              name="fromDate"
            />
            <DatePicker
              control={control}
              label="To Payment Date"
              labelPosition="left"
              format={CONSTANTS.DEFAULT_DATE_FORMAT}
              dataLength="360"
              name="toDate"
            />
            <separator vertical="" data-height="20"></separator>
            <Button
              material
              text="Apply"
              primary
              onClick={handleSubmit(onAdvancedSearchApplyClick)}
            />
            <Button
              material
              text="Cancel"
              outline
              onClick={onAdvancedSearchCancelClick}
            />
          </group>
        )}
        <DataTable
          columns={columns}
          bordered={true}
          customPagination={true}
          defaultFilters={advancedSearchFilters}
          reload={reload}
          dataPagination={true}
          getPageAsync={getPage}
          loading={loading}
          rowKey={(record) => `${record.accountInvoiceId}`}
          showTotal={true}
          size="small"
          totalRecords={totalRecords}
          // summary={(pageData) => {
          //   let totalPaid = 0;

          //   pageData.forEach(({ amountPaid }: any) => {
          //     totalPaid += amountPaid;
          //   });

          //   return (
          //     <>
          //       <Table.Summary.Row>
          //         <Table.Summary.Cell index={0} colSpan={4}>Total</Table.Summary.Cell>
          //         {/* <Table.Summary.Cell index={1}></Table.Summary.Cell>
          //         <Table.Summary.Cell index={2}></Table.Summary.Cell>
          //         <Table.Summary.Cell index={3}></Table.Summary.Cell> */}
          //         <Table.Summary.Cell index={4}>
          //           <text>{formatMoney(totalPaid / 100, 2)}</text>
          //         </Table.Summary.Cell>
          //       </Table.Summary.Row>
          //     </>
          //   );
          // }}
        />
      </view>
      {!!showAccountPickerModal && (
        <AccountPickerModal
          open={showAccountPickerModal}
          closeOnSave={true}
          onClose={() => setShowAccountPickerModal(false)}
          onSelect={onAccountSelect}
        />
      )}
    </>
  );
}
