// 3rd-party modules
import ReactQuill from 'react-quill';
import { SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useMemo, useState } from "react";

// project modules
import Input from "../shared/inputs/input";
import Loader from "../shared/loader";
import Popup from "../shared/popup/popup";
import Select from "../shared/inputs/select";
import yup from "../../plugins/yup";
import { apiCall } from "../../helpers/apiHelper";
import { trimStringProps } from "../../helpers/objectHelper";
import { yupResolver } from "@hookform/resolvers/yup";

// apis
import * as CommonValueApi from '../../apis/commonValueApi';
import * as PostApi from '../../apis/postApi';

// models
import { ApiResponse } from "../../models/response";
import { CommonValue } from "../../models/commonValue";
import { Post } from "../../models/post";
import { message } from "antd";

// styles
import 'react-quill/dist/quill.snow.css';

// defines
import postTemplatesJson from "../../assets/post-templates.json";

type Props = {
  closeOnSave?: boolean;
  open: boolean;
  post: Post;
  onClose?: () => void;
  onSave?: (post: Post) => void;
};

const getPostCategories = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(CommonValueApi.getPostCategories(abortSignal));

  return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
};

const getPostLanguages = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(CommonValueApi.getPostLanguages(abortSignal));

  return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
};

const getPostStatuses = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(CommonValueApi.getPostStatuses(abortSignal));

  return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
};

const getPostTypes = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(CommonValueApi.getPostTypes(abortSignal));

  return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
};

const getPages = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(PostApi.getPages(abortSignal));

  return response.success ? Post.toArrayOfClass(response.data?.value || []) : [];
};

export default function PostModal({ closeOnSave = false, post, open, onClose, onSave }: Props) {
  const schema = yup.object().shape({
    title: yup.string().label("Title").required(),
    type: yup.string().label("Type").required(),
    routePath: yup.string().label("Route Path").max(256).nullable(),
    // categoryId: yup.number().label("Category").required(),
    order: yup.number().label("Order"),
    // content: yup.string().label("Content").required(),
    languageId: yup.string().label("Language").required(),
    status: yup.string().label("Status").required(),
    template: yup.string().label("Template").max(50).nullable()
    // tags: yup.string().label("Tags").nullable(),
    // excerpt: yup.string().label("Excerpt").max(256).nullable(),
    // allowComments: yup.bool().label("Allow Comments")
  });
  const { control, handleSubmit, reset, setValue } = useForm<Post | any>({
    defaultValues: useMemo(() => post, [post]),
    resolver: yupResolver(schema),
  });
  const [categories, setCategories] = useState<CommonValue[]>([]);
  const [languages, setLanguages] = useState<CommonValue[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingLanguages, setLoadingLanguages] = useState(false);
  const [loadingStatuses, setLoadingStatuses] = useState(false);
  const [loadingTypes, setLoadingTypes] = useState(false);
  const [pages, setPages] = useState<Post[]>([]);
  const [statuses, setStatuses] = useState<CommonValue[]>([]);
  const [types, setTypes] = useState<CommonValue[]>([]);
  const abortController = new AbortController();

  useEffect(() => {
    if (open) {
      const getPostCategoriesAsync = async () => {
        setLoadingCategories(true);
        setCategories(await getPostCategories(abortController.signal));
        setLoadingCategories(false);
      }
      const getPostLanguagesAsync = async () => {
        setLoadingLanguages(true);
        setLanguages(await getPostLanguages(abortController.signal));
        setLoadingLanguages(false);
      }
      const getPostStatusesAsync = async () => {
        setLoadingStatuses(true);
        setStatuses(await getPostStatuses(abortController.signal));
        setLoadingStatuses(false);
      }
      const getPostTypesAsync = async () => {
        setLoadingTypes(true);
        setTypes(await getPostTypes(abortController.signal));
        setLoadingTypes(false);
      }
      const getPagesAsync = async () => {
        setPages(await getPages(abortController.signal));
      }

      getPostCategoriesAsync();
      getPostLanguagesAsync();
      getPostStatusesAsync();
      getPostTypesAsync();
      getPagesAsync();
    }
  }, [open]);

  useEffect(() => {
    reset(); // this is to make sure form clears on new while undefined props ignored by react-hook-form
    reset(post);
  }, [post]);

  const onCancel = () => {
    abortController.abort();

    if (onClose) onClose();
  };

  const onEditorChange = (content: any, delta: any, source: any, editor: any) => {
    setValue("content", content)
  }

  const onSubmit: SubmitHandler<Post> = async (formData: Post) => {
    let response: ApiResponse;

    setLoading(true);

    if (!post?.postId)
      response = await apiCall(PostApi.insertPost(trimStringProps(formData), abortController.signal));
    else
      response = await apiCall(PostApi.updatePost(trimStringProps(formData), abortController.signal));

    if (response.success) {
      message.success(`Post ${!post?.postId ? 'added' : 'edited'} successfully.`);

      if (onSave) onSave(Post.toClass(response.data?.value));

      if (closeOnSave) {
        open = false;

        onCancel();
      }

    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  return (
    <>
      <Popup
        title={!post.postId ? "New Post" : "Edit Post"}
        onCancel={onCancel}
        onClose={onCancel}
        onSave={handleSubmit(onSubmit)}
        fixSize="large"
      >
        {loading && <Loader />}
        <group data-space-vertical="15" data-direction="column" data-gap="5">
          <group data-gap="10" data-background="highlight" data-border='' data-space='15' data-sticky="top">
            <Input
              control={control}
              name="title"
              label="Title"
              dataLength="auto"
              size="large"
              labelPosition='left'
            />
            <Select
              autoComplete=""
              label="Type"
              labelPosition='left'
              control={control}
              dataLength="150"
              loading={loadingTypes}
              name="type"
              allowSearch={true}
              options={
                types?.map((item) => {
                  return {
                    text: item.valueCaption,
                    value: item.value!.toLocaleLowerCase(),
                  };
                }) || []
              }
            />
            <Input
              control={control}
              name="routePath"
              label="Route Path"
              dataLength="200"
              labelPosition='left'
            />
            <Select
              autoComplete=""
              label="Parent"
              control={control}
              labelPosition='left'
              dataLength="200"
              // loading={loadingPages}
              name="parentId"
              allowSearch={true}
              popupMatchSelectWidth={200}
              options={
                pages?.map((item) => {
                  return {
                    text: item.title,
                    value: item.postId,
                  };
                }) || []
              }
            />
            <Select
              autoComplete=""
              label="Category"
              control={control}
              labelPosition='left'
              dataLength="200"
              loading={loadingCategories}
              name="categoryId"
              allowSearch={true}
              popupMatchSelectWidth={150}
              options={
                categories?.map((item) => {
                  return {
                    text: item.valueCaption!,
                    value: item.commonValueId,
                  };
                }) || []
              }
            />
            <Input
              control={control}
              type='number'
              name="order"
              label="Order"
              dataLength="100"
              labelPosition='left'
            />
            <Select
              autoComplete=""
              label="Language"
              control={control}
              labelPosition='left'
              dataLength="150"
              loading={loadingLanguages}
              name="languageId"
              allowSearch={true}
              options={
                languages?.map((item) => {
                  return {
                    text: item.valueCaption!,
                    value: item.value!.toLocaleLowerCase(),
                  };
                }) || []
              }
            />
            <Select
              autoComplete=""
              label="Template"
              control={control}
              labelPosition='left'
              dataLength="300"
              name="template"
              allowSearch={true}
              popupMatchSelectWidth={120}
              options={
                postTemplatesJson["templates"].map((item: any) => {
                  return {
                    text: item.name,
                    value: item.code,
                  };
                }) || []
              }
            />
            <Select
              autoComplete=""
              label="Status"
              control={control}
              dataLength="200"
              loading={loadingStatuses}
              name="status"
              labelPosition='left'
              allowSearch={true}
              popupMatchSelectWidth={120}
              options={
                statuses?.map((item) => {
                  return {
                    text: item.valueCaption!,
                    value: item.value!.toLocaleLowerCase(),
                  };
                }) || []
              }
            />
          </group>

          <group  data-direction="column" data-space='15' data-gap='10'>
            <ReactQuill
              style={{
                width: "100%",
                height:'450px'
              }}
              value={post.content}
              onChange={onEditorChange}
              modules={{
                toolbar: [
                  [{ 'header': [1, 2, 3, 4, false] }],
                  ['bold', 'italic', 'underline','strike', 'blockquote'],
                  [{ color: [] }],
                  [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
                  ['link', 'image'],
                  ['clean']
                ],
              }}
            />
          </group>
        </group>
      </Popup>
    </>
  );
}
