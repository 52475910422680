// project modules
import RevenueSummary from "../../components/sales/dashboard/RevenueSummary";

export default function RevenueSummaryPage() {
  return (
    <>
      <view data-scroll='' data-adaptive="" data-background="highlight">
        <RevenueSummary />
      </view>
    </>
  );
}
