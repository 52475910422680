// 3rd-party modules
import moment from 'moment-timezone';
import { message } from "antd";
import { SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";

// project modules
import Button from "../shared/button";
import Input, { InputType } from "../shared/inputs/input";
import Loader from "../shared/loader";
import yup from "../../plugins/yup";
import { apiCall } from "../../helpers/apiHelper";
import { RootState } from "../../stores";

// apis
import * as CommentApi from '../../apis/commentApi';

// models
import { ApiResponse } from "../../models/response";
import { Comment } from "../../models/comment";
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from "../../models/dataSourceRequest";
import { User } from "../../models/auth";

// defines
type CommentByDate = {
  date: string;
  items: Comment[];
}

type Props = {
  entityCode: string;
  entityId: any;
  reload?: boolean;
  commentsTitle?: string;
  commentsLabel?: string;
  showType?: boolean;
  onSave?: () => void;
};

export default function CommentList({ onSave, entityCode, entityId, reload, commentsTitle = "Comments", commentsLabel = "Comment", showType = true }: Props) {
  const schema = yup.object().shape({
    message: yup.string().label("Message").max(500).required(),
  });
  const { control, handleSubmit, reset, setValue } = useForm<Comment | any>({
    resolver: yupResolver(schema),
  });
  const [comments, setComments] = useState<Comment[]>([]);
  const [datasource, setDatasource] = useState<CommentByDate[]>([]);
  const [loadingComments, setLoadingComments] = useState(false);
  const [loading, setLoading] = useState(false);

  const user = useSelector((state: RootState) => state.user.currentUser) as User;
  const abortController = new AbortController();

  useEffect(() => {
    getCommentsAsync()
  }, []);

  useEffect(() => {
    if (reload) {
      getCommentsAsync();
    }
  }, [reload]);

  useEffect(() => {
    let data: CommentByDate[] = [];

    if (comments?.length) {
      comments.forEach(item => {
        const date = moment(item.createdAt).format("DD/MM/YYYY");
        const parentIndex = data.findIndex(x => x.date === date);

        if (parentIndex > -1) {
          data[parentIndex].items.push(item);
        } else {
          data.push({ date, items: [item]});
        }
      });
    }
    setDatasource(data);
  }, [comments]);

  const getCommentsAsync = async () => {
    setComments(await getComments([], [], new PaginationDescriptor(), abortController.signal));
  }

  const getComments = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    const request = { conditionList, sortList, pagination } as DataSourceRequest;

    setLoadingComments(true);
    const response = await apiCall(CommentApi.getComments(entityCode, entityId, request, abortSignal));
    setLoadingComments(false);

    return response.success ? Comment.toArrayOfClass(response.data?.value || []) : [];
  };

  const onCommentCancel = () => {
    reset();
    setValue("message", "");
  };

  const onSubmit: SubmitHandler<Comment> = async (formData: Comment) => {
    formData.entityCode = entityCode;
    formData.entityId = entityId;
    formData.from = user ? `${user.firstName} ${user.lastName}` : "Admin";
    formData.type = "admin";

    setLoading(true);
    let response: ApiResponse = await apiCall(CommentApi.insertComment(formData, abortController.signal));

    if (response.success) {
      message.success(`${commentsLabel} added successfully.`);
      getCommentsAsync();
      onCommentCancel();

      if (onSave) onSave();
    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  const renderNewCommentSection = () => {
    return (
      <>
        {/*<group data-space="20" data-gap="10" data-direction="column">

        </group>*/}
        <group
          data-space="15"
          data-align="center"
          data-gap="10"
          data-border="top"
          data-sticky="bottom"
          data-elevation="1"
        >
          <Input
            control={control}
            name="message"
            type={InputType.TextArea}
            dataLength="auto"
            resize=""
            placeholder={`Enter ${commentsLabel.toLocaleLowerCase()}`}
            aria-invalid="false"
            data-height="100"
          />
          <group data-gap="10">
            <Button
              material
              primary
              data-length="forcefit"
              onClick={handleSubmit(onSubmit)}
            >
              <text>Send the {commentsLabel}</text>
            </Button>
            <Button
              material
              outline
              data-length="forcefit"
              onClick={onCommentCancel}
            >
              <text>Cancel</text>
            </Button>            
          </group>


        </group>
      </>
    );
  }

  return (
    <>
    {loading && <Loader />}
      <view>
        <group
          data-direction="column"
          data-gap="10"
          data-scroll=""
          data-wrap="no"
          data-space="20"
          //data-test="1"
          data-length="autofit"
        >
          <group
            //data-sticky="top"
            //data-border="top"
            data-space-vertical="10"
            //data-backdrop=""
          >
            <text data-weight="600">{commentsTitle}</text>
          </group>

          
          {loadingComments && <Loader />}
          {datasource.map((x) => (
            <>
              <group data-space="5">
                <text data-weight="600" data-light="">
                  {x.date}
                </text>
              </group>
              <group data-direction="column" data-gap="10" data-wrap="no">
                {x.items.map((item) => (
                  <>
                    <group
                      data-radius="10"
                      data-background={
                        item.type === "admin" ? "main-lighter" : "main-light"
                      }
                      data-space="10"
                      data-gap="10"
                      data-wrap="no"
                      data-border=""
                      data-align={item.type === "admin" ? "start" : ""}
                      data-width="90"
                      data-position={item.type === "admin" ? "left" : "right"}
                      data-radius-top-left={item.type === "admin" ? "0" : null}
                      data-radius-bottom-right={
                        item.type === "admin" ? null : "0"
                      }
                    >
                      <group
                        data-width="auto"
                        data-direction="column"
                        data-gap="10"
                      >
                        <group data-direction="column">
                          <text data-weight="600">{item.from}</text>
                          {showType && <text data-light="">{item.type}</text>}
                        </group>
                        <group>
                          <text data-wrap="wrap">{item.message}</text>
                        </group>
                      </group>
                      <group
                        data-width="auto"
                        data-position="right"
                        data-justify="center"
                      >
                        <text>{moment(item.createdAt).format("HH:mm")}</text>
                      </group>
                    </group>
                  </>
                ))}
              </group>
            </>
          ))}
        </group>
        {renderNewCommentSection()}
      </view>
    </>
  );
}
