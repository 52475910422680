import MaterialRipple from "../ripple";
import React from "react";
import { DetailedHTMLProps, HTMLAttributes } from "react";
import { Link } from "react-router-dom";
import { generateKey } from "../../../helpers/objectHelper";

export interface ContextMenuItem {
  icon?: string;
  text?: string;
  link?: string;
  element?: React.ReactElement;
  customElement?: React.ReactElement;
  bold?: boolean;
  separator?: boolean;
  onClick?: () => any;
}

type ContextMenuProps = DetailedHTMLProps<
  HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
> & {
  items: ContextMenuItem[];
};

const ContextMenuList: React.FC<ContextMenuProps> = ({
  items,
  ...divProps
}) => {
  return (
    <group data-max-length="300" {...divProps}>
      <div className="list_menu">
        <ul data-space="10">
          {items.map((item, index) => {
            let element;

            if (item.separator) {
              return <li key={generateKey()} className="separator"></li>;
            } else if (item.customElement) {
              return (
                <React.Fragment key={generateKey()}>
                  {item.customElement}
                </React.Fragment>
              );
            } else if (item.element) {
              element = item.element;
            } else {
              element = (
                <>
                  <icon data-length="30">{item.icon || ""}</icon>
                  <text data-weight={item.bold ? "700" : undefined}>
                    {item.text}
                  </text>
                </>
              );

              if (item.link) {
                element = (
                  <Link
                    data-type="group"
                    data-align="center"
                    data-gap="5"
                    to={item.link}
                  >
                    {element}
                  </Link>
                );
              }
            }

            return (
              <MaterialRipple key={generateKey()}>
                <li data-radius="5" data-gap="5" onClick={item.onClick}>
                  {element}
                </li>
              </MaterialRipple>
            );
          })}
        </ul>
      </div>
    </group>
  );
};

export default ContextMenuList;
