// project modules
import WorldMap from "../../components/sales/dashboard/WorldMap";
import AccountSummary from "../../components/sales/dashboard/AccountSummary";

export default function SalesDashboardPage() {
  return (
    <view data-scroll='' data-adaptive="" data-background="highlight">
      <AccountSummary />
      <WorldMap />
    </view>
  );
}
