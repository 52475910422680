// project modules
import { getBaseUrl, sendRequest } from '../helpers/apiHelper';

export const getCountries = async (abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/common/country/list`, 'get', null, null, abortSignal);
};

export const getStates = async (country: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/common/state/country/code/${country}`, 'get', null, null, abortSignal);
};
