// 3rd-party modules
import { createSlice } from "@reduxjs/toolkit";

// project modules
import { popupRoot } from "../components/shared/popup/popup";

const initialState = {
    popupsCount: 0
};

export const componentsSlice = createSlice({
    name: "components",
    initialState,
    reducers: {
        addPopup (state) {
            if (state.popupsCount === 0) {
                popupRoot?.classList.add('open');
            }

            state.popupsCount++;
        },
        removePopup (state) {
            state.popupsCount--;

            if (state.popupsCount === 0) {
                popupRoot?.classList?.remove('open');
            }
        },
    }
});

export const { addPopup, removePopup } = componentsSlice.actions;

export default componentsSlice.reducer;
