// 3rd-party modules
import { message, Tooltip } from 'antd';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useState } from 'react';

// project modules
import AccountModal from './AccountModal';
import AdvancedSearch from '../global/advanced-search';
import Badge from '../shared/badge';
import Button from '../shared/button';
import ChangePasswordModel from '../system-users/ChangePasswordModel';
import Checkbox from '../shared/inputs/checkbox';
import ConfirmationPopup from '../shared/popup/confirmationPopup';
import ContextMenu from '../shared/contextMenu/contextMenu';
import DataTable, { TableColumn } from '../shared/list/dataTable';
import yup from '../../plugins/yup';
import { apiCall } from '../../helpers/apiHelper';
import { yupResolver } from '@hookform/resolvers/yup';

// apis
import * as AccountApi from '../../apis/accountApi';
import * as DashApi from '../../apis/dashApi';

// models
import { Account } from '../../models/account';
import { DataSourceRequest, ESortOrder, FilterDescriptor, PaginationDescriptor, SortDescriptor } from '../../models/dataSourceRequest';

type Props = {
  onSelect?: (account: Account) => void;
};

export default function AccountsTable({ onSelect }: Props) {
  const columns: TableColumn<Account>[] = [
    {
      title: 'ID',
      dataIndex: 'accountId',
      key: 'accountId',
      dataType: 'number',
      filterable: true,
      width: 100
    },
    {
      title: 'Account Name',
      dataIndex: 'accountName',
      key: 'accountName',
      filterable: true,
    },
    // {
    //   title: 'First Name',
    //   dataIndex: 'firstName',
    //   key: 'firstName',
    //   filterable: true,
    //   width: 200
    // },
    // {
    //   title: 'Last Name',
    //   dataIndex: 'lastName',
    //   key: 'lastName',
    //   filterable: true,
    //   width: 200
    // },
    {
      title: 'Email / Username',
      dataIndex: 'email',
      key: 'email',
      filterable: true,
      width: 300
    },
    {
      title: 'Country',
      dataIndex: 'country',
      key: 'country',
      filterable: true,
      width: 100,
      render: (value) => value && value.substring(0, 2) // backend concatenates all countries from billing address
    },
    // {
    //   title: 'Devices',
    //   dataIndex: 'kiboshDeviceTypes',
    //   key: 'kiboshDeviceTypes',
    //   filterable: true,
    //   width: 170,
    //   render: (value) => value && (Array.from(new Set(value.split(', ')))).join(', ').split(', ').map((v: string, i: number) => <Badge key={i} text={v} color={{'Router': 'blue', 'DNS': 'cyan', 'VPN': 'brown'}[v]} />)
    // },
    {
      title: '# of Routers',
      dataIndex: 'routersCount',
      key: 'routersCount',
      filterable: true,
      dataType: 'number',
      width: 140
    },
    {
      title: '# of VPNs',
      dataIndex: 'vpnsCount',
      key: 'vpnsCount',
      filterable: true,
      dataType: 'number',
      width: 140
    },
    {
      title: 'Account Type',
      dataIndex: 'accountType',
      key: 'accountType',
      filterable: true,
      width: 125
    },
    {
      title: 'Subscription',
      dataIndex: 'latestSubscriptionInterval',
      key: 'latestSubscriptionInterval',
      filterable: true,
      width: 120,
      render: (value) => value
        ? value <= 30 ? 'Monthly' : 'Annual'
        : ''
    },
    // {
    //   title: 'Status',
    //   dataIndex: 'hasSubscription',
    //   key: 'hasSubscription',
    //   filterable: true,
    //   width: 90,
    //   render: (_, record) => record.hasSubscription ? "Active" : "Inactive"
    // },
    {
      title: 'Status',
      dataIndex: 'activeSubscriptionsCount',
      key: 'activeSubscriptionsCount',
      filterable: true,
      width: 90,
      render: (value, record) => {
        let accountStatus = value > 0 ? "Active" : "Inactive";

        if (record.lockoutEnabled)
          accountStatus = "Inactive";

        if (record.archived)
          accountStatus = "Canceled";

        return accountStatus;
      }
    },
    // {
    //   title: 'Locked',
    //   dataIndex: 'lockoutEnabled',
    //   key: 'lockoutEnabled',
    //   dataType: 'bool',
    //   width: 90
    // },
    {
      title: '',
      key: 'action',
      width: 60,
      hidden: !!onSelect,
      render: (_, record) => (
        <group data-wrap='no' data-gap="5">
          <Tooltip title="Edit"><div className='button micro' onClick={() => onEditClick(record)}><icon>edit_square</icon></div></Tooltip>
          <Tooltip title="Delete"><div className='button micro' onClick={() => onDeleteClick(record)}><icon>delete</icon></div></Tooltip>
          <separator vertical="" data-adaptive="desktop"></separator>
          <ContextMenu placement="bottomLeft" items={
            [
              {
                text: "Details/Addresses",
                onClick: () => onSelectedTab(record, "details")
              },
              // {
              //   text: "Locations",
              //   onClick: () => onSelectedTab(record, "locations")
              // },
              {
                text: "Kibosh Devices",
                onClick: () => onSelectedTab(record, "kibosh-devices")
              },
              {
                text: "Billing",
                onClick: () => onSelectedTab(record, "billing")
              },
              {
                text: "Subscriptions",
                onClick: () => onSelectedTab(record, "subscriptions")
              }
            ]
          }>
            <Tooltip title="Tabs"><div className='button micro'><icon>more_vert</icon></div></Tooltip>
          </ContextMenu>
        </group>
      ),
    }
  ];
  const schema = yup.object().shape({
    showInactiveAccounts: yup.bool().label("Include Inactive Accounts").nullable(),
    showCanceledAccounts: yup.bool().label("Include Canceled Accounts").nullable(),
  });
  const { control, handleSubmit, reset } = useForm<any>({
    resolver: yupResolver(schema),
  });

  const [advancedSearchFilters, setAdvancedSearchFilters] = useState<FilterDescriptor[]>([]);
  const [currentAccount, setCurrentAccount] = useState<Account>(new Account());
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [pageLoaded, setPageLoaded] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [preSelectedTab, setPreSelectedTab] = useState<string>("");
  const [showAccountModal, setShowAccountModal] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  useEffect(() => {
    setAdvancedSearchFilters([
      {
        fieldName: "lockoutEnabled",
        fieldValue: false
      },
      {
        fieldName: "archived",
        fieldValue: false
      },
    ])
    setPageLoaded(true)
    setReload(true);
  }, []);

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    if (!pageLoaded) {
      return [];
    }
    const request = { conditionList, sortList, pagination } as DataSourceRequest;

    setLoading(true);
    // const response = await apiCall(AccountApi.getAccounts(request, abortSignal));
    // const dashResponse = await apiCall(DashApi.getAccountsKiboshDevicesAsync(abortSignal));
    const [response, dashResponse] = await Promise.all([
      apiCall(AccountApi.getAccounts(request, abortSignal)),
      apiCall(DashApi.getAccountsKiboshDevicesAsync(abortSignal))
    ]);
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setLoading(false);

    const dashData = dashResponse.success ? dashResponse.data?.value : []

    return response.success ?
      Account.toArrayOfClass(response.data?.value || []).map((account: any) => {
        return {
          ...account,
          hasSubscription: !!account.activeSubscriptionsCount,
          routersCount: dashData.findIndex((x: any) => x.kibosh_user_id == account.accountId) > -1 ? dashData.find((x: any) => x.kibosh_user_id == account.accountId).router_count : 0,
          vpnsCount: dashData.findIndex((x: any) => x.kibosh_user_id == account.accountId) > -1 ? dashData.find((x: any) => x.kibosh_user_id == account.accountId).vpn_count : 0
        }
      }) :
      [];
  };

  const onAccountSave = (e: Account) => {
    setReload(true);
  }

  const onAdvancedSearchApplyClick = (formData: any, conditions?: FilterDescriptor[]) => {
    setAdvancedSearchFilters(conditions || []);
    setReload(true);
  };

  /*
  const onAdvancedSearchApplyClick: SubmitHandler<any> = async (formData: any) => {
    let filters: FilterDescriptor[] = [];

    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        const value = formData[key];

        if (key === 'showInactiveAccounts') {
          if (!value) {
            filters.push({
              fieldName: "LockoutEnabled",
              fieldValue: false
            })
          }
        } else if (key === 'showCanceledAccounts') {
          if (!value) {
            filters.push({
              fieldName: "Archived",
              fieldValue: false
            })
          }
        } else if (value) {
          let filter: FilterDescriptor = {
            fieldName: key,
            fieldValue: value
          }

          switch (key) {
            case "startDate":
              filter = {
                fieldName: "created_at",
                fieldValue: value,
                compareMode: "moreOrEqual"
              }
              break;
            case "endDate":
              filter = {
                fieldName: "created_at",
                fieldValue: value,
                compareMode: "less"
              }
              break;
            default:
              break;
          }

          filters.push(filter)
        }
      }
    }

    setAdvancedSearchFilters(filters);
    setReload(true);
  };
  */

  const onAdvancedSearchCancelClick = () => {
    reset();
    setShowAdvancedSearch(false);
  };

  // const onChangePasswordClick = (account: Account) => {
  //   setCurrentAccount({...account});
  //   setShowChangePasswordModal(true);
  // };

  const onEditClick = (account: Account) => {
    setCurrentAccount({...account});
    setPreSelectedTab("");
    setShowAccountModal(true);
  };

  const onRowSelect = (account: Account) => {
    if (onSelect) {
      onSelect(account);
    }
  };

  const onSelectedTab = (account: Account, selectedTab: any) => {
    if (selectedTab) {
      setCurrentAccount({...account});
      setPreSelectedTab(selectedTab);
      setShowAccountModal(true);
    }
  };

  // const onDeleteClick = (user: Account) => {
  //   Modal.confirm({
  //     title: 'Please confirm',
  //     content: 'Are you sure?',
  //     okText: 'Delete',
  //     okType: 'danger',
  //     onOk: async () => {
  //       setLoading(true);
  //       const response = await apiCall(AccountApi.deleteAccount(user));
  //       setLoading(false);

  //       if (response.success) {
  //         message.success(`Account deleted successfully.`);
  //         setReload(true);
  //       }
  //     }
  //   });
  // };

  const onDeleteClick = (account: Account) => {
    setCurrentAccount(account);
    setIsDeleteConfirmationPopupOpen(true);
  };

  const onNewClick = () => {
    setCurrentAccount(new Account());
    setPreSelectedTab("");
    setShowAccountModal(true);
  };

  const handleDeleteRequest = async () => {
    setLoading(true);
    const response = await apiCall(AccountApi.deleteAccount(currentAccount));
    setLoading(false);

    if (response.success) {
      message.success(`Account deleted successfully.`);
      setIsDeleteConfirmationPopupOpen(false);
      setReload(true);
    }
  };

  return (
    <>
      <view className="table_cont" data-background="none">
        <group data-space="15"  data-gap="20" data-border="" data-background="highlight">
          {!onSelect && <Button
            material
            icon="add"
            text="New"
            primary
            onClick={onNewClick}
          />}
          <group data-position="right" data-width='auto' data-gap='10' data-align='center'>
            <Button
              material
              icon="search"
              text="Advanced Search"
              outline
              onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
            />
            <separator vertical="" data-height="20"></separator>
            <Button
              material
              icon="refresh"
              text="Refresh"
              outline
              onClick={() => setReload(true)}
            />
          </group>
        </group>
        <AdvancedSearch
          show={showAdvancedSearch}
          filters={[
            { type: "text", name: "globalFilter", label: "Filter", yupSchema: yup.string().label("Filter").nullable(), conditions: [{ fieldName: "accountId", concatMode: "or", groupId: 1 }, { fieldName: "accountName", compareMode: "contains", concatMode: "or", groupId: 1 }, { fieldName: "email", compareMode: "contains", concatMode: "or", groupId: 1 }], props: { dataLength: "400" } },
            { type: "checkbox", name: "showInactiveAccounts", label: "Include Inactive Accounts", yupSchema: yup.bool().label("Include Inactive Accounts").nullable(), conditions: [{ fieldName: "lockoutEnabled" }] },
            { type: "checkbox", name: "showCanceledAccounts", label: "Include Canceled Accounts", yupSchema: yup.bool().label("Include Canceled Accounts").nullable(), conditions: [{ fieldName: "archived" }] },
          ]}
          onApplyClick={onAdvancedSearchApplyClick}
        />
        {/* {
          showAdvancedSearch &&
          <group data-space="15" data-gap="10" data-border="" data-background="highlight" data-align='center'>
            <Checkbox
              control={control}
              name="showInactiveAccounts"
              label="Include Inactive Accounts"
              minimal
              dataLength="auto" />
            <Checkbox
              control={control}
              name="showCanceledAccounts"
              label="Include Canceled Accounts"
              minimal
              dataLength="auto" />
            <separator vertical="" data-height="20"></separator>
            <Button
              material
              text="Apply"
              primary
              onClick={handleSubmit(onAdvancedSearchApplyClick)}
            />
            <Button
              material
              text="Cancel"
              outline
              onClick={onAdvancedSearchCancelClick}
            />
          </group>
        } */}
        <DataTable columns={columns}
          bordered={true}
          customPagination={true}
          defaultFilters={advancedSearchFilters}
          reload={reload}
          dataPagination={true}
          getPageAsync={getPage}
          loading={loading}
          rowKey={(record, index) => `${record.accountId}-${index}`}
          defaultSortOrders={[{ fieldName: 'id', sortOrder: ESortOrder.asc }]}
          showTotal={true}
          size="small"
          totalRecords={totalRecords}
          onRow={(record) => ({
            onDoubleClick: () => onSelect ? onRowSelect(record) : onEditClick(record),
            style: { cursor: "pointer" },
          })}
        />
      </view>
      {!!showAccountModal &&
        <AccountModal open={showAccountModal}
          account={currentAccount}
          preSelectedTab={preSelectedTab}
          onClose={() => setShowAccountModal(false) }
          onSave={(e) => onAccountSave(e) } />
      }
      {!!showChangePasswordModal &&
        <ChangePasswordModel
          open={showChangePasswordModal}
          showUsername={true}
          closeOnSave={true}
          user={currentAccount}
          onClose={() => setShowChangePasswordModal(false) } />
      }
      {isDeleteConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Delete"
          positiveCallback={handleDeleteRequest}
          negativeCallback={() => {
            setIsDeleteConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
