import classNames from "classnames";

import { PropsWithChildren, useEffect, useRef, useState } from "react";

import { v4 as uuidv4 } from "uuid";
import Draggable from "react-draggable";
import { createPortal } from "react-dom";
import Button from "../button";
import { addPopup, removePopup } from "../../../stores/componentsSlice";
import { useAppDispatch } from "../../../hooks/storeHooks";
import { CommandPanelToolbar } from "../../commandPanelToolbar";

export const popupRoot = document.getElementById("popup-root");

type PopupProps = PropsWithChildren<{
  coloredTitle?: boolean;
  fixSize?: string;
  fullscreen?: boolean;
  noCommandbar?: boolean;
  noHeader?: boolean;
  open?: boolean;
  isPortal?: boolean;
  space?: boolean;
  title?: string;
  onClose?: () => void;
  onSave?: () => void;
  onCancel?: () => void;
}>;

const Popup: React.FC<PopupProps> = ({
  children,
  title = "",
  fixSize,
  fullscreen = false,
  coloredTitle = false,
  noCommandbar = false,
  noHeader = false,
  isPortal = false,
  space = false,
  onClose,
  onSave,
  onCancel,
}) => {
  const [guid] = useState(uuidv4());
  const containerRef = useRef<HTMLDivElement>(null);
  const elementRef = useRef<HTMLDivElement>(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(addPopup());

    return () => {
      dispatch(removePopup());
    };
  }, [dispatch]);
  let innerContent = (
    <div ref={elementRef} className={"handle_" + guid}>
      {!noHeader && (
        <group
          {...(coloredTitle
            ? { "data-background": "main", "data-color": "white" }
            : { "data-background": "main-background" })}
          data-space="5"
        >
          <group data-wrap="no" data-align="center">
            <text
              data-weight="700"
              data-space="10"
              data-ellipsis=""
              data-name="popup_title"
            >
              {title}
            </text>
            <Button
              mini
              material
              //{...(fullscreen ? null : { position: "right" })}
              className="close"
              //icon={fullscreen ? "chevron_left" : "close"}

              position={"right"}
              onClick={onClose}
            >
              <icon>close</icon>
            </Button>
          </group>

          {/*<text data-weight="700" space="">*/}
          {/*    {t(title)}*/}
          {/*</text>*/}
        </group>
      )}
      <div>
        <div id="windowContent" className="window_cont" fixed={fixSize}>
          <view>
            <view data-scroll=''>
                {children}
            </view>

          </view>
          {!noCommandbar && (
                <CommandPanelToolbar onSave={onSave} onCancel={onCancel} />
              )}
        </div>
      </div>
    </div>
  );

  if (!fullscreen) {
    innerContent = (
      <Draggable
        nodeRef={elementRef}
        cancel=".window_cont,.close"
        bounds="parent"
        handle={".handle_" + guid}
      >
        {innerContent}
      </Draggable>
    );
  }

  const content = (
    <div
      ref={containerRef}
      className={classNames("popup", "open", { fullscreen })}
    >
      {innerContent}
    </div>
  );

  if (isPortal) {
    return content;
  }

  return createPortal(
    content,
    document.getElementById("popup-root") as HTMLElement
  );
};

export default Popup;
