// project modules
import { getBaseUrl, sendRequest } from '../helpers/apiHelper';

// models
import { DataSourceRequest } from '../models/dataSourceRequest';
import { Post } from '../models/post';

export const getPosts = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/post/list`, 'post', request, null, abortSignal);
};

export const insertPost = async (post: Post, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/post`, 'post', post, null, abortSignal);
};

export const updatePost = async (post: Post, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/post`, 'put', post, null, abortSignal);
};

export const deletePost = async (post: Post, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/post`, 'delete', post, null, abortSignal);
};

export const getPages = async (abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/post/page/list`, 'get', null, null, abortSignal);
};

export const getNotes = async (abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/post/note/list`, 'get', null, null, abortSignal);
};

export const getNoteCategories = async (abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/post/note/category/list`, 'get', null, null, abortSignal);
};
