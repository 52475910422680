// 3rd-party modules
import { Tooltip, message } from "antd";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// project modules
import { apiCall } from "../../../helpers/apiHelper";
import AccountDeviceGroupModal from "./AccountDeviceGroupModal";
import Button from "../../shared/button";
import ConfirmationPopup from "../../shared/popup/confirmationPopup";
import ContextMenu from "../../shared/contextMenu/contextMenu";
import DataTable, { TableColumn } from "../../shared/list/dataTable";
import Loader from "../../shared/loader";
import Input from "../../shared/inputs/input";
import Select from "../../shared/inputs/select";
import yup from "../../../plugins/yup";

// apis
import * as AccountKiboshDeviceApi from '../../../apis/accountKiboshDeviceApi';
import * as DashApi from '../../../apis/dashApi';

// models
import { Account } from "../../../models/account";
import { AccountKiboshDevice } from "../../../models/accountKiboshDevice";
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from "../../../models/dataSourceRequest";
import { AccountDeviceGroup } from "../../../models/accountDeviceGroup";
import { accountDeviceGroupUpdateViewModel } from "../../../models/types/accountDeviceGroup";

type Props = {
  account?: Account;
  accountKiboshDevice?: AccountKiboshDevice;
  refresh?: boolean;
  onCancel?: () => void;
  onMembersListChange?: () => void;
  onSave?: (account: Account) => void;
};

export default function AccountDeviceGroups({ account, refresh, accountKiboshDevice, onCancel, onSave, onMembersListChange }: Props) {
  const columns: TableColumn<AccountDeviceGroup>[] = [
    {
      title: 'Profile Name',
      dataIndex: 'name',
      key: 'name',
      filterable: true
    },
    {
      title: '# of Devices',
      dataIndex: 'ip',
      key: 'ip',
      filterable: true,
      width: 150,
      render: (text: any, record: AccountDeviceGroup) => record.clientIds?.length || 0
    },
    {
      title: 'Blocking Enabled',
      dataIndex: 'isBlocked',
      key: 'isBlocked',
      dataType: 'bool',
      width: 150
    },
    {
      title: '',
      key: 'action',
      width: 60,
      render: (_, record: any) => (
        <group data-wrap='no' data-gap="5">
          <Tooltip title="Edit"><div className='button micro' onClick={() => onEditClick(record)}><icon>edit_square</icon></div></Tooltip>
          <Tooltip title="Delete"><div className='button micro' onClick={() => onDeleteClick(record)}><icon>delete</icon></div></Tooltip>
          <separator vertical="" data-adaptive="desktop"></separator>
          <ContextMenu placement="bottomLeft" items={
            [
              {
                text: "Details",
                onClick: () => onSelectedTab(record, "details")
              },
              {
                text: "Blocked Sites",
                onClick: () => onSelectedTab(record, "blocked-sites")
              },
              {
                text: "Internet Schedules",
                onClick: () => onSelectedTab(record, "internet-schedules")
              }
            ]
          }>
            <Tooltip title="Tabs"><div className='button micro'><icon>more_vert</icon></div></Tooltip>
          </ContextMenu>
        </group>
      ),
    }
  ];
  const schema = yup.object().shape({
    name: yup.string().label("Profile Name").max(256).required()
  });
  const { control, handleSubmit } = useForm<accountDeviceGroupUpdateViewModel | any>({
    resolver: yupResolver(schema),
  });
  const [accountKiboshDevices, setAccountKiboshDevices] = useState<AccountKiboshDevice[]>([]);
  const [currentAccountKiboshDevice, setCurrentAccountKiboshDevice] = useState<AccountKiboshDevice | null>(null);
  const [currentAccountDeviceGroup, setCurrentAccountDeviceGroup] = useState<AccountDeviceGroup>(new AccountDeviceGroup());
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAccountKiboshDevices, setLoadingAccountKiboshDevices] = useState(false);
  const [preSelectedTab, setPreSelectedTab] = useState<string>("");
  const [reload, setReload] = useState<boolean>(false);
  const [showAccountDeviceGroupModal, setShowAccountDeviceGroupModal] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  let abortController = new AbortController();

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  useEffect(() => {
    if (refresh) {
      if (!accountKiboshDevice?.kiboshDeviceReference) {
        getAccountKiboshDevicesAsync();
      } else {
        setReload(true)
      }
    }
  }, [refresh]);

  const getAccountKiboshDevicesAsync = async () => {
    setAccountKiboshDevices(await getAccountKiboshDevices({}, abortController.signal));
  }

  const getAccountKiboshDevices = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
    setLoadingAccountKiboshDevices(true);
    const response = await apiCall(AccountKiboshDeviceApi.getAccountKiboshDevices(account?.accountId!, request, abortSignal));
    setLoadingAccountKiboshDevices(false);

    return response.success ? AccountKiboshDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    if (!currentAccountKiboshDevice?.kiboshDeviceReference && !accountKiboshDevice?.kiboshDeviceReference)
      return [];

    setLoading(true);
    const response = await apiCall(DashApi.getDeviceGroups(accountKiboshDevice?.kiboshDeviceReference ? accountKiboshDevice?.kiboshDeviceReference : currentAccountKiboshDevice?.kiboshDeviceReference!, abortSignal));
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setLoading(false);

    return response.success ? AccountDeviceGroup.toArrayOfClass(response.data?.value || []) : [];
  };

  const handleDeleteRequest = async () => {
    setLoading(true);
    const response = await apiCall(DashApi.deleteDeviceGroup(accountKiboshDevice?.kiboshDeviceReference ? accountKiboshDevice?.kiboshDeviceReference : currentAccountKiboshDevice?.kiboshDeviceReference!, currentAccountDeviceGroup.id!, abortController.signal));
    setLoading(false);

    if (response.success) {
      message.success(`Account Device deleted successfully.`);
      setIsDeleteConfirmationPopupOpen(false);
      setReload(true);
      if(onMembersListChange) onMembersListChange();
    }
  };

  const onAccountDeviceGroupSave = (e?: AccountDeviceGroup) => {
    setReload(true);
  }

  const onAccountDeviceGroupMembersListChange = () => {
    setReload(true);
    if(onMembersListChange) onMembersListChange();
  }

  const onAccountKiboshDeviceChange = (kiboshDeviceReference: any) => {
    setCurrentAccountKiboshDevice(accountKiboshDevices.filter(x => x.kiboshDeviceReference === kiboshDeviceReference)[0]);
    setReload(true)
  }

  const onEditClick = (accountDeviceGroup: AccountDeviceGroup) => {
    setCurrentAccountDeviceGroup({...accountDeviceGroup});
    setPreSelectedTab("");
    setShowAccountDeviceGroupModal(true);
  };

  const onDeleteClick = (accountDeviceGroup: AccountDeviceGroup) => {
    setCurrentAccountDeviceGroup(accountDeviceGroup);
    setIsDeleteConfirmationPopupOpen(true);
  };

  const onSelectedTab = (accountDeviceGroup: AccountDeviceGroup, selectedTab: any) => {
    if (selectedTab) {
      setCurrentAccountDeviceGroup({...accountDeviceGroup});
      setPreSelectedTab(selectedTab);
      setShowAccountDeviceGroupModal(true);
    }
  };

  const onSubmit: SubmitHandler<accountDeviceGroupUpdateViewModel> = async (formData: accountDeviceGroupUpdateViewModel) => {
    setLoading(true);

    const kiboshDevice = accountKiboshDevice || currentAccountKiboshDevice!;
    formData.kiboshDeviceReference = kiboshDevice.kiboshDeviceReference!;
    const response = await apiCall(DashApi.insertDeviceGroup(formData, undefined, abortController.signal));

    if (response.success) {
      message.success(`Device Profile added successfully.`);

      onAccountDeviceGroupSave();

    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  return (
    <>
      {loading && <Loader />}
      <view>
        {!accountKiboshDevice?.kiboshDeviceReference && (
          <group data-space="15" data-gap="20" data-border="">
            <Select
              autoComplete=""
              label="Kibosh Device"
              labelPosition="left"
              dataLength="320"
              onChange={onAccountKiboshDeviceChange}
              loading={loadingAccountKiboshDevices}
              allowSearch={true}
              options={
                accountKiboshDevices?.map((item) => {
                  return {
                    text: `${item.kiboshDeviceName!} (${item.kiboshDeviceReference!})`,
                    value: item.kiboshDeviceReference!,
                  };
                }) || []
              }
              button={
                <Button
                  material
                  icon="refresh"
                  onClick={getAccountKiboshDevicesAsync}
                />
              }
            />
          </group>
        )}

        <view className="table_cont" data-background="none">
          <group
            data-space="15"
            data-gap="20"
            data-border=""
            data-background="highlight"
          >
            <Input
              control={control}
              labelPosition="left"
              name="name"
              label="Profile Name"
              dataLength="400"
              size="large"
            />
            <Button
              material
              icon="add"
              text="Create new profile"
              primary
              onClick={handleSubmit(onSubmit)}
            />
            <Button
              data-position="right"
              material
              icon="refresh"
              text="Refresh"
              outline
              onClick={() => setReload(true)}
            />
          </group>
          <DataTable
            columns={columns}
            bordered={true}
            customPagination={true}
            reload={reload}
            dataPagination={true}
            getPageAsync={getPage}
            loading={loading}
            rowKey={(record) => `${record.id}`}
            showTotal={true}
            size="small"
            totalRecords={totalRecords}
            onRow={(record) => ({
              onDoubleClick: () => onEditClick(record),
              style: { cursor: "pointer" },
            })}
          />
        </view>
      </view>
      {!!showAccountDeviceGroupModal && (
        <AccountDeviceGroupModal
          open={showAccountDeviceGroupModal}
          account={account!}
          accountDeviceGroup={currentAccountDeviceGroup}
          accountKiboshDevice={
            accountKiboshDevice || currentAccountKiboshDevice!
          }
          preSelectedTab={preSelectedTab}
          onClose={() => setShowAccountDeviceGroupModal(false)}
          onSave={(e) => onAccountDeviceGroupSave(e)}
          onMembersListChange={() => onAccountDeviceGroupMembersListChange()}
        />
      )}
      {isDeleteConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Delete"
          positiveCallback={handleDeleteRequest}
          negativeCallback={() => {
            setIsDeleteConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
