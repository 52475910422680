// 3rd-party modules
import { useEffect, useState } from 'react';

// project modules
import AccountBillings from './billings/AccountBillings';
import AccountForm from './AccountForm';
// import AccountLocations from './locations/AccountLocations';
import AccountKiboshDevices from './kibosh-device/AccountKiboshDevices';
import AccountSubscriptions from './subscriptions/AccountSubscriptions';
import Tab from '../shared/tabstrip/tab';
import TabStrip from '../shared/tabstrip';

// models
import { Account } from '../../models/account';

type Props = {
  refresh: boolean;
  account: Account;
  preSelectedTab?: string
  onClose?: () => void;
  onSave?: (account: Account) => void;
};

export default function AccountTabs({ refresh, account, preSelectedTab, onClose, onSave }: Props) {
  const [currentAccount, setCurrentAccount] = useState<Account>(account);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const abortController = new AbortController();

  useEffect(()=> {
    if (refresh) {
      setCurrentAccount(account);

      if (preSelectedTab) {
        switch (preSelectedTab) {
          case 'details':
            setCurrentTab(0)
            break;
          // case 'locations':
          //   setCurrentTab(1)
          //   break;
          case 'kibosh-devices':
            setCurrentTab(1)
            break;
          case 'billing':
            setCurrentTab(2)
            break;
          case 'subscriptions':
            setCurrentTab(3)
            break;
        }
      }
    }
  },[refresh]);

  const onCancel = () => {
    abortController.abort();

    if(onClose) onClose();
  };

  const onStepSave = (acc: Account, step: string) => {
    switch (step) {
      case 'details':
        if (!account.accountId) { // update currentAccount value on insert
          setCurrentAccount(acc);
        }
        if (onSave) {
          onSave(account);
        }
        break;

      default:
        break;
    }
  };

  return (
    <TabStrip
      skipSecondaryTabs={false}
      selectedIndex={currentTab}
      onChange={setCurrentTab}
      id="account-tab">
      <Tab title='Details'>
        <AccountForm refresh={refresh} account={currentAccount} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'details')} />
      </Tab>
      {/* <Tab title='Locations' disabled={!currentAccount.accountId}>
        {!!currentAccount.accountId &&
          <AccountLocations refresh={!!currentAccount.accountId} account={currentAccount} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'locations')} />
        }
      </Tab> */}
      <Tab title='Kibosh Devices' disabled={!currentAccount.accountId}>
        {!!currentAccount.accountId &&
          <AccountKiboshDevices refresh={!!currentAccount.accountId} account={currentAccount} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'routers')} />
        }
      </Tab>
      <Tab title='Billing' disabled={!currentAccount.accountId}>
        {!!currentAccount.accountId &&
          <AccountBillings refresh={!!currentAccount.accountId} account={currentAccount} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'billings')} />
        }
      </Tab>
      <Tab title='Subscriptions' disabled={!currentAccount.accountId}>
        {!!currentAccount.accountId &&
          <AccountSubscriptions refresh={!!currentAccount.accountId} account={currentAccount} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'subscriptions')} />
        }
      </Tab>
    </TabStrip>
  );
}
