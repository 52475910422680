// 3rd-party modules
import { Outlet, useNavigate } from 'react-router-dom';
import { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';

// project modules
import Button from "../components/shared/button";
import ContextMenu from '../components/shared/contextMenu/contextMenu';
import HeaderNotifications from '../components/global/headerNotifications';
import TopNavigation from '../components/global/topNavigation';
import { logoutUser } from '../stores/userStore';
import { RootState } from '../stores';
import { useAppDispatch } from '../hooks/storeHooks';

// models
import SubNavigation from '../components/global/subNavigation';
import { User } from '../models/auth';
import { SubNavigationItem } from '../models/types/navigation';

// defines
type Props = {
  children?: ReactNode
}

export default function DefaultLayout({ children }: Props) {
  const [submenuItems, setSubmenuItems] = useState<SubNavigationItem[]>([]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const user = useSelector((state: RootState) => state.user.currentUser) as User;

  const onLogoutClick = () => {
    dispatch(logoutUser());
    navigate('/auth/login');
  };

  const onTopNavItemSelect = (items: SubNavigationItem[]) => {
    setSubmenuItems(items);
  };

  const userContextMenuItems = [
    {
      element: (
        <Link to="" onClick={onLogoutClick}>
          <icon data-length="30">logout</icon>{" "}
          <text data-position="left" data-space-left="5">
            Sign out
          </text>
        </Link>
      ),
    },
  ];

  return (
    <>
      <view data-border="no">
          <group data-space="10" data-wrap="no" data-align="center">
            <group
              data-gap="20"
              data-align="center"
              data-space-horizontal="10"
              data-wrap="no"
              data-contain=""
            >
              <a data-type="group" data-width="auto" href="/">
                <logo adaptive="desk">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="120"
                    height="17.531"
                    viewBox="0 0 173.91 17.531"
                  >
                    <path
                      d="M5319.46,360.439h-1.7l-8.19,7.824v-7.824h-1.27v16.8h1.27v-8.28l8.43,8.28h1.7l-8.73-8.64Zm23.04,16.8v-16.8h-1.27v16.8h1.27Z"
                      transform="translate(-5308.31 -359.938)"
                    />
                    <path
                      data-accent=""
                      data-fill="main-color"
                      d="M5376.82,365.076a4.357,4.357,0,0,0-1.5-3.492,6.02,6.02,0,0,0-4.05-1.284h-6.62v16.8h7.08a6.067,6.067,0,0,0,4.1-1.344,4.456,4.456,0,0,0,1.54-3.576,4.107,4.107,0,0,0-2.11-3.768,4.012,4.012,0,0,0,1.56-3.336h0Zm-4.68-.576a1.418,1.418,0,0,1,0,1.932,1.431,1.431,0,0,1-.99.348h-2.21v-2.64h2.21A1.4,1.4,0,0,1,5372.14,364.5Zm-3.2,5.928h2.67a1.464,1.464,0,0,1,1.05.384,1.489,1.489,0,0,1,0,2.052,1.438,1.438,0,0,1-1.05.4h-2.67v-2.832Zm46.32-1.728a8.366,8.366,0,0,0-2.56-6.216,8.962,8.962,0,0,0-12.51,0,8.905,8.905,0,0,0,0,12.456,8.815,8.815,0,0,0,15.07-6.24h0Zm-13.34,0a4.307,4.307,0,0,1,1.3-3.264,4.7,4.7,0,0,1,6.48,0,4.337,4.337,0,0,1,1.29,3.264,4.466,4.466,0,0,1-1.29,3.288,4.525,4.525,0,0,1-3.24,1.272,4.4,4.4,0,0,1-4.54-4.56h0Zm44.5,7.368a4.68,4.68,0,0,0,1.75-3.864,4.089,4.089,0,0,0-1.75-3.672,9.752,9.752,0,0,0-1.64-.912c-0.46-.192-1.12-0.416-1.96-0.672a9.9,9.9,0,0,1-2.3-.888,1.1,1.1,0,0,1-.58-0.936,0.946,0.946,0,0,1,.4-0.8,1.931,1.931,0,0,1,1.16-.3,2.7,2.7,0,0,1,2.61,1.872l3.6-2.112a6.425,6.425,0,0,0-2.49-2.844,7.063,7.063,0,0,0-3.75-1,6.22,6.22,0,0,0-4.17,1.44,4.739,4.739,0,0,0-1.66,3.768,4.421,4.421,0,0,0,1.27,3.312,8.6,8.6,0,0,0,3.72,1.944c1.15,0.336,1.86.552,2.11,0.648a1.466,1.466,0,0,1,1.13,1.2c0,0.752-.66,1.128-1.97,1.128a3.341,3.341,0,0,1-3.31-2.232l-3.67,2.136a5.95,5.95,0,0,0,2.48,3.072,8.128,8.128,0,0,0,4.38,1.1A7.258,7.258,0,0,0,5446.42,376.068Zm26.8-9.552V360.3h-4.29v16.8h4.29v-6.456h4.73V377.1h4.27V360.3h-4.27v6.216h-4.73Z"
                      transform="translate(-5308.31 -359.938)"
                    />
                  </svg>
                </logo>
                <logo adaptive="mobile">
                  <icon>home</icon>
                </logo>
              </a>
              <separator vertical="" data-height="20"></separator>
              <TopNavigation onNavItemSelect={onTopNavItemSelect} />
            </group>
            <group
              data-width="auto"
              data-position="right"
              data-wrap="no"
              data-align="center"
              data-gap="10"
            >
              <HeaderNotifications />
              <Button>
                <text>Kibosh</text>
              </Button>
              <separator vertical="" data-height="20"></separator>
              <ContextMenu placement="bottomLeft" items={userContextMenuItems}>
                <Button adaptive>
                  <icon>person</icon>
                  <text>
                    {user && `${user.firstName} ${user.lastName} (${user.username})`}
                  </text>
                </Button>
              </ContextMenu>
            </group>
          </group>
          <group
            data-wrap="no"
            data-align="center"
            data-gap="10"
            data-border=""
          >
            <SubNavigation navItems={submenuItems} />
          </group>
          <view>
            {children}
            <Outlet />
          </view>
      </view>
    </>
  );
}
