import classNames from 'classnames';
import React, {PropsWithChildren} from 'react';
import {HTMLAttributes} from 'react';
import {useState} from 'react';
import {MouseEventHandler, RefObject, useRef} from 'react';
import {useMaterialEffect} from '../../hooks/useMaterialEffectHook';
import {Text} from './text';
import { Spin } from 'antd';

interface ButtonProps extends HTMLAttributes<HTMLDivElement> {
  accent?: boolean;
  adaptive?: boolean;
  className?: string;
  disabled?: boolean | string;
  error?: boolean;
  extra?: boolean;
  fab?: boolean;
  fit?: boolean;
  highlight?: boolean;
  icon?: string;
  iconMicro?: boolean;
  large?: boolean;
  xlarge?: boolean;
  material?: boolean;
  mini?: boolean;
  micro?: boolean;
  onClick?: MouseEventHandler<HTMLDivElement> | (() => any);
  open?: boolean;
  outline?: boolean;
  preview?: boolean;
  primary?: boolean;
  rounded?: boolean;
  show?: boolean;
  space?: boolean;
  special?: boolean;
  text?: string;
  textFirst?: boolean;
  toggleClassName?: string;
  wide?: boolean;
  position?: string;
  loading?: boolean;
}

const Button: React.FC<PropsWithChildren<ButtonProps>> = React.forwardRef<
  HTMLDivElement,
  PropsWithChildren<ButtonProps>
>(
  (
    {
      accent = false,
      adaptive = false,
      className = '',
      disabled = false,
      error = false,
      extra = false,
      fab = false,
      fit = false,
      highlight = false,
      icon = '',
      iconMicro = false,
      large = false,
      mini = false,
      micro = false,
      onClick,
      open = false,
      outline = false,
      preview = false,
      primary = false,
      rounded = false,
      show = false,
      space = false,
      special = false,
      text = '',
      textFirst = true,
      wide = false,
      children,
      material = true,
      toggleClassName = '',
      position,
      loading = false,
      ...rest
    },
    ref
  ) => {
    const refObj = useRef(null);
    const [iconCode, fill] = icon.split('.');
    const [addToggleClassNames, setAddToggleClassNames] = useState(false);
    let iconProps = null;
    if (fill === 'fill') {
      iconProps = {fill: ''};
    }
    const {svg, onMouseDown, onMouseLeave, onMouseUp} = useMaterialEffect({
      ref: (ref || refObj) as RefObject<HTMLDivElement>,
      isMaterial: material,
    });

    return (
      <div
        ref={ref || refObj}
        className={classNames(
          'button',
          {
            accent,
            adaptive,
            error,
            extra,
            fab,
            fit,
            highlight,
            large,
            mini,
            micro,
            open,
            outline,
            preview,
            primary,
            rounded,
            show,
            special,
            space,
            wide,
            ...(toggleClassName
              ? {[toggleClassName]: addToggleClassNames}
              : null),
          },
          className
        )}
        onClick={(e) => {
          if (disabled || loading) {
            return;
          }
          toggleClassName && setAddToggleClassNames((prev) => !prev);
          onClick && onClick(e);
        }}
        onMouseDown={onMouseDown}
        onMouseLeave={onMouseLeave}
        onMouseUp={onMouseUp}
        {...rest}
        {...(position ? {'data-position': position} : null)}
        {...(disabled || loading ? {disabled: true} : null)}
        {...(material ? {effect: 'material'} : null)}
      >
        <Spin spinning={loading}></Spin>        
        {iconCode && (
          <icon {...iconProps} {...(iconMicro ? {micro: ''} : null)}>
            {iconCode}
          </icon>
        )}
        {text && textFirst && <Text>{text}</Text>}
        {children}
        {text && !textFirst && <Text>{text}</Text>}
        {svg}
      </div>
    );
  }
);

export default Button;
