// 3rd-party modules
import storage from 'redux-persist/lib/storage';
import { combineReducers } from 'redux';
import { encryptTransform } from 'redux-persist-transform-encrypt';
import { configureStore } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';

// project modules
import userReducer from './userStore';
import componentsReducer from './componentsSlice';

const persistConfig = {
  key: 'redux',
  storage: storage,
  whitelist: ['user'], // which reducer want to store
  transforms: [
    encryptTransform({
      secretKey: 'Password@2'
    }),
  ],
};
const reducers = combineReducers({
  user: userReducer,
  components: componentsReducer,
});
const persistedReducer = persistReducer(persistConfig, reducers);

export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false
  }),
  reducer: persistedReducer
});

// infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
