// 3rd-party modules
import { Tooltip, message } from "antd";
import { useEffect, useState } from "react";

// project modules
import AccountKiboshDeviceModal from "./AccountKiboshDeviceModal";
import Button from "../../shared/button";
import ConfirmationPopup from "../../shared/popup/confirmationPopup";
import ContextMenu from "../../shared/contextMenu/contextMenu";
import Loader from "../../shared/loader";
import DataTable, { TableColumn } from "../../shared/list/dataTable";
import { apiCall } from "../../../helpers/apiHelper";

// apis
import * as AccountKiboshDeviceApi from '../../../apis/accountKiboshDeviceApi';
import * as DashApi from '../../../apis/dashApi';

// models
import { Account } from "../../../models/account";
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from "../../../models/dataSourceRequest";
import { AccountKiboshDevice } from "../../../models/accountKiboshDevice";

type Props = {
  account?: Account;
  refresh?: boolean;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
};

export default function AccountKiboshDevices({ account, refresh, onCancel, onSave }: Props) {
  const columns: TableColumn<AccountKiboshDevice>[] = [
    {
      title: 'Kibosh Device Name',
      dataIndex: 'kiboshDeviceName',
      key: 'kiboshDeviceName',
      filterable: true,
    },
    {
      title: 'Device Type',
      dataIndex: 'kiboshDeviceType',
      key: 'kiboshDeviceType',
      filterable: true,
      width: 150
    },
    {
      title: 'Kibosh Device Reference',
      dataIndex: 'kiboshDeviceReference',
      key: 'kiboshDeviceReference',
      filterable: true,
      width: 350
    },
    {
      title: '',
      key: 'action',
      width: 60,
      render: (_, record: any) => (
        <>
          {
            !record.inactive &&
            <group data-wrap='no' data-gap="5">
              <Tooltip title="Reset Password"><div className='button micro' onClick={() => onChangePasswordClick(record)}><icon>key</icon></div></Tooltip>
              <separator vertical="" data-adaptive="desktop"></separator>
              <Tooltip title="Edit"><div className='button micro' onClick={() => onEditClick(record)}><icon>edit_square</icon></div></Tooltip>
              <Tooltip title="Delete"><div className='button micro' onClick={() => onDeleteClick(record)}><icon>delete</icon></div></Tooltip>
              <separator vertical="" data-adaptive="desktop"></separator>
              <ContextMenu placement="bottomLeft" items={
                [
                  {
                    text: "Details",
                    onClick: () => onSelectedTab(record, "details")
                  },
                  {
                    text: "Devices",
                    onClick: () => onSelectedTab(record, "devices")
                  },
                  {
                    text: "Profiles",
                    onClick: () => onSelectedTab(record, "device-groups")
                  },
                  {
                    text: "Blocked Sites",
                    onClick: () => onSelectedTab(record, "blocked-sites")
                  },
                  {
                    text: "Activity Logs",
                    onClick: () => onSelectedTab(record, "activity-logs")
                  },
                  {
                    text: "Internet Schedules",
                    onClick: () => onSelectedTab(record, "internet-schedules")
                  },
                  {
                    text: "Bandwidth Logs",
                    onClick: () => onSelectedTab(record, "bandwidth-logs")
                  },
                  {
                    text: "Settings",
                    onClick: () => onSelectedTab(record, "settings")
                  }
                ]
              }>
                <Tooltip title="Tabs"><div className='button micro'><icon>more_vert</icon></div></Tooltip>
              </ContextMenu>
            </group>
          }
        </>
      ),
    }
  ];
  const [currentAccountKiboshDevice, setCurrentAccountKiboshDevice] = useState<AccountKiboshDevice>(new AccountKiboshDevice());
  const [loading, setLoading] = useState(false);
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);
  const [isResetPasswordConfirmationPopupOpen, setIsResetPasswordConfirmationPopupOpen] = useState(false);
  const [preSelectedTab, setPreSelectedTab] = useState<string>("");
  const [reload, setReload] = useState<boolean>(false);
  const [showAccountKiboshDeviceModal, setShowAccountKiboshDeviceModal] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    const request = { conditionList, sortList, pagination } as DataSourceRequest;

    setLoading(true);
    const response = await apiCall(AccountKiboshDeviceApi.getAccountKiboshDevices(account?.accountId!, request, abortSignal));
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setLoading(false);

    return response.success ? AccountKiboshDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const handleDeleteRequest = async () => {
    setLoading(true);
    const response = await apiCall(AccountKiboshDeviceApi.deleteAccountKiboshDevice({ ...currentAccountKiboshDevice, kiboshDeviceType: undefined })); // kiboshDeviceType is not part of data-model
    setLoading(false);

    if (response.success) {
      message.success(`Account Kibosh Device deleted successfully.`);
      setReload(true);
    }
    setIsDeleteConfirmationPopupOpen(false);

    setReload(true);
  };

  const handleResetPasswordRequest = async () => {
    setLoading(true);
    const response = await apiCall(DashApi.resetAccountKiboshDevicePassword(currentAccountKiboshDevice.kiboshDeviceReference!));
    setLoading(false);

    if (response.success) {
      message.success(`Account Kibosh Device password reseted successfully.`);
      setReload(true);
    }
    setIsResetPasswordConfirmationPopupOpen(false);
  };

  const onAccountKiboshDeviceSave = (e: Account) => {
    setReload(true);
  }

  const onChangePasswordClick = (accountKiboshDevice: AccountKiboshDevice) => {
    setCurrentAccountKiboshDevice(accountKiboshDevice);
    setIsResetPasswordConfirmationPopupOpen(true);
  };

  const onDeleteClick = (accountKiboshDevice: AccountKiboshDevice) => {
    setCurrentAccountKiboshDevice(accountKiboshDevice);
    setIsDeleteConfirmationPopupOpen(true);
  };

  const onEditClick = (accountKiboshDevice: AccountKiboshDevice) => {
    setCurrentAccountKiboshDevice({...accountKiboshDevice});
    setPreSelectedTab("");
    setShowAccountKiboshDeviceModal(true);
  };

  const onNewClick = () => {
    setCurrentAccountKiboshDevice(new AccountKiboshDevice());
    setPreSelectedTab("");
    setShowAccountKiboshDeviceModal(true);
  };

  const onSelectedTab = (accountKiboshDevice: AccountKiboshDevice, selectedTab: any) => {
    if (selectedTab) {
      setCurrentAccountKiboshDevice({...accountKiboshDevice});
      setPreSelectedTab(selectedTab);
      setShowAccountKiboshDeviceModal(true);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <view>
        <view className="table_cont" data-background="none">
          <group
            data-space="15"
            data-gap="20"
            data-border=""
            data-background="highlight"
          >
            <Button
              material
              icon="add"
              text="New"
              primary
              onClick={onNewClick}
            />
            <Button
              data-position="right"
              material
              icon="refresh"
              text="Refresh"
              outline
              onClick={() => setReload(true)}
            />
          </group>
          <DataTable
            columns={columns}
            bordered={true}
            customPagination={true}
            reload={reload}
            dataPagination={true}
            getPageAsync={getPage}
            loading={loading}
            rowKey={(record) => `${record.accountKiboshDeviceId}`}
            showTotal={true}
            size="small"
            totalRecords={totalRecords}
            rowClassName={(record: any, index: any) => {
              // Check condition and return appropriate class name
              if (record.inactive) {
                return 'inactive-row'; // CSS class for inactive rows
              } else {
                return 'active-row'; // CSS class for active rows
              }
            }}
            onRow={(record) => ({
              onDoubleClick: () => !record.inactive && onEditClick(record),
              style: { cursor: record.inactive ? "" : "pointer"},
            })}
          />
        </view>
      </view>
      {!!showAccountKiboshDeviceModal && (
        <AccountKiboshDeviceModal
          open={showAccountKiboshDeviceModal}
          closeOnSave={true}
          account={account!}
          accountKiboshDevice={currentAccountKiboshDevice}
          preSelectedTab={preSelectedTab}
          onClose={() => setShowAccountKiboshDeviceModal(false)}
          onSave={(e) => onAccountKiboshDeviceSave(e)}
        />
      )}
      {isDeleteConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Delete"
          positiveCallback={handleDeleteRequest}
          negativeCallback={() => {
            setIsDeleteConfirmationPopupOpen(false);
          }}
        />
      )}
      {isResetPasswordConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Reset Password"
          positiveCallback={handleResetPasswordRequest}
          negativeCallback={() => {
            setIsResetPasswordConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
