// project modules
import { getBaseUrl, sendRequest } from '../helpers/apiHelper';

export const getAccountsPerCountryReport = async (abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/report/accountsPerCountry`, 'get', null, null, abortSignal);
};

export const getAccountSummary = async (abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/report/accountSummary`, 'get', null, null, abortSignal);
};

export const getRevenueSummary = async (abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/report/revenueSummary`, 'get', null, null, abortSignal);
};
