// 3rd-party modules
import { ISchema } from "yup";
import { SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

// project modules
import Button from "../shared/button";
import Checkbox from "../shared/inputs/checkbox";
import Input from "../shared/inputs/input";
import Select from "../shared/inputs/select";
import yup from "../../plugins/yup";
import { DatePicker } from "../shared/inputs/datePicker";

// models
import { FilterDescriptor } from "../../models/dataSourceRequest";

// defines
import { CONSTANTS } from "../../helpers/defines";

type Props = {
  show?: boolean;
  filters: Filter[];
  onApplyClick?: (formData: any, conditions?: FilterDescriptor[]) => void;
  onCancelClick?: () => void;
};

type Filter = {
  type: "text" | "date" | "checkbox" | "select",
  name: string,
  label: string,
  yupSchema?: ISchema<any>,
  conditions?: FilterDescriptor[],
  props?: any,
  items?: Array<{ text: string, value: string}>
};

export default function AdvancedSearch({show = false, filters = [], onApplyClick, onCancelClick}: Props) {
  let yupSchema: any = {};

  for (const filter of filters) {
    yupSchema[filter.name] = filter.yupSchema
  }

  const schema = yup.object().shape(yupSchema);
  const { control, handleSubmit, reset, setValue } = useForm<any>({
    resolver: yupResolver(schema),
  });
  const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(show);

  useEffect(() => {
    filters.map(filter => {
      if (filter.type === "checkbox") setValue(filter.name, false)
    })
  }, []);

  useEffect(() => { setShowAdvancedSearch(show) }, [show])

  const renderFilter = (filter: Filter) => {
    switch(filter.type) {
      case "text":
        return (
          <Input
            control={control}
            name={filter.name}
            label={filter.label}
            key={filter.name}
            labelPosition="left"
            dataLength={filter.props?.dataLength || "200"}
            size="large"
            {...filter.props}
          />);
      case "date":
        return (
          <DatePicker
            control={control}
            name={filter.name}
            label={filter.label}
            key={filter.name}
            labelPosition="left"
            format={CONSTANTS.DEFAULT_DATE_FORMAT}
            dataLength={filter.props?.dataLength || "200"}
            {...filter.props}
          />);
      case "checkbox":
        return (
          <Checkbox
            control={control}
            name={filter.name}
            label={filter.label}
            key={filter.name}
            minimal
            dataLength={filter.props?.dataLength || "200"}
            {...filter.props}
          />);
      case "select":
        return (
          <Select
            control={control}
            name={filter.name}
            label={filter.label}
            key={filter.name}
            allowClear={filter.props?.allowClear || undefined}
            labelPosition="left"
            dataLength={filter.props?.dataLength || "200"}
            allowSearch={filter.props?.allowSearch || true}
            options={
              filter.items?.map((item) => {
                return {
                  text: item.text,
                  value: item.value,
                };
              }) || []
            }
            {...filter.props}
          />);
    }
  };

  const onAdvancedSearchApplyClick: SubmitHandler<any> = async (formData: any) => {
    const conditions: FilterDescriptor[] = [];

    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        const filter = filters.find(f => f.name === key);
        const value = formData[key];

        if (filter && filter.conditions) {
          filter.conditions.forEach(c => c.fieldValue = value);
          conditions.push(...filter.conditions);
          continue;
        }

        if (value) {
          let f: FilterDescriptor = {
            fieldName: key,
            fieldValue: value
          }

          conditions.push(f)
        }
      }
    }

    if (onApplyClick) onApplyClick(formData, conditions);
  };

  const onAdvancedSearchCancelClick = () => {
    reset();

    if (onApplyClick) onApplyClick(null);
    if (onCancelClick) onCancelClick();
  };

  return (
    <>
      {
        showAdvancedSearch &&
        <group data-space="15" data-gap="10" data-border="" data-background="highlight" data-align='center'>
          {filters.map(filter => renderFilter(filter))}
          <separator vertical="" data-height="20"></separator>
          <Button
            material
            text="Apply"
            primary
            onClick={handleSubmit(onAdvancedSearchApplyClick)}
          />
          <Button
            material
            text="Cancel"
            outline
            onClick={onAdvancedSearchCancelClick}
          />
        </group>
      }
    </>
  )
}
