// 3rd-party modules
// import Iframe from "react-iframe";
import parse from 'html-react-parser';

export default function SeverMonitor() {
  return (
    <>
      <group data-space="20" data-border="" data-sticky="top" data-backdrop="">
        <text data-ellipsis="" data-index="1" data-weight="700">
          Server Monitor
        </text>
      </group>
      <group data-space="20">
        <group
          data-radius="20"
          data-background="main-background"
          data-border=""
          data-contain=""
          data-space="20"
        >
          {parse('<iframe src="https://prtg.kibosh.com/group.htm?id=0&tabid=1&username=kibosh&passhash=2967382420" width="100%" height="900" frameborder="0" allowfullscreen="allowfullscreen"></iframe>')}
        </group>
      </group>
    </>
  );
}
