// project modules
import CommonValuesTable from "../../components/global/commonValuesTable";
import DataTable, { TableColumn } from "../../components/shared/list/dataTable";

export default function SettingsPage() {
  const columns: TableColumn<any>[] = [
    {
      title: 'Setting Title',
      dataIndex: 'title',
      key: 'title'
    }
  ];

  const dataSource = [
    {
      id: 1,
      title: "Post Language",
      code: "post-language"
    },
    {
      id: 2,
      title: "Support Ticket Categories",
      code: "support-ticket-category"
    },
    {
      id: 3,
      title: "Post Categories",
      code: "post-category"
    },
    {
      id: 4,
      title: "Application Categories",
      code: "application-category"
    }
  ];

  return (
    <>
      <view className="table_cont" data-background="none">
        <DataTable
          columns={columns}
          bordered={true}
          dataSource={dataSource}
          rowKey={record => `${record.id}`}
          showTotal={true}
          size="small"
          expandable={{
            expandedRowRender: (record) => (
              <CommonValuesTable commonCode={record.code} title={record.title} />
            ),
          }}
        />
      </view>
    </>
  );
}
