// 3rd-party modules
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

// project modules
import { apiCall } from '../helpers/apiHelper';

// models
import { ApiResponse } from '../models/response';
import { User } from '../models/auth';

// apis
import * as AuthApi from '../apis/authApi';

// models
import { loginViewModel } from '../models/types/auth';

// defines
export type RememberUser = {
  username: string | undefined
  remember: boolean
}

// state
export interface UserState {
  currentUser: User
  authToken: string
  rememberUser: RememberUser
}

const initialState: UserState = {
  currentUser: new User(),
  authToken: '',
  rememberUser: { username: undefined, remember: false }
};

export const loginUser = createAsyncThunk<ApiResponse, loginViewModel>('user/loginUser', async (loginInfo) => {
  return await apiCall(AuthApi.loginUser(loginInfo));
});

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAuthToken: (state, action: PayloadAction<string>) => {
      state.authToken = action.payload;
    },
    setCurrentUser: (state, action: PayloadAction<User>) => {
      state.currentUser = action.payload;
    },
    logoutUser: (state) => {
      state.authToken = '';
      state.currentUser = new User()
    },
    setRememberUser: (state, action: PayloadAction<RememberUser>) => {
      state.rememberUser = action.payload;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      state.authToken = payload.extra?.token?.value || '';
      state.currentUser = User.toClass(payload.data?.value);
    });
  }
});

// action creators
export const { logoutUser, setAuthToken, setCurrentUser, setRememberUser } = userSlice.actions;

export default userSlice.reducer;
