// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, Transform, plainToInstance } from "class-transformer";

export class User {
  @Expose({ name: 'id' })
  public userId?: number;
  @Expose()
  public firstName?: string;
  @Expose()
  public lastName?: string;
  @Expose()
  @Transform(({ value, key, obj }) => obj.username || obj.userName)
  public username?: string;
  @Expose()
  public email?: string;
  @Expose()
  public lockoutEnabled?: boolean;
  @Expose()
  public roles: string[] = [];

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(User, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(User, camelcaseKeys(data, { deep: true }) as User[], { excludeExtraneousValues: true });
  }
}

export class Role {
  @Expose()
  public roleId?: number;
  @Expose()
  public roleName?: string;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(Role, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(Role, camelcaseKeys(data, { deep: true }) as Role[], { excludeExtraneousValues: true });
  }
}
