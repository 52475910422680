// 3rd-party modules
import { Tooltip, message } from 'antd';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";

// project modules
import { apiCall } from "../../../helpers/apiHelper";
import AccountBlockedSites from '../blocked-sites/AccountBlockedSites';
import AccountInternetSchedules from '../internet-schedules/AccountInternetSchedules';
import Button from "../../shared/button";
import Checkbox from '../../shared/inputs/checkbox';
// import ConfirmationPopup from '../../shared/popup/confirmationPopup';
import Input from "../../shared/inputs/input";
import Loader from "../../shared/loader";
import Popup from '../../shared/popup/popup';
import TabStrip from '../../shared/tabstrip';
import Select from '../../shared/inputs/select';
import Tab from '../../shared/tabstrip/tab';
import yup from "../../../plugins/yup";

// apis
import * as DashApi from '../../../apis/dashApi';

// models
import { Account } from '../../../models/account';
import { AccountDeviceGroup } from '../../../models/accountDeviceGroup';
import { AccountKiboshDevice } from '../../../models/accountKiboshDevice';
import { ApiResponse } from "../../../models/response";
import { accountDeviceGroupUpdateViewModel } from '../../../models/types/accountDeviceGroup';
import { accountDeviceBlockViewModel } from '../../../models/types/accountDevice';
import { getDeviceSignalRateClassIcon } from '../../../helpers';
import { AccountDevice } from '../../../models/accountDevice';
import DataTable, { TableColumn } from '../../shared/list/dataTable';
import { FilterDescriptor, PaginationDescriptor, SortDescriptor } from '../../../models/dataSourceRequest';
import AccountDeviceBlockingModal from '../devices/AccountDeviceBlockingModal';
import { CONSTANTS } from '../../../helpers/defines';
import moment from 'moment-timezone';

type Props = {
  account: Account;
  accountDeviceGroup: AccountDeviceGroup;
  accountKiboshDevice: AccountKiboshDevice;
  closeOnSave?: boolean;
  preSelectedTab?: string;
  open: boolean;
  onClose?: () => void;
  onMembersListChange?: () => void;
  onSave?: (accountDeviceGroup: any) => void;
};

// const getDeviceTypes = async (abortSignal?: AbortSignal) => {
//   const response = await apiCall(CommonValueApi.getDeviceTypes(abortSignal));

//   return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
// };

export default function AccountDeviceGroupModal({ account, accountDeviceGroup, accountKiboshDevice, preSelectedTab, closeOnSave = false, open, onClose, onMembersListChange, onSave }: Props) {
  const columns: TableColumn<AccountDevice>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      filterable: true,
      width: 150
    },
    {
      title: 'Device Name',
      dataIndex: 'name',
      key: 'name',
      filterable: true,
    },
    {
      title: 'Device IP',
      dataIndex: 'ip',
      key: 'ip',
      filterable: true,
      width: 110
    },
    {
      title: 'Static IP',
      dataIndex: 'staticIp',
      key: 'staticIp',
      filterable: true,
      width: 110
    },
    {
      title: 'WiFi Signal',
      dataIndex: 'wifiSignal',
      key: 'wifiSignal',
      dataType: 'number',
      width: 90
    },
    {
      title: 'Ping',
      dataIndex: 'pingMs',
      key: 'pingMs',
      filterable: true,
      width: 100,
      render: (text: number) => text > 0 ? `${text.toFixed(2)}ms` : "disconnected"
    },
    {
      title: 'Quality',
      key: 'connectionQuality',
      width: 100,
      render: (_, record: any) => (
        <group data-justify='center'>
          <icon>{getDeviceSignalRateClassIcon(record.pingMs!)}</icon>
        </group>
      )
    },
    {
      title: 'Connection',
      dataIndex: 'connectionStatus',
      key: 'connectionStatus',
      width: 110
    },
    {
      title: 'Activity Logs',
      dataIndex: 'loggingEnabled',
      key: 'loggingEnabled',
      dataType: 'bool',
      width: 110
    },
    {
      title: '',
      key: 'action',
      width: 30,
      render: (_, record: any) => (
        <group data-wrap='no' data-gap="5">
          <Tooltip title="Delete"><div className='button micro' onClick={() => onRemoveClick(record)}><icon>delete</icon></div></Tooltip>
        </group>
      ),
    }
  ];
  const schema = yup.object().shape({
    name: yup.string().label("Device Name").max(256).required()
  });
  const [activityLogsEnabled, setActivityLogsEnabled] = useState<boolean>(false);
  const [currentAccountDeviceId, setCurrentAccountDeviceId] = useState<string>("");
  const [currentAccountDeviceGroup, setCurrentAccountDeviceGroup] = useState<AccountDeviceGroup>(accountDeviceGroup);

  const [currentTab, setCurrentTab] = useState<number>(0);
  const [freeAccountDevices, setFreeAccountDevices] = useState<AccountDevice[]>([]);
  // const [blockingData, setBlockingDate] = useState<accountDeviceBlockViewModel | null>(null);
  const [internetData, setInternetData] = useState<accountDeviceBlockViewModel | null>(null);
  // const [isBlockingConfirmationPopupOpen, setBlockingConfirmationPopupOpen] = useState(false);
  const [isHasInternetConfirmationPopupOpen, setHasInternetConfirmationPopupOpen] = useState(false);
  // const [isUnblocked, setIsUnblocked] = useState<boolean>(false);
  const [hasInternet, setHasInternet] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  const abortController = new AbortController();

  const { control, handleSubmit } = useForm<accountDeviceGroupUpdateViewModel | any>({
    defaultValues: { ...currentAccountDeviceGroup },
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    if (open) {
      if (preSelectedTab) {
        switch (preSelectedTab) {
          case 'details':
            setCurrentTab(0)
            break;
          case 'blocked-sites':
            setCurrentTab(1)
            break;
          case 'internet-schedules':
            setCurrentTab(2)
            break;
        }
      }
    }
  }, [open]);

  useEffect(() => {
    setHasInternet(!currentAccountDeviceGroup.isBlocked);
    setActivityLogsEnabled(!!currentAccountDeviceGroup.loggingEnabled);
    // setIsUnblocked(!currentAccountDeviceGroup.isBlocked);
  }, [currentAccountDeviceGroup]);

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    if (!accountKiboshDevice?.kiboshDeviceReference)
      return [];

    setLoading(true);
    const response = await apiCall(DashApi.getClientDevices(accountKiboshDevice?.kiboshDeviceReference!, abortSignal));

    const data = response.success ? AccountDevice.toArrayOfClass(response.data?.value || []) : [];
    const freeDevices = data.filter(x => !x.groupId);
    const groupDevices = data.filter(x => x.groupId === currentAccountDeviceGroup.id);

    setFreeAccountDevices(freeDevices);

    setTotalRecords(groupDevices.length);

    setLoading(false);

    return groupDevices;
  };

  const onAddNewDeviceToGroup = async () => {
    setLoading(true);
    const data: accountDeviceGroupUpdateViewModel = {
      name: currentAccountDeviceGroup.name!,
      kiboshDeviceReference: accountKiboshDevice.kiboshDeviceReference!
    }
    let response: ApiResponse = await apiCall(DashApi.addDeviceToDeviceGroup(currentAccountDeviceGroup.id!, currentAccountDeviceId, data, abortController.signal));

    if (response.success) {
      message.success(`Device added successfully.`);
      setCurrentAccountDeviceId("");
      setReload(true);

      if (onMembersListChange) onMembersListChange();
    } else
      message.error(response.error?.value);

    setLoading(false);
  }

  const onCancel = () => {
    abortController.abort();

    if(onClose) onClose();
  };

  const onRemoveClick = async (record: any) => {
    setLoading(true);
    let response: ApiResponse = await apiCall(DashApi.removeDeviceFromDeviceGroup(accountKiboshDevice?.kiboshDeviceReference!, currentAccountDeviceGroup.id!, record.id, abortController.signal));

    if (response.success) {
      message.success(`Device removed successfully.`);
      setCurrentAccountDeviceId("");
      setReload(true);

      if (onMembersListChange) onMembersListChange();
    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  const onSubmit: SubmitHandler<accountDeviceGroupUpdateViewModel> = async (formData: accountDeviceGroupUpdateViewModel) => {
    let response: ApiResponse;

    setLoading(true);

    formData.kiboshDeviceReference = accountKiboshDevice.kiboshDeviceReference!;

    if (!currentAccountDeviceGroup?.id)
      response = await apiCall(DashApi.insertDeviceGroup(formData, undefined, abortController.signal));
    else
      response = await apiCall(DashApi.updateDeviceGroup(currentAccountDeviceGroup.id, formData, abortController.signal));

    if (response.success) {
      message.success(`Profile ${!currentAccountDeviceGroup?.id ? 'added' : 'edited'} successfully.`);

      if (onSave) onSave(response.data?.value);

      if (closeOnSave) {
        open = false;

        onCancel();
      }

    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  // const onBlockingCheckboxChange = async (event: any) => {
  //   const data: accountDeviceBlockViewModel = {
  //     blocked: !event.target?.checked,
  //     deviceId: currentAccountDeviceGroup.id!,
  //     kiboshDeviceReference: accountKiboshDevice.kiboshDeviceReference!,
  //     minutesBeforeExpiration: 0
  //   }
  //   setBlockingDate(data);
  //   setBlockingConfirmationPopupOpen(true);
  // }

  // const handleBlocking = async () => {
  //   setLoading(true);

  //   let response: ApiResponse = await apiCall(DashApi.blockClientDevice(currentAccountDeviceGroup.id!, blockingData!, abortController.signal));

  //   if (response.success) {
  //     message.success(`Device blocked status changed successfully.`);
  //     setIsUnblocked(!blockingData?.blocked!);
  //     setBlockingConfirmationPopupOpen(false);

  //     if (onSave) onSave(response.data?.value);
  //   } else
  //     message.error(response.error?.value);

  //   setLoading(false);
  // }

  const onActivityLogsCheckboxChange = async (event: any) => {
    setLoading(true);

    const response = await apiCall(DashApi.updateDeviceActivityLogEnableState(accountKiboshDevice?.kiboshDeviceReference!, currentAccountDeviceGroup.id!, event.target?.checked, abortController.signal));

    if (response.success) {
      message.success("Activity Logs enabled state changed successfully.");
      setActivityLogsEnabled(!event.target?.checked);

      if (onSave) onSave(response.data?.value);
    } else
      message.error(response.error?.value);

    setLoading(false);
  }

  const onInternetCheckboxChange = async (event: any) => {
    const data: accountDeviceBlockViewModel = {
      blocked: !event.target?.checked,
      deviceId: `${currentAccountDeviceGroup.id!}`,
      kiboshDeviceReference: accountKiboshDevice.kiboshDeviceReference!,
      minutesBeforeExpiration: 0
    }
    setInternetData(data);
    setHasInternetConfirmationPopupOpen(true);
  }

  // const handleInternetCheckboxChange = async () => {
  //   setLoading(true);

  //   let response: ApiResponse = await apiCall(DashApi.blockClientDevice(currentAccountDeviceGroup.id!, internetData!, abortController.signal));

  //   if (response.success) {
  //     message.success(`Device internet status changed successfully.`);
  //     setHasInternet(!internetData!.blocked);
  //     setHasInternetConfirmationPopupOpen(false);

  //     if (onSave) onSave(response.data?.value);
  //   } else
  //     message.error(response.error?.value);

  //   setLoading(false);
  // }

  const getAccountDeviceGroup = async () => {
    if (!accountKiboshDevice.kiboshDeviceReference || !currentAccountDeviceGroup.id) {
      return;
    }

    setLoading(true);
    const response = await apiCall(DashApi.getDeviceGroupById(accountKiboshDevice.kiboshDeviceReference!, currentAccountDeviceGroup.id!, abortController.signal));
    setLoading(false);

    return response.success ? AccountDeviceGroup.toClass(response.data?.value) : currentAccountDeviceGroup;
  };

  const onAccountDeviceBlockingSave = async () => {
    setHasInternet(!hasInternet)
    const data = await getAccountDeviceGroup() || currentAccountDeviceGroup;
    setCurrentAccountDeviceGroup(data);
    if (onSave) {
      onSave(data);
    }
  }

  const renderDetailsTab = () => {
    return (
      <>
        <view>
          <view className="table_cont" data-background="none">
            <group
              data-space="15"
              data-gap="20"
              data-border=""
              data-background="highlight"
            >
              <Input
                control={control}
                labelPosition="left"
                name="name"
                label="Profile Name"
                dataLength="400"
                size="large"
                button={
                  <Button
                    material
                    text="Save Changes"
                    primary
                    onClick={handleSubmit(onSubmit)}
                  />
                }
              />
              <Checkbox
                label="Activity Logs Enabled"
                minimal
                dataLength="auto"
                onChange={onActivityLogsCheckboxChange}
                checked={activityLogsEnabled}/>
              <Checkbox
                label={hasInternet ? "Internet Enabled for device" : `Internet Disabled for device until ${moment(currentAccountDeviceGroup.blockedUntil).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}`}
                minimal
                dataLength="auto"
                onChange={onInternetCheckboxChange}
                checked={hasInternet}/>
              {/* <Checkbox
                label="Blocking Enabled for Device"
                minimal
                dataLength="auto"
                onChange={onBlockingCheckboxChange}
                checked={isUnblocked}/> */}
            </group>
            <group
              data-space="15"
              data-gap="20"
              data-border=""
              data-background="highlight"
            >
              <Select
                autoComplete=""
                label="Device"
                labelPosition="left"
                dataLength="320"
                value={currentAccountDeviceId}
                onChange={(e: any) => setCurrentAccountDeviceId(e)}
                loading={loading}
                allowSearch={true}
                options={
                  freeAccountDevices?.map((item) => {
                    return {
                      text: `${item.name!} (${item.id!})`,
                      value: item.id!,
                    };
                  }) || []
                }
              />
              <Button
                material
                disabled={!currentAccountDeviceId ? true : undefined}
                icon="add"
                text="Add Device to Profile"
                primary
                onClick={onAddNewDeviceToGroup}
              />
              <Button
                data-position="right"
                material
                icon="refresh"
                text="Refresh"
                outline
                onClick={() => setReload(true)}
              />
            </group>
            <DataTable
              columns={columns}
              bordered={true}
              customPagination={true}
              reload={reload}
              dataPagination={true}
              getPageAsync={getPage}
              loading={loading}
              rowKey={(record) => `${record.id}`}
              showTotal={true}
              size="small"
              totalRecords={totalRecords}
            />
          </view>
        </view>
      </>
    );
  }

  const onStepSave = (acc: Account, step: string) => {
    switch (step) {
      default:
        break;
    }

    // if (onSave) {
    //   onSave(account);
    // }
  };

  return (
    <Popup
      title={`Account: ${account.accountName} - Kibosh Device: ${accountKiboshDevice.kiboshDeviceName} - ${(!currentAccountDeviceGroup.id ? "New Profile" : `Profile Name: ${currentAccountDeviceGroup.name}`)}`}
      onCancel={onCancel}
      onClose={onCancel}
      noCommandbar={true}
      fixSize="large"
    >
      { loading &&
        <Loader />
      }
      <TabStrip
        skipSecondaryTabs={false}
        selectedIndex={currentTab}
        onChange={setCurrentTab}
        id="device-tab">
        <Tab title='Details'>
          {renderDetailsTab()}
        </Tab>
        <Tab title='Blocked Sites' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference || !currentAccountDeviceGroup.id}>
          {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference && !!currentAccountDeviceGroup.id &&
            <AccountBlockedSites refresh={!!account.accountId} account={account} kiboshDeviceReference={accountKiboshDevice.kiboshDeviceReference} deviceReference={`${currentAccountDeviceGroup.id}`} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'blockedSites')} />
          }
        </Tab>
        <Tab title='Internet Schedules' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference || !currentAccountDeviceGroup.id}>
          {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference && !!currentAccountDeviceGroup.id &&
            <AccountInternetSchedules refresh={!!account.accountId} account={account} kiboshDeviceReference={accountKiboshDevice.kiboshDeviceReference} deviceReference={`${currentAccountDeviceGroup.id}`} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'internetSchedules')} />
          }
        </Tab>
      </TabStrip>
      {/* {isBlockingConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText={blockingData?.blocked ? "Unblock" : "Block"}
          positiveCallback={handleBlocking}
          negativeCallback={() => {
            setBlockingConfirmationPopupOpen(false);
          }}
        />
      )} */}
      {isHasInternetConfirmationPopupOpen && (
        // <ConfirmationPopup
        //   showButton={false}
        //   positiveButtonText={internetData?.blocked ? "Disable Internet" : "Enabled Internet"}
        //   positiveCallback={handleInternetCheckboxChange}
        //   negativeCallback={() => {
        //     setHasInternetConfirmationPopupOpen(false);
        //   }}
        // />
        <AccountDeviceBlockingModal
          open={isHasInternetConfirmationPopupOpen}
          accountDeviceId={currentAccountDeviceGroup.id!}
          accountDeviceName={currentAccountDeviceGroup.name!}
          blockingData={internetData!}
          closeOnSave={true}
          onClose={() => setHasInternetConfirmationPopupOpen(false)}
          onSave={onAccountDeviceBlockingSave}
        />
      )}
    </Popup>
  );
}
