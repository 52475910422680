// 3rd-party modules
import { message, Tooltip } from 'antd';
import { useEffect, useState } from 'react';

// project modules
import Badge from '../../components/shared/badge';
import Button from '../../components/shared/button';
import ConfirmationPopup from '../../components/shared/popup/confirmationPopup';
import DataTable, { TableColumn } from '../../components/shared/list/dataTable';
import SystemUserModal from '../../components/system-users/SystemUserModel';
import { apiCall } from '../../helpers/apiHelper';

// apis
import * as AuthApi from '../../apis/authApi';

// models
import { DataSourceRequest } from '../../models/dataSourceRequest';
import { User } from '../../models/auth';

export default function SystemUserPage() {
  const columns: TableColumn<User>[] = [
    {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      filterable: true,
      width: 200
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      filterable: true,
      width: 200
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      filterable: true,
      width: 200
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      filterable: true,
      width: 300
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      key: 'roles',
      ellipsis: true
    },
    {
      title: 'Status',
      dataIndex: 'lockoutEnabled',
      key: 'lockoutEnabled',
      dataType: 'bool',
      width: 90,
      render: (value) => value ? <Badge text="Inactive" color="red" /> : <Badge text="Active" />
    },
    {
      title: '',
      key: 'action',
      width: 60,
      render: (_, record) => (
        <group data-wrap='no' data-gap="5">
          <Tooltip title="Edit"><div className='button micro' onClick={() => onEditClick(record)}><icon>edit_square</icon></div></Tooltip>
          <Tooltip title="Delete"><div className='button micro' onClick={() => onDeleteClick(record)}><icon>delete</icon></div></Tooltip>
        </group>
      ),
    }
  ];
  const [currentUser, setCurrentUser] = useState<User>(new User());
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showUserModal, setShowUserModal] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  let abortController = new AbortController();

  useEffect(() => {
    let abortController = new AbortController();

    getUsersAsync();

    return () => { abortController.abort(); }
  }, []);

  const getUsersAsync = async () => {
    setUsers(await getUsers({}, abortController.signal));
  }

  const getUsers = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
    setLoading(true);
    const response = await apiCall(AuthApi.getUsers(request, abortSignal));
    setLoading(false);

    return response.success ? User.toArrayOfClass(response.data?.value || []) : [];
  };

  const onNewClick = () => {
    setCurrentUser(new User());
    setShowUserModal(true);
  };

  const onEditClick = (user: User) => {
    setCurrentUser({...user});
    setShowUserModal(true);
  };

  const onDeleteClick = (user: User) => {
    setCurrentUser(user);
    setIsDeleteConfirmationPopupOpen(true);
  };

  const handleDeleteRequest = async () => {
    setLoading(true);
    const response = await apiCall(AuthApi.deleteUser(currentUser));
    setLoading(false);

    if (response.success) {
      message.success(`Account deleted successfully.`);
      getUsersAsync();
    } else
      message.error(response.message);

    setIsDeleteConfirmationPopupOpen(false);
  };

  const onUserSave = (e: User) => {
    setTimeout(async () => {
      setUsers(await getUsers(undefined, abortController.signal));
    }, 50);
  }

  return (
    <>
      <view className="table_cont" data-background="none">
        <group data-space="15" data-gap="20" data-border="" data-background="highlight">
          <Button
            material
            icon="add"
            text="New"
            primary
            onClick={onNewClick}
          />
          <Button
            data-position="right"
            material
            icon="refresh"
            text="Refresh"
            outline
            onClick={getUsersAsync}
          />
        </group>
        <DataTable columns={columns}
          bordered={true}
          customPagination={true}
          dataSource={users}
          loading={loading}
          rowKey={record => `${record.userId}`}
          showTotal={true}
          size="small"
          onRow={(record) => ({
            onDoubleClick: () => onEditClick(record),
            style: { cursor: "pointer" },
          })}
        />
      </view>
      {showUserModal &&
        <SystemUserModal open={showUserModal}
          user={currentUser}
          onClose={() => setShowUserModal(false)}
          onSave={(e) => onUserSave(e)} />
      }
      {isDeleteConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Delete"
          positiveCallback={handleDeleteRequest}
          negativeCallback={() => {
            setIsDeleteConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
