// 3rd-party modules
import * as yup from 'yup';
import { message } from 'antd';
import { useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';

// project modules
import Button from '../../components/shared/button';
import Checkbox from '../../components/shared/inputs/checkbox';
import Input, { Password } from '../../components/shared/inputs/input';
import { RememberUser, loginUser, setRememberUser } from '../../stores/userStore';
import { RootState } from '../../stores';
import { useAppDispatch } from '../../hooks/storeHooks';

// models
import { ApiResponse } from '../../models/response';
import { User } from '../../models/auth';

// css and images
import Loader from '../../components/shared/loader';

type FormValues = {
  username: string;
  password: string;
};

const schema = yup.object().shape({
  username: yup.string().trim().label("Username").required(),
  password: yup.string().trim().label("Password").required()
});

export default function LoginPage() {
  const { register, formState: { errors }, handleSubmit, setValue, setFocus } = useForm<FormValues>({
    resolver: yupResolver(schema)
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [keepSignedIn, setKeepSignedIn] = useState(false);
  const [searchParams] = useSearchParams();
  const rememberUser = useSelector((state: RootState) => state.user.rememberUser) as RememberUser;

  const getUserHomePage = (user: User): string => {
    return '/';
  };

  useEffect(() => {
    // if remember state is true, set restored username and keep-me-signed-in
    if (rememberUser?.remember) {
      setValue('username', rememberUser.username!);
      setFocus('password');
      setKeepSignedIn(true);
    } else
    setFocus('username');
  }, []);

  const onSubmit =  async (formData: FormValues) => {
    setLoading(true);
    const response: ApiResponse = await dispatch(loginUser(formData)).unwrap();
    setLoading(false);

    if (response.success) {
      const user = User.toClass(response.data?.value);

      dispatch(setRememberUser({ username: keepSignedIn ? user.username : undefined, remember: keepSignedIn }));
      navigate(searchParams.get('returnUrl') || getUserHomePage(user));
    } else {
      message.error(response.errors.length > 0 ? `${response.errors[0]} ${response.errors[1]}` : response.errors[0]);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <view data-vertical="">
        <view data-align="center" data-scroll="">
          <group data-space="40" data-width="auto" data-max-length="400">
            <group>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="58.68"
                height="77.626"
                viewBox="0 0 58.68 77.626"
              >
                <path
                  fill="#004b37"
                  d="M5362.32,259.3c0,5.777-.12,11.557.04,17.328a14.827,14.827,0,0,1-3.22,10.16c0.52-.353-1.02,1.5-7.96,8.4-5.3,4.364-10.83,8.358-16.38,12.284a3.538,3.538,0,0,1-3.53.007q-9.36-6.845-18.49-14.14a38.706,38.706,0,0,1-4.93-5.266,17.563,17.563,0,0,1-4.19-12.361c0.24-12.019.18-24.047,0.14-36.071-0.01-2.782.78-4.684,3.16-5.268,19.3-4.719,33.45-6.151,52.19.095,2.16,0.72,3.06,2.347,3.05,4.9-0.03,6.645-.01,13.29-0.01,19.935h0.13Z"
                  transform="translate(-5303.66 -230.312)"
                />
                <path
                  fill="#4c9484"
                  d="M5351.18,295.184c-5.3,4.364-10.83,8.358-16.38,12.284a3.538,3.538,0,0,1-3.53.007q-9.36-6.845-18.49-14.14a38.706,38.706,0,0,1-4.93-5.266,17.563,17.563,0,0,1-4.19-12.361c0.24-12.019.18-24.047,0.14-36.071a6.309,6.309,0,0,1,1.03-3.958l52.7,53.043C5356.29,290.044,5354.27,292.111,5351.18,295.184Z"
                  transform="translate(-5303.66 -230.312)"
                />
                <path
                  fill="#24715c"
                  d="M5304.71,235.848a3.7,3.7,0,0,1,2.25-1.479c19.3-4.719,33.45-6.151,52.19.095a4.211,4.211,0,0,1,2.1,1.436l-28.24,28.427Z"
                  transform="translate(-5303.66 -230.312)"
                />
              </svg>
            </group>
            <space></space>
            <logo>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="173.91"
                height="17.531"
                viewBox="0 0 173.91 17.531"
              >
                <path
                  d="M5319.46,360.439h-1.7l-8.19,7.824v-7.824h-1.27v16.8h1.27v-8.28l8.43,8.28h1.7l-8.73-8.64Zm23.04,16.8v-16.8h-1.27v16.8h1.27Z"
                  transform="translate(-5308.31 -359.938)"
                />
                <path
                  data-accent=""
                  d="M5376.82,365.076a4.357,4.357,0,0,0-1.5-3.492,6.02,6.02,0,0,0-4.05-1.284h-6.62v16.8h7.08a6.067,6.067,0,0,0,4.1-1.344,4.456,4.456,0,0,0,1.54-3.576,4.107,4.107,0,0,0-2.11-3.768,4.012,4.012,0,0,0,1.56-3.336h0Zm-4.68-.576a1.418,1.418,0,0,1,0,1.932,1.431,1.431,0,0,1-.99.348h-2.21v-2.64h2.21A1.4,1.4,0,0,1,5372.14,364.5Zm-3.2,5.928h2.67a1.464,1.464,0,0,1,1.05.384,1.489,1.489,0,0,1,0,2.052,1.438,1.438,0,0,1-1.05.4h-2.67v-2.832Zm46.32-1.728a8.366,8.366,0,0,0-2.56-6.216,8.962,8.962,0,0,0-12.51,0,8.905,8.905,0,0,0,0,12.456,8.815,8.815,0,0,0,15.07-6.24h0Zm-13.34,0a4.307,4.307,0,0,1,1.3-3.264,4.7,4.7,0,0,1,6.48,0,4.337,4.337,0,0,1,1.29,3.264,4.466,4.466,0,0,1-1.29,3.288,4.525,4.525,0,0,1-3.24,1.272,4.4,4.4,0,0,1-4.54-4.56h0Zm44.5,7.368a4.68,4.68,0,0,0,1.75-3.864,4.089,4.089,0,0,0-1.75-3.672,9.752,9.752,0,0,0-1.64-.912c-0.46-.192-1.12-0.416-1.96-0.672a9.9,9.9,0,0,1-2.3-.888,1.1,1.1,0,0,1-.58-0.936,0.946,0.946,0,0,1,.4-0.8,1.931,1.931,0,0,1,1.16-.3,2.7,2.7,0,0,1,2.61,1.872l3.6-2.112a6.425,6.425,0,0,0-2.49-2.844,7.063,7.063,0,0,0-3.75-1,6.22,6.22,0,0,0-4.17,1.44,4.739,4.739,0,0,0-1.66,3.768,4.421,4.421,0,0,0,1.27,3.312,8.6,8.6,0,0,0,3.72,1.944c1.15,0.336,1.86.552,2.11,0.648a1.466,1.466,0,0,1,1.13,1.2c0,0.752-.66,1.128-1.97,1.128a3.341,3.341,0,0,1-3.31-2.232l-3.67,2.136a5.95,5.95,0,0,0,2.48,3.072,8.128,8.128,0,0,0,4.38,1.1A7.258,7.258,0,0,0,5446.42,376.068Zm26.8-9.552V360.3h-4.29v16.8h4.29v-6.456h4.73V377.1h4.27V360.3h-4.27v6.216h-4.73Z"
                  transform="translate(-5308.31 -359.938)"
                />
              </svg>
            </logo>

            <space></space>

            <space></space>
            <group data-gap="10" data-direction="column">
              <div className="field" data-length="auto" data-multi-element="">
                <div
                  className={`form_fields ${
                    errors.username ? "validationError" : ""
                  }`}
                >
                  <Input
                    icon="person"
                    dataLength="320"
                    placeholder="User ID"
                    {...register("username")}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        document.getElementById("passwordInput")?.focus();
                      }
                    }}
                    tabIndex={1}
                  />
                  {errors.username && (
                    <errormessage>
                      <text className="errorMessage">
                        {errors.username?.message?.toString()}
                      </text>
                    </errormessage>
                  )}
                </div>
              </div>
              <div className="field" data-length="auto" data-multi-element="">
                <div
                  className={`form_fields ${
                    errors.password ? "validationError" : ""
                  }`}
                >
                  <Password
                    icon="lock"
                    placeholder="Password"
                    dataLength="320"
                    {...register("password")}
                    onKeyPress={(event) => {
                      if (event.key === "Enter") {
                        event.preventDefault();
                        handleSubmit(onSubmit)();
                      }
                    }}
                    tabIndex={2}
                    id="passwordInput"
                  />
                  {errors.password && (
                    <errormessage>
                      <text className="errorMessage">
                        {errors.password?.message?.toString()}
                      </text>
                    </errormessage>
                  )}
                </div>
              </div>
              <space></space>
              <Button
                large
                primary
                text="Sign In"
                onClick={handleSubmit(onSubmit)}
              />
              <separator horizontal=""></separator>
              <group>
                <space data-height="10"></space>
                <Checkbox
                  name="1"
                  label="Keep me signed in"
                  minimal
                  checked={keepSignedIn}
                  onChange={(e) => setKeepSignedIn(e.target.checked)}
                />
              </group>
            </group>
          </group>
        </view>
        <view
          data-background="main-darker"
          data-adaptive="desktop"
          data-dark=""
          data-align="center"
          data-space="40"
        >
          <group
            data-width="auto"
            data-max-length="600"
            data-align="center"
            data-direction="column"
          >
            <text
              data-weight="700"
              data-text-size="xxx-large"
              data-wrap="wrap"
              data-text-align="center"
            >
              Internet Security & Parental Controls
            </text>
            <space></space>
            <text
              data-text-size="large"
              data-wrap="wrap"
              data-text-align="center"
            >
              The Internet as it should be.
            </text>
            <text
              data-text-size="large"
              data-wrap="wrap"
              data-text-align="center"
            >
              For $2.50 per month.
            </text>
          </group>
        </view>
      </view>
    </>
  );
}
