// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, plainToInstance } from 'class-transformer';

export class AdminFilterSet {
  @Expose()
  public id?: string;
  @Expose()
  public name?: string;
  @Expose()
  public category?: string;
  @Expose()
  public displayOrder?: number;
  @Expose()
  public icon?: string;
  @Expose()
  public filterSetElements?: AdminFilterSetElement[];

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(AdminFilterSet, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(AdminFilterSet, camelcaseKeys(data, { deep: true }) as AdminFilterSet[], { excludeExtraneousValues: true });
  }
}

export class AdminFilterSetElement {
  @Expose()
  public id?: string;
  @Expose()
  public match?: string;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(AdminFilterSetElement, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(AdminFilterSetElement, camelcaseKeys(data, { deep: true }) as AdminFilterSetElement[], { excludeExtraneousValues: true });
  }
}
