import React from 'react';
import Badge from '../../components/shared/badge';
import { Link } from 'react-router-dom';

import {
  SvgHamburger,
  SvgHamburgerToLeft,
  SvgHamburgerToRight,
  SvgLoader,
  SvgMinus,
  SvgPlus,
} from '../../components/svg';
import Button from '../../components/shared/button';
/* import { SvgAtlantic_Commandes, SvgAtlantic_Darwin, SvgAtlantic_Formation, SvgAtlantic_Logiciels, SvgAtlantic_Panier, SvgAtlantic_Produit, SvgAtlantic_Reglement, SvgAtlantic_Services, SvgAtlantic_Solutions, } from '../../brands/atlantic/components/svg'; */
const ButtonsAndIcons: React.FC = () => {
  return (
    <view>
      <form>
        <view data-vertical="true" data-adaptive>
          <view>
            <toolbar>
              <div className="caption">
                <text data-weight="700" data-space="10" data-ellipsis="">
                  Buttons
                </text>
              </div>
            </toolbar>
            <view>
              <demo_wrap>
                <Button primary text="Primary Button" />

                <Button accent text="Accent Button" icon="arrow_forward" />

                <Button
                  material
                  outline
                  text="Outline Button"
                  textFirst={false}
                  icon="search"
                />

                <Button
                  highlight
                  text="Highlight Button"
                  textFirst={false}
                  icon="play_arrow"
                />

                <Button text="Simple Button" />

                <Link effect="material" to="" className="button">
                  <icon>favorite_border</icon> <text>Anchor Button</text>
                </Link>

                <Button icon="star" adaptive text="Responsive Button" />
              </demo_wrap>
              <demo_wrap>
                <Button primary mini rounded icon="home" />
                <separator vertical=""></separator>
                <Button accent mini rounded icon="navigate_next" />
                <separator vertical=""></separator>
                <Button outline mini rounded icon="search" />

                <Button outline mini large icon="arrow_outward" />
                <separator vertical=""></separator>
                <Button primary mini icon="search" />

                <Button accent mini icon="arrow_forward" />

                <Button highlight mini icon="info" />

                <Button mini icon="play_arrow" />
                <separator vertical=""></separator>
                <Button mini rounded toggleClassName="open" material>
                  <icon>
                    <SvgHamburger />
                  </icon>
                </Button>
                <Button mini rounded toggleClassName="open" material>
                  <icon>
                    <SvgPlus />
                  </icon>
                </Button>
                <Button mini rounded toggleClassName="open" material>
                  <icon>
                    <SvgMinus />
                  </icon>
                </Button>
                <Button
                  rounded
                  primary
                  large
                  toggleClassName="open"
                  material
                  text="Hamburger To Back"
                  textFirst={false}
                >
                  <icon>
                    <SvgHamburgerToLeft />
                  </icon>
                </Button>
                <Button
                  outline
                  rounded
                  large
                  toggleClassName="open"
                  material
                  text="Hamburger To Back"
                >
                  <icon>
                    <SvgHamburgerToRight />
                  </icon>
                </Button>

                <Button
                  rounded
                  primary
                  large
                  text="Please Wait"
                  textFirst={false}
                >
                  <icon>
                    <SvgLoader />
                  </icon>
                </Button>
              </demo_wrap>
              <demo_wrap>
                <Button
                  primary
                  large
                  icon="inventory_2"
                  text="Large Button"
                  textFirst={false}
                />

                <Button accent large icon="arrow_outward" text="Large Button" />
                <Button large text="Large Button" />
                <Button outline large text="Large Button" />
                <Button highlight large text="Large Button" />
                <Button primary large wide text="Large Button Wide" />
                <Button outline fit large text="Large Button Fit" />
                <Button highlight large fit text="Large Button Fit" />

              </demo_wrap>
            </view>
          </view>

          <view>
            <toolbar>
              <text data-weight="700" data-space="10" data-ellipsis="">
                Custom Icons Demo
              </text>
            </toolbar>
            <scroller>
              <grid>
                <wrap>
                  {/* <item effect="material"><icon data-icon-size="" data-color="secondary"><SvgAtlantic_Solutions/></icon></item>
                    <item effect="material"><icon data-icon-size="" data-color="secondary"><SvgAtlantic_Commandes/></icon></item>
                    <item effect="material"><icon data-icon-size="" data-color="secondary"><SvgAtlantic_Darwin/></icon></item>
                    <item effect="material"><icon data-icon-size="" data-color="secondary"><SvgAtlantic_Formation/></icon></item>
                    <item effect="material"><icon data-icon-size="" data-color="secondary"><SvgAtlantic_Logiciels/></icon></item>
                    <item effect="material"><icon data-icon-size="" data-color="secondary"><SvgAtlantic_Panier/></icon></item>
                    <item effect="material"><icon data-icon-size="" data-color="secondary"><SvgAtlantic_Produit/></icon></item>
                    <item effect="material"><icon data-icon-size="" data-color="secondary"><SvgAtlantic_Reglement/></icon></item>
                    <item effect="material"><icon data-icon-size="" data-color="secondary"><SvgAtlantic_Services/></icon></item> */}
                </wrap>
              </grid>
              <toolbar>
                <text data-weight="700" data-space="10" data-ellipsis="">
                  Material Icons Demo
                </text>
                <separator vertical=""></separator>
                <a
                  link=""
                  target="_blank"
                  rel="noreferrer"
                  href="https://fonts.google.com/icons?icon.style=Rounded"
                >
                  <text data-weight="700" data-space="10" data-ellipsis="">
                    More icons ...
                  </text>
                </a>
              </toolbar>













              <grid>
                <wrap>
                  <item effect="material">
                    <icon>thumb_up
                    </icon> <text>Settings</text>
                  </item>
                  <item className="selected" effect="material">
                    <icon>settings</icon> <text>Settings</text>
                  </item>
                  <item effect="material">
                    <icon>home</icon> <text>home</text>
                  </item>
                  <item effect="material">
                    <icon>favorite</icon> <text>Heart</text>
                  </item>
                  <item effect="material">
                    <icon>nest_eco_leaf </icon> <text>leaf</text>
                  </item>

                  <item effect="material">
                    <icon>laundry</icon>
                    <text>Icon</text>
                  </item>
                  <item effect="material">
                    <icon>chair</icon>
                    <text>Icon</text>
                  </item>
                  <item effect="material">
                    <icon>shield</icon>
                    <text>Icon</text>
                  </item>
                </wrap>
              </grid>
              <separator horizontal=""></separator>
              <grid>
                <wrap>
                  <item effect="material">
                    <icon fill="">settings</icon> <text>Settings</text>
                  </item>
                  <item effect="material">
                    <icon fill="">home</icon> <text>home</text>
                  </item>
                  <item effect="material">
                    <icon fill="">favorite</icon> <text>Heart</text>
                  </item>
                  <item effect="material">
                    <icon fill="">delete</icon> <text>Delete</text>
                  </item>
                  <item effect="material">
                    <icon fill="">nest_eco_leaf </icon> <text>leaf</text>
                  </item>
                </wrap>
              </grid>
              <separator horizontal=""></separator>
              <grid>
                <wrap>
                  <item>
                    <icon data-icon-weight="100">nest_eco_leaf </icon>
                    <text>leaf</text>
                  </item>
                  <item>
                    <icon data-icon-weight="200">nest_eco_leaf </icon>
                    <text>leaf</text>
                  </item>
                  <item>
                    <icon data-icon-weight="300">nest_eco_leaf </icon>
                    <text>leaf</text>
                  </item>
                  <item>
                    <icon data-icon-weight="">nest_eco_leaf </icon>
                    <text>leaf</text>
                  </item>
                  <item>
                    <icon data-icon-weight="500">nest_eco_leaf </icon>
                    <text>leaf</text>
                  </item>
                  <item>
                    <icon data-icon-weight="600">nest_eco_leaf </icon>
                    <text>leaf</text>
                  </item>
                  <item>
                    <icon data-icon-weight="700">nest_eco_leaf </icon>
                    <text>leaf</text>
                  </item>
                </wrap>
              </grid>
            </scroller>
          </view>

          <view data-size="small">
            <toolbar>
              <text data-weight="700" data-space="10" data-ellipsis="">
                Badges
              </text>
            </toolbar>
            <scroller>
              <demo_wrap>
                <group data-gap="10">
                  <Badge text="Simple Badge" />
                  <Badge text="Simple Badge" filled />

                  <text data-space="10">Inherits Color from Main Theme</text>
                  <separator horizontal=""></separator>

                  <Badge color="red" text="Red" />
                  <Badge color="pink" text="pink" />
                  <Badge color="purple" text="purple" />
                  <Badge color="deep-purple" text="deep-purple" />
                  <Badge color="indigo" text="indigo" />
                  <Badge color="blue" text="blue" />
                  <Badge color="light-blue" text="light-blue" />
                  <Badge color="cyan" text="cyan" />
                  <Badge color="teal" text="teal" />
                  <Badge color="green" text="green" />
                  <Badge color="light-green" text="light-green" />
                  <Badge color="lime" text="lime" />
                  <Badge color="yellow" text="yellow" />
                  <Badge color="amber" text="amber" />
                  <Badge color="orange" text="orange" />
                  <Badge color="deep-orange" text="deep-orange" />
                  <Badge color="brown" text="brown" />
                  <Badge color="grey" text="grey" />
                  <Badge color="blue-grey" text="blue-grey" />
                  <separator horizontal=""></separator>
                  <Badge color="cyan" text="cyan" filled />
                  <Badge color="teal" text="teal" filled />
                  <Badge color="green" text="green" filled />
                  <Badge color="light-green" text="light-green" filled />
                  <Badge color="lime" text="lime" filled />
                </group>
              </demo_wrap>

              <demo_wrap data-background="highlight">
                <group data-color="white" data-background="light-green" data-width="auto" data-align="center" data-wrap="no" data-border="outline" data-radius="5" data-contain="">
                  <div className="button" data-shrink="no" data-radius="0" effect="material"><text data-weight="400">light green</text></div>
                  <separator vertical=""></separator>
                  <div className="button micro" data-radius="0" effect="material"><icon>clear</icon></div>
                </group>
                <group data-color="" data-width="auto" data-align="center" data-wrap="no" data-border="outline" data-radius="5" data-contain="" data-background="context">
                  <div className="button" data-shrink="no" data-radius="0" effect="material"><text data-weight="500">System</text></div>
                  <separator vertical=""></separator>
                  <div className="button micro" data-radius="0" effect="material"><icon data-color="black">clear</icon></div>
                </group>
                <group data-color="deep-orange" data-width="auto" data-align="center" data-wrap="no" data-border="outline" data-radius="5" data-contain="" data-background="context">
                  <div className="button" data-shrink="no" data-radius="0" effect="material"><text data-weight="500">Custom Color</text></div>
                  <separator vertical=""></separator><div className="button micro" data-radius="0" effect="material"><icon>clear</icon></div></group>
                <group data-color="teal" data-width="auto" data-align="center" data-wrap="no" data-border="outline" data-radius="5" data-contain="" data-background="context">
                  <div className="button" data-shrink="no" data-radius="0" effect="material"><text data-weight="500">Teal</text></div>
                  <separator vertical=""></separator><div className="button micro" data-radius="0" effect="material"><icon data-color="black">clear</icon></div></group>
              </demo_wrap>
            </scroller>
          </view>
        </view>
        <toolbar></toolbar>
      </form>
    </view>
  );
};
export default ButtonsAndIcons;
