import { InputNumber, Tooltip, TreeSelect} from 'antd';
import classNames from 'classnames';
import React, { InputHTMLAttributes, PropsWithChildren, ReactElement, useState } from 'react';
import { Control, Controller, FieldValues } from 'react-hook-form';
import { CheckboxInner } from './checkbox';
import { DatePickerInner } from './datePicker';
import { TimePickerInner } from './timePicker';
import { FormField, FormFieldProps, LabelPosition } from "./formField";
import { RadioInner } from './radio';
import { SelectInner } from './select';
import { InputMask } from '@react-input/mask';

export const InputType = {
  Text: 'text',
  Password: 'password',
  DatePicker: 'datepicker',
  TimePicker: 'timepicker',
  Select: 'select',
  TreeSelect: 'TreeSelect',
  Checkbox: 'Checkbox',
  Radio: 'Radio',
  TextArea: 'textarea',
  Number: 'number',
  Mask: 'mask',
};
export interface UncontrolledInputProps extends FormFieldProps, InputHTMLAttributes<HTMLInputElement> {
  type?: string;
  label?: string;
  translateLabel?: boolean;
  icon?: any;
  inputBox?: boolean;
  minimal?: boolean;
  button?: ReactElement;
  rules?: any;
  name?: string;
  control?: never;
  invalid?: any;
  isTouched?: any;
  isDirty?: any;
  error?: any;
  size?: any;
  fixLength?: string;
  labelPosition?: LabelPosition;
  mask?: string;
  maskReplacement?: string | Record<string, RegExp>;
  needConfirm?: boolean;
  minuteStep?: number;
  format?: string;
}
export interface ControlledInputProps extends Omit<UncontrolledInputProps, 'control' | 'name'> {
  name: string;
  control: Control<FieldValues, any>;
}
export type InputProps = ControlledInputProps | UncontrolledInputProps;

const Input: React.FC<InputProps> = React.forwardRef<ReactElement, PropsWithChildren<InputProps>>((props, ref) => {
  const { name, control, ...restProps } = props as any;

  return control ? (
    <Controller
      control={control}
      name={name!}
      render={({ field: { onChange, value, ref }, fieldState: { error, isDirty, invalid, isTouched } }) => (
        <InputInner
          ref={ref}
          onChange={onChange}
          isDirty={isDirty}
          invalid={invalid}
          isTouched={isTouched}
          value={value}
          error={error}
          {...restProps}
        />
      )}
    />
  ) : (
    <InputInner ref={ref} name={name} {...restProps} />
  );
});

const InputInner: React.FC<InputProps> = React.forwardRef<ReactElement, PropsWithChildren<InputProps>>(
  (
    {
      type = InputType.Text,
      className,
      label = '',
      translateLabel = true,
      icon,
      inputBox = false,
      placeholder = ' ',
      button,
      invalid,
      isTouched,
      isDirty,
      error,
      fixLength,
      dataLength,
      labelPosition,
      borderLess,
      mask,
      maskReplacement,
      ...rest
    },
    ref
  ) => {
    let input;
    const [isTooltipOpen, setIsTooltipOpen] = useState(false);
    const inputProps = {
      onPointerEnter: () => setIsTooltipOpen(true),
      onPointerLeave: () => {
        setIsTooltipOpen(false);
      },
      ...rest,
    };
    const size = 'compact';
    const antClassName = 'form_field_ant';
    let isAnt = false;
    switch (type) {
      case InputType.DatePicker:
        isAnt = true;
        input = (
          <DatePickerInner
            ref={ref}
            placeholder={placeholder}
            size={size}
            bordered={false}
            className={antClassName}
            {...(inputProps as any)}
          />
        );
        break;
      case InputType.TimePicker:
        isAnt = true;
        input = (
          <TimePickerInner
            ref={ref}
            placeholder={placeholder}
            size={size}
            bordered={false}
            className={antClassName}
            {...(inputProps as any)}
          />
        );
        break;
      case InputType.Select:
            isAnt = true;
        input = (
          <SelectInner
            {...(inputBox ? { showAction: ['focus'] } : null)}
            ref={ref}
            bordered={false}
            variant={'borderless'}
            placeholder={placeholder}
            size={size}
            className={antClassName}
            {...(inputProps as any)}
          />
        );
        break;
      case InputType.TreeSelect:
        isAnt = true;
        input = (
          <TreeSelect
            {...(inputBox ? { showAction: ['focus'] } : null)}
            ref={ref}
            placeholder={placeholder}
            size={size}
            bordered={false}
            className={antClassName}
            {...(inputProps as any)}
          />
        );
        break;
      case InputType.Checkbox:
        input = (
          <CheckboxInner
            ref={ref}
            placeholder={placeholder}
            size={size}
            error={error}
            invalid={invalid}
            isTouched={isTouched}
            icon={icon}
            label={label}
            className={className}
            {...(inputProps as any)}
          />
        );
        break;
      case InputType.Radio:
        input = (
          <RadioInner
            ref={ref}
            placeholder={placeholder}
            size={size}
            error={error}
            invalid={invalid}
            isTouched={isTouched}
            icon={icon}
            className={className}
            label={label}
            {...(inputProps as any)}
          />
        );
        break;
      case InputType.Number:
        isAnt = true;
        input = (
          <InputNumber
            ref={ref}
            bordered={false}
            placeholder={placeholder}
            {...(inputProps as any)}
          />
        );        break;
      case InputType.TextArea:
        input = <textarea ref={ref} placeholder={placeholder} {...(inputProps as any)} />;
        break;
      case InputType.Mask:
        input = <InputMask ref={ref as any} mask={mask} replacement={maskReplacement} {...inputProps} />
        break;
      default:
        input = <input type={type} ref={ref as any} placeholder={placeholder} {...inputProps} />;
    }

        let content = ![InputType.Checkbox, InputType.Radio].includes(type) ? (
        <FormField
            className={classNames(className, {
              error: invalid,
              touched: isTouched,
              dirty: isDirty,
            })}
            label={label}
            translateLabel={translateLabel}
            button={button}
            icon={icon}
            dataLength={dataLength}
            isAnt={isAnt}
            labelPosition={labelPosition}
            error={error?.message}
            borderLess={borderLess}
          >
            {input}
        </FormField>
    ) : (
      input
    );

    return content;
  }
);

export const Password: React.FC<InputProps> = React.forwardRef<ReactElement, PropsWithChildren<InputProps>>(
  (props, ref) => {
    const [show, setShow] = useState(false);
    const [blurTimeout, setBlurTimeout] = useState(setTimeout(() => {}));
    const onBlur = (e: any) => {
      let nativeEvent = e.nativeEvent;
      setBlurTimeout(
        setTimeout(() => {
          if (props.onBlur) {
            return props.onBlur(nativeEvent);
          }
        }, 300)
      );
    };
    return (
      <Input
        ref={ref}
        type={show ? 'text' : 'password'}
        button={
          <Tooltip title={show ? ("HidePassword") : ("ShowPassword")}>
            <div
              className="button micro"
              onClick={() => {
                setShow((sh) => !sh);
                clearTimeout(blurTimeout);
              }}
            >
              <icon>{show ? "lock_open" : "lock"}</icon>
            </div>
          </Tooltip>
        }
        {...props}
        onBlur={onBlur}
      />
    );
  }
);

export default Input;
