//3rd-party modules
import { useEffect, useState } from "react";

// project modules
import { apiCall } from "../../../helpers/apiHelper";
import DashboardWidget from "../../global/dashboardWidget";
import Loader from "../../shared/loader";

// apis
import * as SupportTicketApi from '../../../apis/supportTicketApi';

const getSupportTicketSummary = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(SupportTicketApi.getSummaryReport(abortSignal));

  return response.success ? response.data?.value : null;
};

export default function SupportTicketStats() {
  const [loading, setLoading] = useState(false);
  const [supportTicketSummary, setSupportTicketSummary] = useState<any | null>(null);
  const abortController = new AbortController();

  useEffect(() => {
    const getSupportTicketSummaryAsync = async () => {
      setLoading(true);
      setSupportTicketSummary(await getSupportTicketSummary(abortController.signal));
      setLoading(false);
    }

    getSupportTicketSummaryAsync();
  }, []);

  return (
    <>
      <group data-space='20' data-border='' data-sticky="top" data-backdrop=''><text data-ellipsis="" data-index="1" data-weight="700">Help Desk Stats.</text></group>
      {loading && <Loader />}
      <group data-type="grid" data-gap="10" data-space="20" data-grid-template="300">
        <DashboardWidget title="Active Tickets" value={supportTicketSummary?.active_tickets} description="Tickets in any status other than closed" height="400">
        </DashboardWidget>
        <DashboardWidget title="New Tickets" value={supportTicketSummary?.new_tickets || "0"} description="Tickets opened in last 24 hours" height="400">
        </DashboardWidget>
      </group>
    </>
  )
}
