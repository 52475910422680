// 3rd-party modules
import { useEffect, useState } from "react";

// project modules
import Button from "../../../../shared/button";
// import DataTable from "../../../../shared/list/dataTable";
// import Loader from "../../../../shared/loader";
import Select from "../../../../shared/inputs/select";
import { apiCall } from "../../../../../helpers/apiHelper";

// apis
import * as AccountKiboshDeviceApi from '../../../../../apis/accountKiboshDeviceApi';
import * as DashApi from '../../../../../apis/dashApi';

// models
import { Account } from "../../../../../models/account";
import { AccountKiboshDevice } from "../../../../../models/accountKiboshDevice";
import { DataSourceRequest } from "../../../../../models/dataSourceRequest";
import { AccountDevice } from "../../../../../models/accountDevice";
import { accountDeviceExcludViewModel } from "../../../../../models/types/accountDevice";
import Checkbox from "../../../../shared/inputs/checkbox";
import AccountDeviceFilterationModal from "./AccountDeviceFilterationModal";
import { CONSTANTS } from "../../../../../helpers/defines";
import moment from "moment-timezone";

type Props = {
  account?: Account;
  deviceReference?: string;
  refresh?: boolean;
  kiboshDeviceReference?: string;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
};

export default function AccountDeviceFilterations({ account, deviceReference, refresh, kiboshDeviceReference, onCancel, onSave }: Props) {
  const [accountKiboshDevices, setAccountKiboshDevices] = useState<AccountKiboshDevice[]>([]);
  const [accountDevices, setAccountDevices] = useState<AccountDevice[]>([]);
  const [excludingData, setExcludingDate] = useState<accountDeviceExcludViewModel | null>(null);
  const [currentAccountKiboshDeviceReference, setCurrentAccountKiboshDeviceReference] = useState<string>("");
  const [currentAccountDevice, setCurrentAccountDevice] = useState<AccountDevice | null>(null);
  const [showBlockingModal, setShowBlockingModal] = useState(false);
  const [isExcluded, setIsExcluded] = useState<boolean>(false);
  // const [loading, setLoading] = useState(false);
  const [loadingAccountKiboshDevices, setLoadingAccountKiboshDevices] = useState(false);
  const [loadingAccountDevices, setLoadingAccountDevices] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  let abortController = new AbortController();

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  useEffect(() => {
    if (refresh) {
      if (!kiboshDeviceReference) {
        getAccountKiboshDevicesAsync();
      } else if (!deviceReference) {
        getAccountDevicesAsync();
      }
    }
  }, [refresh]);

  useEffect(() => {
    setIsExcluded(!currentAccountDevice?.isExcluded)
  }, [currentAccountDevice]);

  const getAccountDevicesAsync = async (routerRef?: string) => {
    const data = await getAccountDevices(routerRef, abortController.signal);

    if (data.length && currentAccountDevice?.id) {
      const currentDevice = data.find(x => x.id === currentAccountDevice.id);
      if (currentDevice) {
        setCurrentAccountDevice(currentDevice)
      }
    }

    setAccountDevices(data);
  }

  const getAccountDevices = async (routerRef?: string, abortSignal?: AbortSignal) => {
    setLoadingAccountDevices(true);
    const response = await apiCall(DashApi.getClientDevices(kiboshDeviceReference || currentAccountKiboshDeviceReference || routerRef!, abortSignal));
    setLoadingAccountDevices(false);

    return response.success ? AccountDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const getAccountKiboshDevicesAsync = async () => {
    setAccountKiboshDevices(await getAccountKiboshDevices({}, abortController.signal));
  }

  const getAccountKiboshDevices = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
    setLoadingAccountKiboshDevices(true);
    const response = await apiCall(AccountKiboshDeviceApi.getAccountKiboshDevices(account?.accountId!, request, abortSignal));
    setLoadingAccountKiboshDevices(false);

    return response.success ? AccountKiboshDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const onAccountKiboshDeviceChange = (kiboshDeviceReference: any) => {
    setCurrentAccountKiboshDeviceReference(kiboshDeviceReference);
    getAccountDevicesAsync(kiboshDeviceReference);
  }

  const onAccountDeviceChange = (ref: any) => {
    const currentDevice = accountDevices.find(x => x.id === ref);
    setCurrentAccountDevice(currentDevice || null);
  }

  const onExcludingCheckboxChange = async (event: any) => {
    const data: accountDeviceExcludViewModel = {
      excluded: !event.target?.checked,
      deviceId: currentAccountDevice!.id!,
      kiboshDeviceReference: kiboshDeviceReference!,
      minutesBeforeExpiration: 0
    }
    setExcludingDate(data);
    setShowBlockingModal(true);
  }

  const onAccountDeviceFilterationSave = (accountDevice: AccountDevice) => {
    setIsExcluded(!accountDevice.isExcluded)
    getAccountDevicesAsync();
  }

  return (
    <>
      {/* {loading && <Loader />} */}
      <view>
        <group data-space="15" data-border="" data-gap="5">
          <group data-width="auto" data-gap="15">
            {(!kiboshDeviceReference || !deviceReference) && (
              <>
                {!kiboshDeviceReference && (
                  <>
                    <Select
                      autoComplete=""
                      label="Kibosh Device"
                      labelPosition="left"
                      dataLength="320"
                      onChange={onAccountKiboshDeviceChange}
                      loading={loadingAccountKiboshDevices}
                      allowSearch={true}
                      options={
                        accountKiboshDevices?.map((item) => {
                          return {
                            text: `${item.kiboshDeviceName!} (${item.kiboshDeviceReference!})`,
                            value: item.kiboshDeviceReference!,
                          };
                        }) || []
                      }
                      button={
                        <Button
                          material
                          icon="refresh"
                          onClick={getAccountKiboshDevicesAsync}
                        />
                      }
                    />
                  </>
                )}
                {!deviceReference && (
                  <>
                    <Select
                      autoComplete=""
                      disabled={
                        loadingAccountDevices ||
                        !(
                          kiboshDeviceReference ||
                          currentAccountKiboshDeviceReference
                        )
                      }
                      label="Device"
                      labelPosition="left"
                      dataLength="320"
                      onChange={onAccountDeviceChange}
                      loading={loadingAccountDevices}
                      allowSearch={true}
                      options={
                        accountDevices?.map((item) => {
                          return {
                            text: `${item.name!} (${item.id!})`,
                            value: item.id!,
                          };
                        }) || []
                      }
                      button={
                        <Button
                          material
                          icon="refresh"
                          onClick={getAccountDevicesAsync}
                        />
                      }
                    />
                  </>
                )}
              </>
            )}
            {
              !!currentAccountDevice &&
              <Checkbox
                label={isExcluded ? "Enable Filtration for device" : `Disabled Filtration for device until ${moment(currentAccountDevice.excludedUntil).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}`}
                minimal
                dataLength="auto"
                onChange={onExcludingCheckboxChange}
                checked={isExcluded}/>
            }
          </group>
        </group>
        {/* <view className="table_cont" data-background="none">
          <group
            data-space="15"
            data-gap="20"
            data-border=""
            data-background="highlight"
          >
            <Button
              data-position="right"
              material
              icon="refresh"
              text="Refresh"
              outline
              onClick={() => setReload(true)}
            />
          </group>
          <DataTable
            columns={columns}
            bordered={true}
            customPagination={true}
            reload={reload}
            dataPagination={true}
            getPageAsync={getPage}
            loading={loading}
            showTotal={true}
            size="small"
            totalRecords={totalRecords}
          />
        </view> */}
      </view>
      {!!showBlockingModal && (
        <AccountDeviceFilterationModal
          open={showBlockingModal}
          accountDevice={currentAccountDevice!}
          excludingData={excludingData!}
          closeOnSave={true}
          onClose={() => setShowBlockingModal(false)}
          onSave={(e) => onAccountDeviceFilterationSave(e)}
        />
      )}
    </>
  );
}
