// 3rd-party modules
import { message } from 'antd';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";

// project modules
import AttachmentItem from '../../attachments/AttachmentItem';
import Input, { InputType } from "../../shared/inputs/input";
import Loader from "../../shared/loader";
import Popup from '../../shared/popup/popup';
import Select from '../../shared/inputs/select';
import yup from "../../../plugins/yup";
import { apiCall } from "../../../helpers/apiHelper";

// apis
import * as CommonValueApi from '../../../apis/commonValueApi';
import * as DashApi from '../../../apis/dashApi';

// models
import { ApiResponse } from "../../../models/response";
import { AdminFilterSet } from '../../../models/adminFilterSet';
import { adminFilterSetViewModel } from '../../../models/types/adminFilterSet';
import { Attachment } from '../../../models/attachment';
import { CommonValue } from '../../../models/commonValue';

type Props = {
  closeOnSave?: boolean;
  open: boolean;
  adminFilterSet: AdminFilterSet;
  onClose?: () => void;
  onSave?: (accountBlockedSite: any) => void;
};

const getCategories = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(CommonValueApi.getApplicationCategories(abortSignal));

  return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
};

export default function AdminFilterSetModal({ closeOnSave = false, open, adminFilterSet, onClose, onSave }: Props) {
  const schema = yup.object().shape({
    name: yup.string().trim().label("Name").max(256).required(),
    category: yup.string().trim().label("Category").max(256).nullable(),
    displayOrder: yup.number().default(0),
    icon: yup.string()
  });
  const { control, handleSubmit, setValue } = useForm<adminFilterSetViewModel | any>({
    defaultValues: useMemo(() => adminFilterSet, [adminFilterSet]),
    resolver: yupResolver(schema),
  });
  const [attachment, setAttachment] = useState<Attachment>(new Attachment());
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [ticketCategories, setCategories] = useState<CommonValue[]>([]);
  const [loading, setLoading] = useState(false);
  const abortController = new AbortController();

  useEffect(() => {
    if (open) {
      const getCategoriesAsync = async () => {
        setLoadingCategories(true);
        setCategories(await getCategories(abortController.signal));
        setLoadingCategories(false);
      }

      getCategoriesAsync();
    }
  }, [open]);

  const onCancel = () => {
    abortController.abort();

    if(onClose) onClose();
  };

  const onAttachmentItemChange = (attachment: Attachment) => {
    //TODO: Make sure attachment.file.name has png extension, else clear icon
    setAttachment(attachment)
    setValue("icon", attachment.fileBase64);
  }

  const onSubmit: SubmitHandler<adminFilterSetViewModel> = async (formData: adminFilterSetViewModel) => {
    let response: ApiResponse;

    setLoading(true);

    if (!adminFilterSet?.id) {
      formData.id = formData.name.toLocaleLowerCase().split(" ").join("_")
      response = await apiCall(DashApi.insertAdminFilterSet(formData, abortController.signal));
    }
    else
      response = await apiCall(DashApi.updateAdminFilterSet(adminFilterSet?.id, { name: formData.name, icon: formData.icon, category: formData.category, displayOrder: formData.displayOrder }, abortController.signal));

    if (response.success) {
      message.success(`Filter set ${!adminFilterSet?.id ? 'added' : 'edited'} successfully.`);

      if (onSave) onSave(AdminFilterSet.toClass(response.data?.value));

      if (closeOnSave) {
        open = false;

        onCancel();
      }

    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  return (
    <Popup
      title={`${!adminFilterSet.id ? "New Filter Set" : `Update Filter Set`}`}
      onCancel={onCancel}
      onClose={onCancel}
      onSave={handleSubmit(onSubmit)}
    >
      {loading && <Loader />}
      <group data-space="15" data-gap="10" data-direction="column">
        <Input
          control={control}
          name="name"
          label="Name"
          dataLength="360"
          size="large"
        />
        <group data-gap="10">
        <Select
          label="Category"
          control={control}
          dataLength=""
          loading={loadingCategories}
          name="category"
          allowSearch={true}
          allowClear={true}
          options={
            ticketCategories?.map((item) => {
              return {
                text: item.valueCaption!,
                value: item.value!,
              };
            }) || []
          }
        />
        <Input
          control={control}
          type={InputType.Number}
          name="displayOrder"
          label="Order"
          dataLength="80"
        />
        </group>
        {adminFilterSet.icon && <img src={adminFilterSet.icon && adminFilterSet.icon.toString().startsWith('data:image') ? adminFilterSet.icon : `data:image/png;base64,${adminFilterSet.icon}`} alt="Filter Set Icon" width={32} height={32} />}
        <Input control={control} name="icon" type='hidden' />
        <AttachmentItem
          item={attachment}
          allowedExtensions=".png"
          allowedSize={52428800} // 50MB
          placeholder='Select Filter Set Icon'
          onChange={onAttachmentItemChange}
          onError={(err) => message.error(err.message)}
        />
      </group>
    </Popup>
  );
}
