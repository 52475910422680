// 3rd-party modules
import { useRef, useState } from 'react';

// project modules
import Button from '../shared/button';
import Input from '../shared/inputs/input';

// models
import { Attachment } from "../../models/attachment";

type Props = {
  item: Attachment;
  allowedExtensions?: string;
  allowedSize?: number; // bytes
  placeholder?: string;
  onChange: (doc: Attachment) => void;
  onError?: (err: Error) => void;
};

export default function AttachmentItem({ item, allowedExtensions, allowedSize, placeholder, onChange, onError }: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [attachment, setAttachment] = useState<Attachment>(item);

  const convertFileToBase64 = (file: any): Promise<string | ArrayBuffer | null> => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();

      fileReader.readAsDataURL(file)
      fileReader.onload = () => {
        resolve(fileReader.result);
      }
      fileReader.onerror = (error) => {
        reject(error);
      }
    })
  }

  const onUploadDocumentChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      if (allowedSize && (file.size > allowedSize)) {
        if (onError) onError(new Error("Max. allowed file size exceeded."))
        else throw new Error("Max. allowed file size exceeded.");
        return;
      }

      const base64: string | ArrayBuffer | null = await convertFileToBase64(file);

      item.originalFileName = file.name;
      item.fileBase64 = base64?.toString(); // .substring(base64?.toString().lastIndexOf(',') + 1); // remove header info.
      item.file = file;

      setAttachment(item);
      onChange(item);
    }
  };

  return (
    <>
      <input ref={fileInputRef} accept={allowedExtensions} onChange={(event: React.ChangeEvent<HTMLInputElement>) => onUploadDocumentChange(event)} type="file" hidden />
      <Input
        label=""
        type="text"
        dataLength="auto"
        placeholder={placeholder}
        value={attachment.originalFileName}
        button={<Button onClick={() => fileInputRef?.current?.click()} primary text="Browse"></Button>}
      />
    </>
  );
}
