// project modules
import { getBaseUrl, sendRequest } from '../helpers/apiHelper';

// models
import { AccountKiboshDevice } from '../models/accountKiboshDevice';
import { accountKiboshDeviceInsertViewModel, accountKiboshDeviceUpdateViewModel } from '../models/types/accountKiboshDevice';
import { DataSourceRequest } from '../models/dataSourceRequest';

export const getAccountKiboshDevice = async (deviceId: number, request?: DataSourceRequest, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/accountKiboshDevice/${deviceId}`, 'post', request, null, abortSignal);
};

export const getAccountKiboshDevices = async (accountId: number, request?: DataSourceRequest, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/accountKiboshDevice/account/${accountId}/List`, 'post', request, null, abortSignal);
};

export const insertAccountKiboshDevice = async (accountKiboshDevice: accountKiboshDeviceInsertViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/accountKiboshDevice`, 'post', accountKiboshDevice, null, abortSignal);
};

export const updateAccountKiboshDevice = async (accountKiboshDevice: accountKiboshDeviceUpdateViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/accountKiboshDevice`, 'put', accountKiboshDevice, null, abortSignal);
};

export const deleteAccountKiboshDevice = async (accountKiboshDevice: AccountKiboshDevice, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/accountKiboshDevice`, 'delete', accountKiboshDevice, null, abortSignal);
};

