// 3rd-party modules
import moment from 'moment-timezone';
import { message } from 'antd';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";

// project modules
import Checkbox, { CheckboxType } from '../../shared/inputs/checkbox';
import Input, { InputType } from '../../shared/inputs/input';
import Loader from "../../shared/loader";
import OptionBar from '../../shared/optionBar';
import Popup from '../../shared/popup/popup';
// import Select from '../../shared/inputs/select';
// import { TimePicker } from '../../shared/inputs/timePicker';
import yup from "../../../plugins/yup";
import { apiCall } from "../../../helpers/apiHelper";
import { convertToSnakecase } from '../../../helpers/objectHelper';

// apis
import * as DashApi from '../../../apis/dashApi';

// models
import { Account } from '../../../models/account';
import { AccountDeviceSchedule } from '../../../models/accountDevice';
import { ApiResponse } from "../../../models/response";
import { accountDeviceScheduleInsertViewModel } from '../../../models/types/accountDeviceSchedule';

type Props = {
  closeOnSave?: boolean;
  open: boolean;
  account: Account;
  accountDeviceSchedule: AccountDeviceSchedule;
  deviceReference: string;
  kiboshDeviceReference: string;
  onClose?: () => void;
  onSave?: (accountDeviceSchedule: any) => void;
};

export default function AccountInternetScheduleModal({ closeOnSave = false, open, account, accountDeviceSchedule, deviceReference, kiboshDeviceReference, onClose, onSave }: Props) {
  const schema = yup.object().shape({
    fromTime: yup.string().label("From Time").max(20).required(),
    toTime: yup.string().label("To Time").max(20).required()
  });
  const { control, handleSubmit } = useForm<AccountDeviceSchedule | any>({
    defaultValues: {...accountDeviceSchedule},
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const [repeatingDays, setRepeatingDays] = useState<string[]>([]);
  const abortController = new AbortController();

  useEffect(() => {
    if (open) {
    }
  }, [open]);


  const onCancel = () => {
    abortController.abort();

    if(onClose) onClose();
  };

  const onDaySelectionChange = (event: any, value: any) => {
    if (repeatingDays.includes(value)) {
      setRepeatingDays(repeatingDays.filter(x => x !== value));
    } else {
      setRepeatingDays([...repeatingDays, value]);
    }
  }

  const onSubmit: SubmitHandler<AccountDeviceSchedule> = async (formData: AccountDeviceSchedule) => {
    let response: ApiResponse;

    if (!repeatingDays.length) {
      message.error("Please select at least one day.")
      return;
    }

    setLoading(true);

    const tempData: accountDeviceScheduleInsertViewModel = {
      id: formData.id,
      fromAmPm: "",
      fromDay: undefined,
      fromHour: "",
      fromMinute: "",
      enabled: formData.enabled ? "1" : "0",
      toAmPm: "",
      toDay: undefined,
      activeDays: repeatingDays.join(","),
      toHour: "",
      toMinute: "",
      repeats: "daily",
      kiboshDeviceReference: kiboshDeviceReference,
      deviceId: deviceReference,
    };

    const fromTime = moment(formData.fromTime);
    const toTime = moment(formData.toTime);

    tempData.fromHour = fromTime.format("hh");
    tempData.fromMinute = fromTime.format("mm");
    tempData.fromAmPm = fromTime.format("a");
    tempData.toHour = toTime.format("hh");
    tempData.toMinute = toTime.format("mm");
    tempData.toAmPm = toTime.format("a");


    const data = convertToSnakecase(tempData, [], ["kiboshDeviceReference", "deviceId"]);

    if (!accountDeviceSchedule?.id)
      response = await apiCall(DashApi.insertDeviceSchedule(deviceReference, data, abortController.signal));
    else
      response = await apiCall(DashApi.updateDeviceSchedule(accountDeviceSchedule?.id, data, abortController.signal));

    if (response.success) {
      message.success(`Internet Schedule ${!accountDeviceSchedule?.id ? 'added' : 'edited'} successfully.`);

      if (onSave) onSave(AccountDeviceSchedule.toClass(response.data?.value));

      if (closeOnSave) {
        open = false;

        onCancel();
      }

    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  return (
    <Popup
      title={`Account: ${account.accountName} - ${(!accountDeviceSchedule.id ? "New Internet Schedule" : "Edit Internet Schedule")}`}
      onCancel={onCancel}
      onClose={onCancel}
      onSave={handleSubmit(onSubmit)}
    >
      { loading &&
        <Loader />
      }
      <view data-scroll=''>
        <group data-space="15" data-gap="10" data-direction="column">

          <group data-gap="10" >
            <OptionBar data-length="410">
              <Checkbox checkboxType={CheckboxType.Button} label="Mon" checked={repeatingDays.includes('mon')} onChange={(e) => onDaySelectionChange(e, 'mon')} />
              <Checkbox checkboxType={CheckboxType.Button} label="Tue" checked={repeatingDays.includes('tue')} onChange={(e) => onDaySelectionChange(e, 'tue')} />
              <Checkbox checkboxType={CheckboxType.Button} label="Wed" checked={repeatingDays.includes('wed')} onChange={(e) => onDaySelectionChange(e, 'wed')} />
              <Checkbox checkboxType={CheckboxType.Button} label="Thu" checked={repeatingDays.includes('thu')} onChange={(e) => onDaySelectionChange(e, 'thu')} />
              <Checkbox checkboxType={CheckboxType.Button} label="Fri" checked={repeatingDays.includes('fri')} onChange={(e) => onDaySelectionChange(e, 'fri')} />
              <Checkbox checkboxType={CheckboxType.Button} label="Sat" checked={repeatingDays.includes('sat')} onChange={(e) => onDaySelectionChange(e, 'sat')} />
              <Checkbox checkboxType={CheckboxType.Button} label="Sun" checked={repeatingDays.includes('sun')} onChange={(e) => onDaySelectionChange(e, 'sun')} />
            </OptionBar>
          </group>
          <group data-gap="10">
            <Input
              name="fromTime"
              control={control}
              label="From Time"
              needConfirm={false}
              type={InputType.TimePicker}
              minuteStep={15}
              format="hh:mm a"
            />
            <Input
              name="toTime"
              control={control}
              label="To Time"
              needConfirm={false}
              type={InputType.TimePicker}
              minuteStep={15}
              format="hh:mm a"
            />
            {/* <TimePicker
              label="From Time"
              control={control}
              name="fromTime"
              minuteStep={15}
              format="hh:mm a"
              dataLength="auto"
              showSecond={false} />
            <TimePicker
              label="To Time"
              control={control}
              name="toTime"
              minuteStep={15}
              format="hh:mm a"
              dataLength="auto"
              showSecond={false} /> */}
          </group>
        </group>
      </view>
    </Popup>
  );
}
