export const getDeviceSignalRateClassIcon = (ping: number = -1) : string => {
  let deviceSignalRateClass = getDeviceSignalRateClass(ping || -1);
  return deviceSignalRateClass < 0 ? "signal_cellular_nodata" : `signal_cellular_${deviceSignalRateClass}_bar`
};

export const getDeviceSignalRateClass = (ping: number = -1) : number => {
  if (ping < 0) return -1; // disconnected
  if (ping < 40) return 4;
  if (ping < 70) return 3;
  if (ping < 100) return 2;
  if (ping < 150) return 1;
  return 0;
};
