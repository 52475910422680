// 3rd-party modules
import { Tooltip, message } from 'antd';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";

// project modules
import AccountDevices from '../devices/AccountDevices';
import AccountBlockedSites from '../blocked-sites/AccountBlockedSites';
import AccountDeviceActivityLogs from '../device-activity-logs/AccountDeviceActivityLogs';
import AccountDeviceBandwidthLogs from '../device-bandwidth-logs/AccountDeviceBandwidthLogs';
import AccountInternetSchedules from '../internet-schedules/AccountInternetSchedules';
import CustomChart from '../../shared/chart/chart';
import DashboardWidget from "../../global/dashboardWidget";
import Button from "../../shared/button";
import Input from "../../shared/inputs/input";
import Loader from "../../shared/loader";
import Popup from '../../shared/popup/popup';
import TabStrip from '../../shared/tabstrip';
import Tab from '../../shared/tabstrip/tab';
import Select from '../../shared/inputs/select';
import yup from "../../../plugins/yup";
import { apiCall } from "../../../helpers/apiHelper";
import { trimStringProps } from '../../../helpers/objectHelper';

// apis
import * as AccountKiboshDeviceApi from '../../../apis/accountKiboshDeviceApi';
import * as DashApi from '../../../apis/dashApi';
import * as CommonValueApi from '../../../apis/commonValueApi';

// models
import KiboshDeviceSettings from './settings/KiboshDeviceSettings';
import { Account } from '../../../models/account';
import { AccountKiboshDevice } from '../../../models/accountKiboshDevice';
import { ApiResponse } from "../../../models/response";
import { accountKiboshDeviceInsertViewModel } from '../../../models/types/accountKiboshDevice';
import { AccountDeviceBandwidthLog } from '../../../models/accountDevice';
import { CommonValue } from '../../../models/commonValue';
import AccountDeviceGroups from '../device-groups/AccountDeviceGroups';

type Props = {
  closeOnSave?: boolean;
  open: boolean;
  account: Account;
  accountKiboshDevice: AccountKiboshDevice;
  preSelectedTab?: string;
  onClose?: () => void;
  onSave?: (accountKiboshDevice: any) => void;
};

export default function AccountKiboshDeviceModal({ closeOnSave = false, open, account, accountKiboshDevice, preSelectedTab, onClose, onSave }: Props) {
  const chartOptions: any = {
    chart: {
      height: 200,
      type: 'pie',
    },
    plotOptions: {
      pie: {
        customScale: 0.8,
        size: 200
      }
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5
      },
    },
    tooltip: {
      y: {
        formatter: function (val: any) {
          return `${Number(val).toFixed(2)} MB`;
        }
      }
    },
  };
  const schema = yup.object().shape({
    kiboshDeviceTypeId: yup.string().label("Device Type").required(),
    kiboshDeviceName: yup.string().label("Kibosh Device Name").max(50).required(),
    kiboshDeviceReference: yup.string().label("Kibosh Device Reference").max(50).required()
  });
  const { control, handleSubmit, reset } = useForm<accountKiboshDeviceInsertViewModel | any>({
    defaultValues: useMemo(() => accountKiboshDevice, [accountKiboshDevice]),
    resolver: yupResolver(schema),
  });
  const [bandwidthLogs, setBandwidthLogs] = useState<AccountDeviceBandwidthLog[]>([]);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [deviceTypes, setDeviceTypes] = useState<CommonValue[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingBandwidth, setLoadingBandwidth] = useState(false);
  const [loadingPreferredTimezone, setLoadingPreferredTimezone] = useState(false);
  const [loadingPreferredTimezoneSave, setLoadingPreferredTimezoneSave] = useState(false);
  const [loadingDevicesTypeList, setLoadingDevicesTypeList] = useState(false);
  const [loadingTimezoneList, setLoadingTimezoneList] = useState(false);
  const [openDownloadChartInModal, setOpenDownloadChartInModal] = useState(false);
  const [openTotalChartInModal, setOpenTotalChartInModal] = useState(false);
  const [openUploadChartInModal, setOpenUploadChartInModal] = useState(false);
  const [preferredTimezone, setPreferredTimezone] = useState<string>("");
  const [refreshDevices, setRefreshDevices] = useState(false);
  const [refreshGroups, setRefreshGroups] = useState(false);
  const [timezoneList, setTimezoneList] = useState<string[]>([]);
  const abortController = new AbortController();

  useEffect(() => {
    if (open) {
      getDeviceTypesAsync();

      if (preSelectedTab) {
        switch (preSelectedTab) {
          case 'details':
            setCurrentTab(0)
            break;
          case 'devices':
            setCurrentTab(1)
            break;
          case 'device-groups':
            setCurrentTab(2)
            break;
          case 'blocked-sites':
            setCurrentTab(3)
            break;
          case 'activity-logs':
            setCurrentTab(4)
            break;
          case 'internet-schedules':
            setCurrentTab(5)
            break;
          case 'bandwidth-logs':
            setCurrentTab(6)
            break;
          case 'settings':
            setCurrentTab(7)
            break;
        }
      }
    }
  }, [open]);

  useEffect(() => {
    reset(); // this is to make sure form clears on new while undefined props ignored by react-hook-form
    reset(accountKiboshDevice);

    if (accountKiboshDevice.kiboshDeviceReference) {
      if (!timezoneList.length) {
        getTimezonesAsync();
      }

      getPreferredTimezoneAsync();
      getDeviceLatestBandwidthsAsync();
    }
  }, [accountKiboshDevice]);

  useEffect(() => {
    if (refreshGroups) {
      setRefreshGroups(false);
    }
  }, [refreshGroups]);

  useEffect(() => {
    if (refreshDevices) {
      setRefreshDevices(false);
    }
  }, [refreshDevices]);

  const getDeviceLatestBandwidthsAsync = async () => {
    setBandwidthLogs(await getDeviceLatestBandwidths(abortController.signal));
  }

  const getDeviceLatestBandwidths = async (abortSignal?: AbortSignal) => {
    setLoadingBandwidth(true);
    const response = await apiCall(DashApi.getDeviceLatestBandwidths(accountKiboshDevice.kiboshDeviceReference!, abortSignal));
    setLoadingBandwidth(false);

    return response.success ? AccountDeviceBandwidthLog.toArrayOfClass(response.data?.value || []) : [];
  };

  const getPreferredTimezoneAsync = async () => {
    setPreferredTimezone(await getPreferredTimezone(abortController.signal));
  }

  const getPreferredTimezone = async (abortSignal?: AbortSignal) => {
    setLoadingPreferredTimezone(true);
    const response = await apiCall(DashApi.getPreferredTimezone(accountKiboshDevice.kiboshDeviceReference!, abortSignal));
    setLoadingPreferredTimezone(false);

    return response.success ? response.data?.value || [] : [];
  };

  const getTimezonesAsync = async () => {
    setTimezoneList(await getTimezones(abortController.signal));
  }

  const getTimezones = async (abortSignal?: AbortSignal) => {
    setLoadingTimezoneList(true);
    const response = await apiCall(DashApi.getTimezones(accountKiboshDevice.kiboshDeviceReference!, abortSignal));
    setLoadingTimezoneList(false);

    return response.success ? response.data?.value || [] : [];
  };

  const getDeviceTypesAsync = async () => {
    setDeviceTypes(await getDeviceTypes(abortController.signal));
  }

  const getDeviceTypes = async (abortSignal?: AbortSignal) => {
    setLoadingDevicesTypeList(true);
    const response = await apiCall(CommonValueApi.getDeviceTypes(abortSignal));
    setLoadingDevicesTypeList(false);

    return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
  }

  const onCancel = () => {
    abortController.abort();

    if(onClose) onClose();
  };

  const onTimezoneChange = async (timezone: any) => {
    setLoadingPreferredTimezoneSave(true);

    let response: ApiResponse = await apiCall(DashApi.updatePreferredTimezone(accountKiboshDevice.kiboshDeviceReference!, timezone, abortController.signal));

    if (response.success) {
      message.success(`Timezone updated successfully`);
      getPreferredTimezoneAsync();
    } else
      message.error(response.error?.value);

    setLoadingPreferredTimezoneSave(false);
  }

  const onSubmit: SubmitHandler<accountKiboshDeviceInsertViewModel> = async (formData: accountKiboshDeviceInsertViewModel) => {
    let response: ApiResponse;

    setLoading(true);

    formData.accountId = account.accountId!;
    formData.accountKiboshDeviceId = accountKiboshDevice.accountKiboshDeviceId || 0;

    if (!accountKiboshDevice?.accountKiboshDeviceId)
      response = await apiCall(AccountKiboshDeviceApi.insertAccountKiboshDevice(trimStringProps(formData), abortController.signal));
    else
      response = await apiCall(AccountKiboshDeviceApi.updateAccountKiboshDevice(trimStringProps(formData), abortController.signal));

    if (response.success) {
      message.success(`Kibosh Device ${!accountKiboshDevice?.accountKiboshDeviceId ? 'added' : 'edited'} successfully.`);

      if (onSave) onSave(Account.toClass(response.data?.value));

      if (closeOnSave) {
        open = false;

        onCancel();
      }
    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  const renderDetailsTab = () => {
    return (
      <view>
        {!accountKiboshDevice?.accountKiboshDeviceId &&
        <group data-gap="10" data-background="highlight" data-border="" data-space="15" data-sticky="top">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nunc sed tempor justo. Morbi et orci convallis, cursus odio sed, lobortis tellus. Ut semper dui a posuere condimentum. Phasellus id ligula nec erat laoreet pharetra. Etiam pellentesque euismod cursus. Vivamus vel ultrices risus, lobortis porttitor neque. Pellentesque nec vehicula massa. Aenean pellentesque auctor quam, aliquam consequat ligula varius et. Morbi porta aliquam risus, ut ultrices ipsum. Donec hendrerit mollis tortor sed ultricies. Donec ut malesuada sem.
        </group>}
        <group data-border="" data-space="15" data-gap="10">
        <Select
          control={control}
          label="Device Type"
          labelPosition="left"
          dataLength="320"
          name="kiboshDeviceTypeId"
          allowSearch={true}
          loading={loadingDevicesTypeList}
          options={
            deviceTypes?.map((item) => {
              return {
                text: item.valueCaption!,
                value: item.commonValueId!,
              };
            }) || []
          }
          />
          <Input
            control={control}
            name="kiboshDeviceName"
            label="Kibosh Device Name"
            labelPosition="left"
            dataLength="360"
            size="large"
          />
          <Input
            disabled={!!accountKiboshDevice?.accountKiboshDeviceId}
            control={control}
            name="kiboshDeviceReference"
            label="Kibosh Device Reference"
            labelPosition="left"
            dataLength="360"
            size="large"
          />
          <separator vertical=""></separator>
          <Button material primary onClick={handleSubmit(onSubmit)}>
            <text>Save Changes</text>
          </Button>
        </group>
        {!!accountKiboshDevice?.accountKiboshDeviceId && (
          <>
            <view
              data-direction="column"
              data-width="auto"
              data-scroll=""
              data-background="highlight"
            >
              {loadingPreferredTimezoneSave && <Loader />}
              <group
                data-gap="10"
                data-direction="column"
                data-backdrop=""
                data-sticky="top"
                data-space="15"
                data-border="bottom"
              >
                <Select
                  label="Preferred Timezone"
                  dataLength="360"
                  labelPosition="left"
                  value={preferredTimezone}
                  onChange={onTimezoneChange}
                  loading={loadingTimezoneList || loadingPreferredTimezone}
                  allowSearch={true}
                  options={timezoneList}
                />
              </group>
              <group
                data-type="grid"
                data-gap="10"
                data-grid-template="400"
                data-space="15"
              >
                {loadingBandwidth && <Loader />}
                <DashboardWidget
                  title="Download"
                  height=""
                  headerButtonsContainer={
                    <>
                      <Tooltip title="Maximize">
                        <div
                          className="button micro"
                          data-position="right"
                          onClick={() => setOpenDownloadChartInModal(true)}
                        >
                          <icon>fullscreen</icon>
                        </div>
                      </Tooltip>
                    </>
                  }
                >
                  <CustomChart
                    title="Download"
                    options={{
                      ...chartOptions,
                      labels: bandwidthLogs
                        .filter((x) => x.clientMac !== "combined")
                        .map((logs: AccountDeviceBandwidthLog) => logs.name),
                    }}
                    series={bandwidthLogs
                      .filter((x) => x.clientMac !== "combined")
                      .map(
                        (logs: AccountDeviceBandwidthLog) =>
                          Number(logs.intervalDownloadBandwidth) / 1048576 || 0
                      )}
                    type="pie"
                    width="100%"
                    openInModal={openDownloadChartInModal}
                    onClose={() => setOpenDownloadChartInModal(false)}
                  />
                </DashboardWidget>
                <DashboardWidget
                  title="Upload"
                  height=""
                  headerButtonsContainer={
                    <>
                      <Tooltip title="Maximize">
                        <div
                          className="button micro"
                          data-position="right"
                          onClick={() => setOpenUploadChartInModal(true)}
                        >
                          <icon>fullscreen</icon>
                        </div>
                      </Tooltip>
                    </>
                  }
                >
                  <CustomChart
                    title="Upload"
                    options={{
                      ...chartOptions,
                      labels: bandwidthLogs
                        .filter((x) => x.clientMac !== "combined")
                        .map((logs: AccountDeviceBandwidthLog) => logs.name),
                    }}
                    series={bandwidthLogs
                      .filter((x) => x.clientMac !== "combined")
                      .map(
                        (logs: AccountDeviceBandwidthLog) =>
                          Number(logs.intervalUploadBandwidth) / 1048576 || 0
                      )}
                    type="pie"
                    width="100%"
                    openInModal={openUploadChartInModal}
                    onClose={() => setOpenUploadChartInModal(false)}
                  />
                </DashboardWidget>
                <DashboardWidget
                  title="Total"
                  height=""
                  headerButtonsContainer={
                    <>
                      <Tooltip title="Maximize">
                        <div
                          className="button micro"
                          data-position="right"
                          onClick={() => setOpenTotalChartInModal(true)}
                        >
                          <icon>fullscreen</icon>
                        </div>
                      </Tooltip>
                    </>
                  }
                >
                  <CustomChart
                    title="Total"
                    options={{
                      ...chartOptions,
                      labels: bandwidthLogs
                        .filter((x) => x.clientMac !== "combined")
                        .map((logs: AccountDeviceBandwidthLog) => logs.name),
                    }}
                    series={bandwidthLogs
                      .filter((x) => x.clientMac !== "combined")
                      .map(
                        (logs: AccountDeviceBandwidthLog) =>
                          (Number(logs.intervalDownloadBandwidth) +
                            Number(logs.intervalUploadBandwidth)) /
                            1048576 || 0
                      )}
                    type="pie"
                    width="100%"
                    openInModal={openTotalChartInModal}
                    onClose={() => setOpenTotalChartInModal(false)}
                  />
                </DashboardWidget>
              </group>
            </view>
          </>
        )}
      </view>
    );
  }

  const onStepSave = (acc: Account, step: string) => {
    switch (step) {
      default:
        break;
    }
  };

  return (
    <Popup
      title={`Account: ${account.accountName} - ${(!accountKiboshDevice.accountKiboshDeviceId ? "New Kibosh Device" : `Kibosh Device Name: ${accountKiboshDevice.kiboshDeviceName}`)}`}
      onCancel={onCancel}
      onClose={onCancel}
      noCommandbar={true}
      fixSize="larger"
    >
      { loading &&
        <Loader />
      }
      <TabStrip
        skipSecondaryTabs={false}
        selectedIndex={currentTab}
        onChange={setCurrentTab}
        id="kibosh-device-tab">
        <Tab title='Details'>
          {renderDetailsTab()}
        </Tab>
        <Tab title='Devices' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference}>
          {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference &&
            <AccountDevices account={account} accountKiboshDevice={accountKiboshDevice} refresh={refreshDevices} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'devices')} onGroupSave={() => setRefreshGroups(true)} />
          }
        </Tab>
        <Tab title='Profiles' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference}>
          {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference &&
            <AccountDeviceGroups account={account} accountKiboshDevice={accountKiboshDevice} refresh={refreshGroups} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'deviceGroups')} onMembersListChange={() => setRefreshDevices(true)} />
          }
        </Tab>
        <Tab title='Blocked Sites' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference}>
          {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference &&
            <AccountBlockedSites refresh={!!account.accountId} account={account} kiboshDeviceReference={accountKiboshDevice.kiboshDeviceReference} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'blockedSites')} />
          }
        </Tab>
        <Tab title='Activity Logs' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference}>
          {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference &&
            <AccountDeviceActivityLogs refresh={!!account.accountId} account={account} kiboshDeviceReference={accountKiboshDevice.kiboshDeviceReference} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'activityLogs')} />
          }
        </Tab>
        <Tab title='Internet Schedules' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference}>
          {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference &&
            <AccountInternetSchedules refresh={!!account.accountId} account={account} kiboshDeviceReference={accountKiboshDevice.kiboshDeviceReference} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'internetSchedules')} />
          }
        </Tab>
        <Tab title='Bandwidth Logs' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference}>
          {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference &&
            <AccountDeviceBandwidthLogs refresh={!!account.accountId} account={account} kiboshDeviceReference={accountKiboshDevice.kiboshDeviceReference} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'bandwidthLogs')} />
          }
        </Tab>
        <Tab title='Settings' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference}>
          {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference &&
            <KiboshDeviceSettings account={account} accountKiboshDevice={accountKiboshDevice} onClose={onCancel} onSave={(acc) => onStepSave(acc, 'firmwareUpgrade')} />
          }
        </Tab>
      </TabStrip>
    </Popup>
  );
}
