// 3rd-party modules
import { ReactElement } from "react";

// defines
type Props = {
  children?: React.ReactNode;
  title?: string;
  value?: string;
  secondaryValue?: string;
  description?: string;
  height?: string;
  headerButtonsContainer?: ReactElement;
  minimized?: boolean;
  withoutSpaces?: boolean;
};

export default function DashboardWidget({ children, description, secondaryValue, value, title, headerButtonsContainer, height = "300", minimized = false, withoutSpaces = false }: Props) {
  return (
    <>
      {
        !!minimized ?
        <group data-radius="20" data-background="main-background" data-border="" data-contain="" data-space-vertical="20" data-width="auto">
          <group data-gap="10" data-space-horizontal="20" data-width="auto">
            <group data-align="center" data-width="auto" data-gap="10" data-wrap="no">
              <text data-index="1"  data-light="">{title}</text>
              <separator vertical="dotted"></separator>
              <text data-weight="700" data-text-size="xx-large">{value}</text>
            </group>
          </group>
        </group>
        :
        !!withoutSpaces ?
        <group data-radius='20' data-background='main-background' data-border='' data-contain="" data-direction="column">
          {title && (
            <group data-direction="column">
              <text data-ellipsis="" data-index="1" data-weight="700" data-space='20'>{title}</text>
              {
                headerButtonsContainer
              }
              <separator horizontal="" data-margin="none"></separator>

            </group>
          )}
          <group
            data-scroll=''
            data-gap='10'
            data-height="300"
          >
            {(value || secondaryValue || description) && (
              <group data-direction='column' data-gap='10'>
                {value && <text data-weight="700" data-text-size="xx-large">{value}</text>}
                {secondaryValue && <text>{secondaryValue}</text>}
                {description && <text data-light="" data-wrap="preline" data-ellipsis=''>{description}</text>}
              </group>
            )}
            {children && <group
              //data-justify="end"
              data-align='center'
              data-direction='column'
            >
              {children}</group>}
          </group>
        </group>
        :
        <group data-radius='20' data-background='main-background' data-border='' data-contain="" data-space-vertical='20'>
          {title && (
          <group data-gap='10' data-space-horizontal='20'>
            <group data-align='center'>
              <text data-ellipsis="" data-index="1" data-weight="700">{title}</text>
              {
                headerButtonsContainer
              }
            </group>
            <separator horizontal=""></separator>
          </group>
          )}
          <group data-scroll='' data-gap='10' data-height={height} data-space='20'>
            {(value || secondaryValue || description) && (
            <group data-direction='column' data-gap='10'>
              {value && <text data-weight="700" data-text-size="xx-large">{value}</text>}
              {secondaryValue && <text>{secondaryValue}</text>}
              {description && <text data-light="" data-wrap="preline" data-ellipsis=''>{description}</text>}
            </group>
            )}
            {children && <group data-justify="end" data-align='center' data-direction='column'>{children}</group>}
          </group>
        </group>
      }
    </>
  );
}
