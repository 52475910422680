// 3rd-party modules
import axios, { AxiosResponse } from "axios";

// project modules
import history from '../helpers/routeHelper';
import { ApiResponse } from "../models/response";
import { store } from '../stores';

// defines
import Config from "../config";

export default function setup() {
  axios.interceptors.request.use(
      config => {
        config.headers!['Authorization'] = store.getState().user?.authToken ? `Bearer ${store.getState().user?.authToken}` : '';

        return config;
      },
      error => Promise.reject(error)
  );

  // global error handling
  axios.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => {
      const result = new ApiResponse(response.data);
      const errorMessage = result.errors[0];

      switch (errorMessage) {
        case "Authorization token has expired.":
          history.push(`${Config.loginPageUrl}?returnUrl=${window.location.pathname}`);
      }

      return response;
    },
    error => {
      if (error) {
        console.log("[Global] response error: " + error);

        if (error.response) {
          const result = new ApiResponse(error.response.data);
          const errorMessage = result.errors[0];

          switch (errorMessage) {
            case "Authentication failed.":
            case "Authorization token has expired.":
              history.push(`${Config.loginPageUrl}?returnUrl=${window.location.pathname}`);

              return;
          }

          return Promise.reject(error);
        } else {
          return Promise.reject(error);
        }
      }
    }
  );
}
