// defines
export type ConditionCompareMode = 'equal' | 'notEqual' | 'more' | 'moreOrEqual' | 'less' | 'lessOrEqual' | 'is' | 'isNot' | 'startsWith' | 'startsWithAny' | 'endsWith' | 'endsWithAny' | 'contains' | 'containsAny';

export type ConditionConcatMode = 'and' | 'or';

export type SortOrder = ESortOrder.asc | ESortOrder.desc;

export enum ESortOrder {
  asc = 0,
  desc = 1
}

export class DataSourceRequest {
  conditionList?: FilterDescriptor[];
  sortList?: SortDescriptor[];
  pagination?: PaginationDescriptor;
}

export class FilterDescriptor {
  fieldName?: string;
  fieldValue?: any;
  groupId?: number;
  compareMode?: ConditionCompareMode = 'equal';
  concatMode?: ConditionConcatMode = 'and';
}

export class SortDescriptor {
  fieldName?: string;
  sortOrder?: SortOrder = ESortOrder.asc;
}

export class PaginationDescriptor {
  enable?: boolean = false;
  pageNumber?: number = 1;
  pageSize?:number = 10;
}
