// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, plainToInstance } from 'class-transformer';

export class State {
  @Expose()
  public stateId?: number;
  @Expose()
  public countryId?: number;
  @Expose()
  public stateCode?: string;
  @Expose()
  public stateName?: string;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(State, camelcaseKeys(data, { deep: true }), { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(State, camelcaseKeys(data, { deep: true }) as State[], { excludeExtraneousValues: true });
  }
}
