// 3rd-party modules
import { Tooltip, message } from "antd";
import { useEffect, useState } from "react";

// project modules
import { apiCall } from "../../../../../helpers/apiHelper";
import KiboshDevicePortForwardingModal from "./KiboshDevicePortForwardingModal";
import Button from "../../../../shared/button";
import DataTable, { TableColumn } from "../../../../shared/list/dataTable";
import Loader from "../../../../shared/loader";

// apis
import * as DashApi from '../../../../../apis/dashApi';

// models
import { Account } from "../../../../../models/account";
import { KiboshDevicePortForwarding } from "../../../../../models/kiboshDevicePortForwarding";
import { AccountKiboshDevice } from "../../../../../models/accountKiboshDevice";
import { FilterDescriptor, PaginationDescriptor, SortDescriptor } from "../../../../../models/dataSourceRequest";
import ConfirmationPopup from "../../../../shared/popup/confirmationPopup";

type Props = {
  account?: Account;
  accountKiboshDevice?: AccountKiboshDevice;
  refresh?: boolean;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
};

export default function KiboshDevicePortForwardings({ account, refresh, accountKiboshDevice, onCancel, onSave }: Props) {
  const columns: TableColumn<KiboshDevicePortForwarding>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filterable: true,
    },
    {
      title: 'From Port',
      dataIndex: 'srcDport',
      key: 'srcDport',
      dataType: 'number',
      filterable: true,
      width: 100
    },
    {
      title: 'To IP',
      dataIndex: 'destIp',
      key: 'destIp',
      filterable: true,
      width: 150
    },
    {
      title: 'To Port',
      dataIndex: 'destPort',
      key: 'destPort',
      dataType: 'number',
      filterable: true,
      width: 100
    },
    {
      title: 'Protocol',
      dataIndex: 'proto',
      key: 'proto',
      filterable: true,
      width: 100
    },
    {
      title: '',
      key: 'action',
      width: 60,
      render: (_, record: any) => (
        <group data-wrap='no' data-gap="5">
          <Tooltip title="Edit"><div className='button micro' onClick={() => onEditClick(record)}><icon>edit_square</icon></div></Tooltip>
          <Tooltip title="Delete"><div className='button micro' onClick={() => onDeleteClick(record)}><icon>delete</icon></div></Tooltip>
        </group>
      ),
    }
  ];
  const [currentPortForwarding, setCurrentPortForwarding] = useState<KiboshDevicePortForwarding>(new KiboshDevicePortForwarding());
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  const [showPortForwardingModal, setShowPortForwardingModal] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const abortController = new AbortController();

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    if (!accountKiboshDevice?.kiboshDeviceReference)
      return [];

    setLoading(true);
    const response = await apiCall(DashApi.getPortForwardings(accountKiboshDevice?.kiboshDeviceReference!, abortSignal));
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setLoading(false);

    return response.success ? KiboshDevicePortForwarding.toArrayOfClass(response.data?.value || []) : [];
  };

  const onPortForwardingSave = (e: any) => {
    setReload(true);
  }

  const onEditClick = (portForwarding: KiboshDevicePortForwarding) => {
    setCurrentPortForwarding({...portForwarding});
    setShowPortForwardingModal(true);
  };

  const onNewClick = () => {
    setCurrentPortForwarding(new KiboshDevicePortForwarding());
    setShowPortForwardingModal(true);
  };

  const onDeleteClick = (portForwarding: KiboshDevicePortForwarding) => {
    setCurrentPortForwarding(portForwarding);
    setIsDeleteConfirmationPopupOpen(true);
  };

  const handleDeleteRequest = async () => {
    setLoading(true);
    const response = await apiCall(DashApi.deletePortForwarding(accountKiboshDevice?.kiboshDeviceReference!, currentPortForwarding.id!, abortController.signal));
    setLoading(false);

    if (response.success) {
      message.success(`Port Forwarding deleted successfully.`);
      setIsDeleteConfirmationPopupOpen(false);
      setReload(true);
    }
  };

  return (
    <>
      { loading &&
        <Loader />
      }
      <view>
        
        <view className="table_cont" data-background="none">
            <group data-space="15"  data-gap="20" data-border="" data-background="highlight">
              <Button
                material
                icon="add"
                text="New"
                primary
                onClick={onNewClick}
              />
              <Button
                data-position="right"
                material
                icon="refresh"
                text="Refresh"
                outline
                onClick={() => setReload(true)}
              />
            </group>
            <DataTable columns={columns}
              bordered={true}
              customPagination={true}
              reload={reload}
              dataPagination={true}
              getPageAsync={getPage}
              loading={loading}
              rowKey={record => `${record.id}`}
              showTotal={true}
              size="small"
              totalRecords={totalRecords}
              onRow={(record) => ({
                onDoubleClick: () => onEditClick(record),
                style: { cursor: "pointer" },
              })}
            />
          </view>
      </view>
      {!!showPortForwardingModal &&
        <KiboshDevicePortForwardingModal
          accountKiboshDevice={accountKiboshDevice!}
          closeOnSave={true}
          open={showPortForwardingModal}
          portForwarding={currentPortForwarding}
          onClose={() => setShowPortForwardingModal(false) }
          onSave={(e) => onPortForwardingSave(e) } />
      }
      {isDeleteConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Delete"
          positiveCallback={handleDeleteRequest}
          negativeCallback={() => {
            setIsDeleteConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
