// 3rd-party modules
import { message } from 'antd';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";

// project modules
import Input from "../../shared/inputs/input";
import Loader from "../../shared/loader";
import yup from "../../../plugins/yup";
import { apiCall } from "../../../helpers/apiHelper";

// apis
import * as DashApi from '../../../apis/dashApi';

// models
import Popup from '../../shared/popup/popup';
import { ApiResponse } from "../../../models/response";
import { AccountDevice } from '../../../models/accountDevice';
import { accountDeviceGroupUpdateViewModel } from '../../../models/types/accountDeviceGroup';
import { AccountDeviceGroup } from '../../../models/accountDeviceGroup';

type Props = {
  closeOnSave?: boolean;
  open: boolean;
  accountDevice: AccountDevice;
  accountDeviceGroup?: AccountDeviceGroup;
  kiboshDeviceReference: string;
  onClose?: () => void;
  onSave?: (accountDeviceGroup: any) => void;
};

export default function AccountDeviceGroupCreationModal({ closeOnSave = false, open, accountDeviceGroup,  accountDevice, kiboshDeviceReference, onClose, onSave }: Props) {
  const schema = yup.object().shape({
    name: yup.string().label("Device Name").max(256).required(),
  });
  const { control, handleSubmit } = useForm<accountDeviceGroupUpdateViewModel | any>({
    defaultValues: { ...accountDeviceGroup },
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const abortController = new AbortController();

  const onCancel = () => {
    abortController.abort();

    if(onClose) onClose();
  };

  const onSubmit: SubmitHandler<accountDeviceGroupUpdateViewModel> = async (formData: accountDeviceGroupUpdateViewModel) => {
    let response: ApiResponse;

    setLoading(true);

    formData.kiboshDeviceReference = kiboshDeviceReference;

    if (!accountDeviceGroup?.id)
      response = await apiCall(DashApi.insertDeviceGroup(formData, accountDevice.id, abortController.signal));
    else
      response = await apiCall(DashApi.updateDeviceGroup(accountDeviceGroup.id, formData, abortController.signal));

    if (response.success) {
      message.success(`Profile ${!accountDeviceGroup?.id ? 'added' : 'edited'} successfully.`);

      if (onSave) onSave(response.data?.value);

      if (closeOnSave) {
        open = false;

        onCancel();
      }

    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  return (
    <Popup
      title={`Create new profile based on this device settings`}
      onCancel={onCancel}
      onClose={onCancel}
      onSave={handleSubmit(onSubmit)}
      fixSize="small"
    >
      {loading && <Loader />}
      <group data-space="15" data-gap="10" data-direction="column">
        <Input
          control={control}
          name="name"
          label="Profile Name"
          dataLength="auto"
          size="large"
        />
      </group>
    </Popup>
  );
}
