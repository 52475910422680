// 3rd-party modules
import { message } from 'antd';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";

// project modules
import { apiCall } from "../../../../../helpers/apiHelper";
import { convertToSnakecase } from '../../../../../helpers/objectHelper';
import Input from "../../../../shared/inputs/input";
import Loader from "../../../../shared/loader";
import Popup from '../../../../shared/popup/popup';
import yup from "../../../../../plugins/yup";

// apis
import * as DashApi from '../../../../../apis/dashApi';

// models
import { AccountKiboshDevice } from '../../../../../models/accountKiboshDevice';
import { ApiResponse } from "../../../../../models/response";
import { kiboshDevicePortForwardingInsertViewModel } from '../../../../../models/types/kiboshDevicePortForwarding';
import { KiboshDevicePortForwarding } from '../../../../../models/kiboshDevicePortForwarding';
import Select from '../../../../shared/inputs/select';

type Props = {
  accountKiboshDevice: AccountKiboshDevice;
  portForwarding: KiboshDevicePortForwarding;
  closeOnSave?: boolean;
  open: boolean;
  onClose?: () => void;
  onSave?: (portForwarding: any) => void;
};

export default function KiboshDevicePortForwardingModal({ accountKiboshDevice, portForwarding, closeOnSave = false, open, onClose, onSave }: Props) {
  const schema = yup.object().shape({
    name: yup.string().label("Name").max(256).required(),
    srcDport: yup.number().label("From Port").required(),
    destIp: yup.string().label("To IP").min(7).max(16).required(),
    destPort: yup.number().label("To Port").required(),
    proto: yup.string().label("Protocol").required()
  });
  const { control, handleSubmit, reset } = useForm<kiboshDevicePortForwardingInsertViewModel | any>({
    defaultValues: useMemo(() => portForwarding, [portForwarding]),
    resolver: yupResolver(schema),
  });

  const [loading, setLoading] = useState(false);
  const abortController = new AbortController();

  useEffect(() => {
    reset(); // this is to make sure form clears on new while undefined props ignored by react-hook-form
    reset(portForwarding);
  }, [portForwarding]);

  const onCancel = () => {
    abortController.abort();

    if(onClose) onClose();
  };

  const onSubmit: SubmitHandler<kiboshDevicePortForwardingInsertViewModel> = async (formData: kiboshDevicePortForwardingInsertViewModel) => {
    let response: ApiResponse;

    setLoading(true);

    formData.kiboshDeviceReference = accountKiboshDevice.kiboshDeviceReference!;
    const data = convertToSnakecase(formData, [], ["kiboshDeviceReference"]);

    if (!portForwarding?.id)
      response = await apiCall(DashApi.insertPortForwarding(data, abortController.signal));
    else
      response = await apiCall(DashApi.updatePortForwarding(portForwarding.id, data, abortController.signal));

    if (response.success) {
      message.success(`Port forwarding ${!portForwarding?.id ? 'added' : 'edited'} successfully.`);

      if (onSave) onSave(KiboshDevicePortForwarding.toClass(response.data?.value));

      if (closeOnSave) {
        open = false;

        onCancel();
      }

    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  return (
    <Popup
      title={`${(!portForwarding.id ? "New Port Forwarding" : `Port Forwarding: ${portForwarding.name}`)}`}
      onCancel={onCancel}
      onClose={onCancel}
      onSave={handleSubmit(onSubmit)}
    >
      { loading &&
        <Loader />
      }
      <group data-space="15" data-gap="10" data-direction="column" data-align="start">
        <group data-direction="column" data-gap='10' width='auto'>
          <Input
            control={control}
            name="name"
            label="Name"
            dataLength="360"
            size="large"
          />
          <separator horizontal=""></separator>
        </group>
                <Input
                  control={control}
                  name="srcDport"
                  label="From Port"
                  dataLength="150"
                  size="large"
                />
                 <group data-gap='10'>
                <Input
                  control={control}
                  name="destIp"
                  label="To IP"
                  dataLength="150"
                  size="large"
                />
                <Input
                  control={control}
                  name="destPort"
                  label="To Port"
                  dataLength="150"
                  size="large"
                />
                </group>
                <Select
                  label="Protocol"
                  control={control}
                  dataLength="200"
                  name="proto"
                  allowSearch={true}
                  options={["All","TCP","UDP"]}
                />
              </group>
    </Popup>
  );
}
