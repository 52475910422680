// 3rd-party modules
import { message } from "antd";
import { SubmitHandler, useForm } from "react-hook-form";
import { useEffect, useState } from "react";
import { yupResolver } from "@hookform/resolvers/yup";

// project modules
import Button from "../../components/shared/button";
import DataTable, { TableColumn } from "../../components/shared/list/dataTable";
import Input, { InputType } from "../../components/shared/inputs/input";
import Loader from "../../components/shared/loader";
import yup from "../../plugins/yup";
import { apiCall } from "../../helpers/apiHelper";

// apis
import * as DashApi from '../../apis/dashApi';

// models
import { KiboshDevice } from "../../models/kiboshDevice";
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from "../../models/dataSourceRequest";

export default function ImportDevicesPage() {
  const schema = yup.object().shape({
    references: yup.string().label("References").required(),
  });
  const { control, handleSubmit, setValue } = useForm<any>({
    resolver: yupResolver(schema),
  });
  const columns: TableColumn<KiboshDevice>[] = [
    {
      title: 'Id.',
      dataIndex: 'id',
      key: 'id',
      filterable: true,
      width: 250
    },
    {
      title: 'Device Type',
      dataIndex: 'deviceType',
      key: 'deviceType',
      filterable: true,
      width: 200
    }
  ];
  const [kiboshDevices, setKiboshDevices] = useState<KiboshDevice[]>([]);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const abortController = new AbortController();

  useEffect(() => {
    const getKiboshDevicesAsync = async () => {
      setKiboshDevices(await getKiboshDevices(abortController.signal));
    }

    getKiboshDevicesAsync();
  }, [])

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  /*
  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    const request = { conditionList, sortList, pagination } as DataSourceRequest;

    setLoading(true);
    const response = await apiCall(DashApi.getKiboshDevices(abortSignal));
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setLoading(false);

    return response.success ? KiboshDevice.toArrayOfClass(response.data?.value || []) : [];
  };
  */

  const getKiboshDevices = async (abortSignal?: AbortSignal) => {
    setLoading(true);
    const response = await apiCall(DashApi.getKiboshDevices(abortSignal));
    // setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setLoading(false);

    return response.success ? KiboshDevice.toArrayOfClass(response.data?.value || []).filter(kd => kd.deviceType === 'router') : [];
  };

  const onSubmit: SubmitHandler<any> = async (formData: any) => {
    const deviceReferences = formData.references.toString().split(',');

    setLoading(true);
    const response = await apiCall(DashApi.insertNewKiboshDeviceIds(deviceReferences, undefined, 'router', abortController.signal));
    setLoading(false);

    if (response.success) {
      message.success('References imported successfully.')

      setValue('references', '');
    } else
      message.error('Importing references failed.')
  };

  return (
    <>
      { loading && <Loader /> }
      <group data-space='20' data-border='' data-sticky="top" data-backdrop=''><text data-ellipsis="" data-index="1" data-weight="700">Import Kibosh Devices</text></group>
      <group data-gap="10" data-background="highlight" data-border="" data-space="15" data-sticky="top">Please enter Kibosh device references in comma-separated format bellow:</group>
      <group data-type="grid" data-gap="10" data-space="20" data-grid-template="600">
        <group data-background="main-background" data-border="" data-contain="" data-direction="column">
          <group
            data-space="15"
            data-align="center"
            data-gap="10"
            data-elevation="1"
            data-sticky="bottom"
            data-backdrop=""
          >
            <Input
              control={control}
              name="references"
              type={InputType.TextArea}
              dataLength="auto"
              resize=""
              placeholder="Comma-Separated Device References"
              aria-invalid="false"
              data-height="300"
            />
            <group
              data-space="10"
              data-align="center"
              data-gap="10"
              data-wrap="no"
            >
              <Button large material primary onClick={handleSubmit(onSubmit)}>
                <text>Import</text>
              </Button>
            </group>
          </group>
        </group>
        <group data-background="main-background" data-border="" data-contain="" data-direction="column">
        <group>
          <DataTable
            columns={columns}
            dataSource={kiboshDevices}
            bordered={true}
            customPagination={true}
            // reload={reload}
            // dataPagination={true}
            // getPageAsync={getPage}
            loading={loading}
            rowKey={record => `${record.id}`}
            showTotal={true}
            size="small"
            // totalRecords={totalRecords}
          />
        </group>
        </group>
      </group>
    </>
  )
}
