export const SvgHamburger: React.FC<{ toLeft?: boolean; toRight?: never; } | { toLeft?: never; toRight?: boolean; }> = ({ toLeft, toRight }) => {
    let dataIcon = 'hamburger';

    if (toLeft) {
        dataIcon = 'hamburgertoleft';
    }

    if (toRight) {
        dataIcon = 'hamburgertoright';
    }

    return (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="30"
        height="30"
        viewBox="0 0 30 30"
        data-icon={dataIcon}
        fill="currentColor"
      >
        <rect x="8" y="9" width="14" height="2" />
        <rect x="8" y="14" width="14" height="2" />
        <rect x="8" y="19" width="14" height="2" />
      </svg>
    );
};

export const SvgHamburgerToRight: React.FC = () => {
    return <SvgHamburger toRight={true} />;
};

export const SvgHamburgerToLeft: React.FC = () => {
    return <SvgHamburger toLeft={true} />;
}

export const SvgPlus: React.FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            data-icon="plus"
        >
            <rect x="14" y="8" width="2" height="14" />
            <rect x="8" y="14" width="14" height="2" />
        </svg>
    );
};

export const SvgMinus: React.FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30"
            height="30"
            viewBox="0 0 30 30"
            data-icon="minus"
        >
            <rect x="8" y="14" width="14" height="2" />
            <rect x="8" y="14" width="14" height="2" />
        </svg>
    );
};

export const SvgLoader: React.FC = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="30px"
            height="30px"
            viewBox="0 0 100 100"
            preserveAspectRatio="xMidYMid"
        >
            <g transform="rotate(0 50 50)">
                <rect x="47" y="24" rx="0" ry="0" width="6" height="12">
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="-0.9166666666666666s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(30 50 50)">
                <rect x="47" y="24" rx="0" ry="0" width="6" height="12">
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="-0.8333333333333334s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(60 50 50)">
                <rect x="47" y="24" rx="0" ry="0" width="6" height="12">
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="-0.75s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(90 50 50)">
                <rect x="47" y="24" rx="0" ry="0" width="6" height="12">
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="-0.6666666666666666s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(120 50 50)">
                <rect x="47" y="24" rx="0" ry="0" width="6" height="12">
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="-0.5833333333333334s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(150 50 50)">
                <rect x="47" y="24" rx="0" ry="0" width="6" height="12">
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="-0.5s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(180 50 50)">
                <rect x="47" y="24" rx="0" ry="0" width="6" height="12">
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="-0.4166666666666667s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(210 50 50)">
                <rect x="47" y="24" rx="0" ry="0" width="6" height="12">
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="-0.3333333333333333s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(240 50 50)">
                <rect x="47" y="24" rx="0" ry="0" width="6" height="12">
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="-0.25s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(270 50 50)">
                <rect x="47" y="24" rx="0" ry="0" width="6" height="12">
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="-0.16666666666666666s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(300 50 50)">
                <rect x="47" y="24" rx="0" ry="0" width="6" height="12">
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="-0.08333333333333333s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
            <g transform="rotate(330 50 50)">
                <rect x="47" y="24" rx="0" ry="0" width="6" height="12">
                    <animate
                        attributeName="opacity"
                        values="1;0"
                        keyTimes="0;1"
                        dur="1s"
                        begin="0s"
                        repeatCount="indefinite"
                    ></animate>
                </rect>
            </g>
        </svg>
    );
};

export const SvgFrFlag: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <rect fill="#324095" x="6" y="8" width="6" height="14"></rect>
      <rect fill="#f4f4f4" x="12" y="8" width="6" height="14"></rect>
      <rect fill="#d81e34" x="18" y="8" width="6" height="14"></rect>
    </svg>
  );
};

export const SvgItFlag: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <rect fill="#009434" x="6" y="8" width="6" height="14"></rect>
      <rect fill="#f4f4f4" x="12" y="8" width="6" height="14"></rect>
      <rect fill="#d81e34" x="18" y="8" width="6" height="14"></rect>
    </svg>
  );
};

export const SvgDeFlag: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <rect fill="#000000" x="6" y="8" width="18" height="5"></rect>
      <rect fill="#cd2626" x="6" y="13" width="18" height="5"></rect>
      <rect fill="#ffd700" x="6" y="18" width="18" height="5"></rect>
    </svg>
  );
};
export const SvgEsFlag: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="30"
      height="30"
      viewBox="0 0 30 30"
    >
      <rect fill="#AA151B" x="6" y="8" width="18" height="5"></rect>
      <rect fill="#F1BF00" x="6" y="13" width="18" height="5"></rect>
      <rect fill="#AA151B" x="6" y="18" width="18" height="5"></rect>
    </svg>
  );
};
