// 3rd-party modules
import 'reflect-metadata';
import camelcaseKeys from 'camelcase-keys';
import { Expose, plainToInstance } from 'class-transformer';

export class KiboshDevice {
  @Expose()
  public id?: string;
  @Expose()
  public deviceType?: string;
  @Expose()
  public currentWanIp?: string;
  @Expose()
  public maxClientDevices?: number;
  @Expose()
  public lastDeviceCheckin?: Date;
  @Expose()
  public currentFirmwareVersion?: string;
  @Expose()
  public upgradeStatus?: string;
  @Expose()
  public board?: string;
  @Expose()
  public initialized?: boolean;

  public static toClass(data: object) {
    //@ts-ignore
    return plainToInstance(KiboshDevice, camelcaseKeys(data, { deep: true }) as KiboshDevice, { excludeExtraneousValues: true });
  }

  public static toArrayOfClass(data: object[]) {
    //@ts-ignore
    return plainToInstance(KiboshDevice, camelcaseKeys(data, { deep: true }) as KiboshDevice[], { excludeExtraneousValues: true });
  }
}
