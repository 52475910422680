// project modules
import { getBaseUrl, sendRequest } from '../helpers/apiHelper';

// models
import { AccountSubscription } from '../models/accountSubscription';
import { DataSourceRequest } from '../models/dataSourceRequest';

export const getAccountSubscriptions = async (accountId: number, request?: DataSourceRequest, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/accountSubscription/account/${accountId}/List`, 'post', request, null, abortSignal);
};

export const getCustomerPortalSession = async (customerId: number, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/accountSubscription/StripePortalSession/${customerId}`, 'get', null, null, abortSignal);
};

export const cancelSubscription = async (data: AccountSubscription, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/accountSubscription/cancelSubscription/${data.accountSubscriptionId}`, 'post', null, null, abortSignal);
};

