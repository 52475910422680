// 3rd-party modules
import moment from "moment-timezone";
import { Tooltip } from "antd";
import { useEffect, useState } from "react";

// project modules
import Button from "../../shared/button";
import DataTable, { TableColumn } from "../../shared/list/dataTable";
import Loader from "../../shared/loader";
import { apiCall } from "../../../helpers/apiHelper";
import { formatMoney } from "../../../helpers/objectHelper";

// apis
import * as AccountBillingApi from '../../../apis/accountBillingApi';

// models
import { Account } from "../../../models/account";
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from "../../../models/dataSourceRequest";
import { AccountBilling } from "../../../models/accountBilling";

// defines
import { CONSTANTS } from "../../../helpers/defines";

type Props = {
  account?: Account;
  refresh?: boolean;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
};

export default function AccountBillings({ account, refresh, onCancel, onSave }: Props) {
  const columns: TableColumn<AccountBilling>[] = [
    {
      title: 'Payment id.',
      dataIndex: 'paymentId',
      key: 'paymentId',
      filterable: true,
      width: 250
    },
    {
      title: 'Kibosh Device id.',
      dataIndex: 'kiboshDeviceReference',
      key: 'kiboshDevicereference',
      filterable: true,
      width: 200
    },
    {
      title: 'Amount Paid',
      dataIndex: 'amountPaid',
      key: 'amountPaid',
      filterable: true,
      dataType: 'number',
      width: 150,
      render: text => formatMoney(Number(text) / 100, 2)
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
      filterable: true,
      width: 100,
      render: (text: any) => <>{text.toUpperCase()}</>
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filterable: true,
      width: 100,
      render: (text: any) => <>{text.toUpperCase()}</>
    },
    {
      title: 'Payment Date',
      dataIndex: 'paymentDate',
      key: 'paymentDate',
      filterable: true,
      dataType: 'datetime',
      render: (text: any) => <>{moment(text).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}</>
    },
    {
      title: '',
      key: 'action',
      width: 30,
      render: (_, record: any) => (
        <group data-wrap='no' data-gap="5">
          <Tooltip title="Download PDF">
            <Button
              disabled={record.InvoicePdf || null}
              micro
              icon="picture_as_pdf"
              onClick={() => onDownloadPDF(record)}/>
          </Tooltip>
        </group>
      ),
    }
  ];
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    const request = { conditionList, sortList, pagination } as DataSourceRequest;

    setLoading(true);
    const response = await apiCall(AccountBillingApi.getAccountBillings(account?.accountId!, request, abortSignal));
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setLoading(false);

    return response.success ? AccountBilling.toArrayOfClass(response.data?.value || []) : [];
  };

  const onDownloadPDF = (record: AccountBilling) => {
    const newWindow = window.open(record.invoicePdf, '_blank');

    newWindow?.focus();
  };

  return (
    <>
      {loading &&
        <Loader />
      }
      <view>
        <view className="table_cont" data-background="none">
          <group data-space="15"  data-gap="20" data-border="" data-background="highlight">
            <Button
              data-position="right"
              material
              icon="refresh"
              text="Refresh"
              outline
              onClick={() => setReload(true)}
            />
          </group>
          <DataTable columns={columns}
            bordered={true}
            customPagination={true}
            reload={reload}
            dataPagination={true}
            getPageAsync={getPage}
            loading={loading}
            rowKey={record => `${record.accountInvoiceId}`}
            showTotal={true}
            size="small"
            totalRecords={totalRecords}
          />
        </view>
      </view>
    </>
  );
}
