// 3rd-party modules
import moment from 'moment';
import { ColumnFilterItem } from 'antd/es/table/interface';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Tooltip, message } from 'antd';
import { useEffect, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

// project modules
import AdvancedSearch from '../../components/global/advanced-search';
import Button from '../../components/shared/button';
import CommentsModal from '../../components/support/help-desk/comment/CommentsModal';
import ConfirmationPopup from '../../components/shared/popup/confirmationPopup';
import DataTable, { TableColumn } from '../../components/shared/list/dataTable';
import Input from '../../components/shared/inputs/input';
import Select from '../../components/shared/inputs/select';
import SupportTicketModal from '../../components/support/help-desk/SupportTicketModal';
import yup from '../../plugins/yup';
import { apiCall } from '../../helpers/apiHelper';
import { CONSTANTS } from '../../helpers/defines';
import { DatePicker } from '../../components/shared/inputs/datePicker';
import { kababCaseToCamelCase } from '../../helpers/objectHelper';

// apis
import * as CommonValueApi from '../../apis/commonValueApi';
import * as SupportTicketApi from '../../apis/supportTicketApi';

// models
import { ApiResponse } from '../../models/response';
import { CommonValue } from '../../models/commonValue';
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from '../../models/dataSourceRequest';
import { SupportTicket } from "../../models/supportTicket";

const getTicketCategories = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(CommonValueApi.getTicketCategories(abortSignal));

  return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
};

const getTicketPriorities = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(CommonValueApi.getTicketPriorities(abortSignal));

  return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
};

const getTicketStatuses = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(CommonValueApi.getTicketStatuses(abortSignal));

  return response.success ? [{value: 'all', valueCaption: 'All'}, ...CommonValue.toArrayOfClass(response.data?.value || [])] : [];
};

export default function HelpDeskPage() {
  const columns: TableColumn<SupportTicket>[] = [
    {
      title: 'Ticket #',
      dataIndex: 'supportTicketId',
      key: 'supportTicketId',
      dataType: 'number',
      width: 80
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filterable: true,
      width: 100,
      render: (text: any, record: any) =>
      <>
        <Select
          value={record.status}
          onChange={(status) => onRecordValueChange({...record, status})}
          dataLength="auto"
          loading={loadingTicketStatuses}
          options={
            ticketStatuses?.map((item) => {
              return {
                text: item.valueCaption!,
                value: item.value!,
              };
            }) || []
          }
        />
      </>
    },
    {
      title: 'Account Id',
      dataIndex: 'accountId',
      key: 'accountId',
      filterable: true,
      width: 110,
    },
    {
      title: 'Account Name',
      dataIndex: 'accountName',
      key: 'accountName',
      filterable: true
    },
    {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject',
      filterable: true
    },
    {
      title: 'Category',
      dataIndex: 'ticketCategory',
      key: 'ticketCategory',
      filterable: true,
      width: 200
    },
    {
      title: 'Assignee',
      dataIndex: 'assigneeFullName',
      key: 'assigneeFullName',
      filterable: true,
      width: 200
    },
    {
      title: 'Opened',
      dataIndex: 'createdAt',
      key: 'createdAt',
      filterable: true,
      width: 150,
      dataType: 'datetime',
      render: (text: any) => <>{moment(text).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}</>
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      key: 'priority',
      filterable: true,
      width: 100,
      render: (text: any, record: any) =>
      <>
        <Select
          value={record.priority}
          onChange={(priority) => onRecordValueChange({...record, priority})}
          dataLength="auto"
          loading={loadingTicketPriorities}
          options={
            ticketPriorities?.map((item) => {
              return {
                text: item.valueCaption!,
                value: item.value!,
              };
            }) || []
          }
        />
      </>
    },
    {
      title: 'Last Updated',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      filterable: true,
      width: 150,
      dataType: 'datetime',
      render: (text: any) => <>{text && moment(text).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}</>
    },
    {
      title: '',
      key: 'action',
      width: 100,
      render: (_, record: any) => (
        <group data-wrap='no' data-gap="5">
          <Tooltip title="Details"><div className='button micro' onClick={() => onCommentsClick(record)}><icon>comment</icon></div></Tooltip>
          <separator vertical="" data-adaptive="desktop"></separator>
          <Tooltip title="Edit"><div className='button micro' onClick={() => onEditClick(record)}><icon>edit_square</icon></div></Tooltip>
          <Tooltip title="Delete"><div className='button micro' onClick={() => onDeleteClick(record)}><icon>delete</icon></div></Tooltip>
        </group>
      ),
    }
  ];
  const schema = yup.object().shape({
    ticketStartDate: yup.string().label("Start Date").nullable(),
    ticketEndDate: yup.string().label("End Date").nullable(),
    accountNumber: yup.number().label("Account Number").max(100).nullable(),
    // accountEmail: yup.string().label("Account Email").email().nullable(),
    status: yup.string().label("Status").nullable(),
    ticketCategoryId: yup.string().label("Category").nullable(),
  });
  const { control, handleSubmit, reset, setValue } = useForm<any>({
    resolver: yupResolver(schema),
  });
  const [advancedSearchFilters, setAdvancedSearchFilters] = useState<FilterDescriptor[]>([{ fieldName: "status", fieldValue: "active" }]);
  const [currentSupportTicket, setCurrentSupportTicket] = useState<SupportTicket>(new SupportTicket());
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingTicketPriorities, setLoadingTicketPriorities] = useState(false);
  const [loadingTicketCategories, setLoadingTicketCategories] = useState(false);
  const [loadingTicketStatuses, setLoadingTicketStatuses] = useState(false);
  const [loadingRecordValueChange, setLoadingRecordValueChange] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  const [showAdvancedSearch, setShowAdvancedSearch] = useState(false);
  const [showCommentsModal, setShowCommentsModal] = useState(false);
  const [showSupportTicketModal, setShowSupportTicketModal] = useState(false);
  const [tableColumns, setTableColumns] = useState<TableColumn<SupportTicket>[]>(columns);
  const [ticketCategories, setTicketCategories] = useState<CommonValue[]>([]);
  const [ticketPriorities, setTicketPriorities] = useState<CommonValue[]>([]);
  const [ticketStatuses, setTicketStatuses] = useState<CommonValue[]>([]);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const abortController = new AbortController();

  useEffect(() => {
    const getTicketCategoriesAsync = async () => {
      setLoadingTicketCategories(true);
      setTicketCategories(await getTicketCategories(abortController.signal));
      setLoadingTicketCategories(false);
    }
    const getTicketPrioritiesAsync = async () => {
      setLoadingTicketPriorities(true);
      setTicketPriorities(await getTicketPriorities(abortController.signal));
      setLoadingTicketPriorities(false);
    }
    const getTicketStatusesAsync = async () => {
      setLoadingTicketStatuses(true);
      setTicketStatuses(await getTicketStatuses(abortController.signal));
      setLoadingTicketStatuses(false);
    }

    getTicketCategoriesAsync();
    getTicketPrioritiesAsync();
    getTicketStatusesAsync();

    // set default filters in advanced search section
    for (let filter of advancedSearchFilters) {
      const fieldName = kababCaseToCamelCase(filter.fieldName!);

      setValue(fieldName, filter.fieldValue);
    }
  }, []);

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  useEffect(() => {
    const ticketCategoriesColumn = (columns.find(c => c.dataIndex === "ticketCategory"));

    if (ticketCategoriesColumn) {
      ticketCategoriesColumn.filterable = undefined;
      ticketCategoriesColumn.filters = ticketCategories.map(tc => { return { text: tc.valueCaption, value: tc.value } }) as ColumnFilterItem[];
    }

    const ticketPrioritiesColumn = (columns.find(c => c.dataIndex === "priority"));

    if (ticketPrioritiesColumn) {
      ticketPrioritiesColumn.filterable = undefined;
      ticketPrioritiesColumn.filters = ticketPriorities.map(tc => { return { text: tc.valueCaption, value: tc.value } }) as ColumnFilterItem[];
    }

    const ticketStatusesColumn = (columns.find(c => c.dataIndex === "status"));

    if (ticketStatusesColumn) {
      ticketStatusesColumn.filterable = undefined;
      ticketStatusesColumn.filters = ticketStatuses.map(tc => { return { text: tc.valueCaption, value: tc.value } }) as ColumnFilterItem[];
    }

    setTableColumns(columns);
  }, [ticketCategories, ticketPriorities, ticketStatuses]);

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    const request = { conditionList, sortList, pagination } as DataSourceRequest;

    setLoading(true);
    const response = await apiCall(SupportTicketApi.getSupportTickets(request, abortSignal));
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setLoadingRecordValueChange(false);
    setLoading(false);

    return response.success ? SupportTicket.toArrayOfClass(response.data?.value || []) : [];
  };

  const onAdvancedSearchApplyClick = (formData: any, conditions?: FilterDescriptor[]) => {
    setAdvancedSearchFilters(conditions || []);
    setReload(true);
  }

  /*
  const onAdvancedSearchApplyClick: SubmitHandler<any> = async (formData: any) =>{
    let filters: FilterDescriptor[] = [];

    for (let key in formData) {
      if (formData.hasOwnProperty(key)) {
        const value = formData[key];

        if (value) {
          let filter: FilterDescriptor = {
            fieldName: key,
            fieldValue: value
          }

          switch (key) {
            case "startDate":
              filter = {
                fieldName: "created_at",
                fieldValue: value,
                compareMode: "moreOrEqual"
              }

              break;
            case "endDate":
              filter = {
                fieldName: "created_at",
                fieldValue: value,
                compareMode: "less"
              }

              break;
            default:
              break;
          }

          filters.push(filter)
        }
      }
    }

    setAdvancedSearchFilters(filters);
    setReload(true);
  };
  */

  // const onAdvancedSearchCancelClick = () => {
  //   reset();
  //   setShowAdvancedSearch(false);
  // };

  const onCommentsClick = (supportTicket: SupportTicket) => {
    setCurrentSupportTicket({...supportTicket});
    setShowCommentsModal(true);
  };

  const onDeleteClick = (supportTicket: SupportTicket) => {
    setCurrentSupportTicket(supportTicket);
    setIsDeleteConfirmationPopupOpen(true);
  };

  const onEditClick = (supportTicket: SupportTicket) => {
    setCurrentSupportTicket({...supportTicket});
    setShowSupportTicketModal(true);
  };

  const onNewClick = () => {
    setCurrentSupportTicket(new SupportTicket());
    setShowSupportTicketModal(true);
  };

  const onRecordValueChange = async (supportTicket: SupportTicket) => {
    setLoadingRecordValueChange(true);
    const response: ApiResponse = await apiCall(SupportTicketApi.updateSupportTicket(supportTicket, abortController.signal));

    if (response.success) {
      message.success(`Record updated successfully.`);

    } else
      message.error(response.error?.value);
    setReload(true);
  }

  const onSupportTicketSave = (e: SupportTicket) => {
    setReload(true);
  }

  const handleDeleteRequest = async () => {
    setLoading(true);
    const response = await apiCall(SupportTicketApi.deleteSupportTicket(currentSupportTicket, abortController.signal));
    setLoading(false);

    if (response.success) {
      message.success(`Support Ticket deleted successfully.`);
      setIsDeleteConfirmationPopupOpen(false);
      setReload(true);
    }
  };

  return (
    <>
      <view className="table_cont" data-background="none">
        <group data-space="15"  data-gap="10" data-border="" data-background="highlight">
          <Button
            material
            icon="add"
            text="New"
            primary
            onClick={onNewClick}
          />
          <group data-position="right" data-width='auto' data-gap='10' data-align='center'>
            <Button
              material
              icon="search"
              text="Advanced Search"
              outline
              onClick={() => setShowAdvancedSearch(!showAdvancedSearch)}
            />
            <separator vertical="" data-height="20"></separator>
            <Button
              material
              icon="refresh"
              text="Refresh"
              outline
              onClick={() => setReload(true)}
            />
          </group>
        </group>
        <AdvancedSearch
          show={showAdvancedSearch}
          filters={[
            { type: "date", name: "ticketFromDate", label: "Start Date", yupSchema: yup.string().label("Start Date").nullable(), conditions: [{ fieldName: "ticketFromDate", compareMode: "moreOrEqual" }] },
            { type: "date", name: "ticketToDate", label: "End Date", yupSchema: yup.string().label("End Date").nullable(), conditions: [{ fieldName: "ticketToDate", compareMode: "less" }] },
            { type: "text", name: "accountId", label: "Account Number", yupSchema: yup.number().label("Account Number").max(100).nullable() },
            { type: "select", name: "status", label: "Status", yupSchema: yup.string().label("Status").nullable(), items: ticketStatuses.map(ts => { return { text: ts.valueCaption!, value: ts.value! }; }), props: { loading: loadingTicketStatuses } },
            { type: "select", name: "ticketCategory", label: "Category", yupSchema: yup.string().label("Category").nullable(), items: ticketCategories.map(ts => { return { text: ts.valueCaption!, value: ts.value! }; }), props: { loading: loadingTicketCategories } },
          ]}
          onApplyClick={onAdvancedSearchApplyClick}
        />
        {
          // showAdvancedSearch &&
          // <group data-space="15" data-gap="10" data-border="" data-background="highlight" data-align='center'>
          //   <DatePicker
          //     control={control}
          //     name="ticketFromDate"
          //     label="Start Date"
          //     labelPosition="left"
          //     format={CONSTANTS.DEFAULT_DATE_FORMAT}
          //     dataLength="200"
          //   />
          //   <DatePicker
          //     control={control}
          //     name="ticketToDate"
          //     label="End Date"
          //     labelPosition="left"
          //     format={CONSTANTS.DEFAULT_DATE_FORMAT}
          //     dataLength="200"
          //   />
          //   <Input
          //     control={control}
          //     name="account_number"
          //     label="Account Number"
          //     labelPosition="left"
          //     dataLength="200"
          //     size="large"
          //   />
          //   {/* <Input
          //     control={control}
          //     name="account_email"
          //     label="Account Email"
          //     labelPosition="left"
          //     dataLength="200"
          //     size="large"
          //   /> */}
          //   <Select
          //     control={control}
          //     name="status"
          //     allowClear
          //     label="Status"
          //     labelPosition="left"
          //     dataLength="200"
          //     allowSearch={true}
          //     loading={loadingTicketStatuses}
          //     options={
          //       ticketStatuses?.map((item) => {
          //         return {
          //           text: item.valueCaption!,
          //           value: item.value!,
          //         };
          //       }) || []
          //     }
          //   />
          //   <Select
          //     control={control}
          //     name="ticket_category_id"
          //     allowClear
          //     label="Category"
          //     labelPosition="left"
          //     dataLength="200"
          //     loading={loadingTicketCategories}
          //     allowSearch={true}
          //     options={
          //       ticketCategories?.map((item) => {
          //         return {
          //           text: item.valueCaption!,
          //           value: item.commonValueId!,
          //         };
          //       }) || []
          //     }
          //   />
          //   <separator vertical="" data-height="20"></separator>
          //   <Button
          //     material
          //     text="Apply"
          //     primary
          //     onClick={handleSubmit(onAdvancedSearchApplyClick)}
          //   />
          //   <Button
          //     material
          //     text="Cancel"
          //     outline
          //     onClick={onAdvancedSearchCancelClick}
          //   />
          // </group>
        }
        <DataTable
          columns={tableColumns}
          bordered={true}
          customPagination={true}
          defaultFilters={advancedSearchFilters}
          reload={reload}
          dataPagination={true}
          getPageAsync={getPage}
          loading={loading || loadingRecordValueChange}
          rowKey={record => `${record.supportTicketId}`}
          showTotal={true}
          size="small"
          totalRecords={totalRecords}
          onRow={(record) => ({
            onDoubleClick: () => onCommentsClick(record),
            style: { cursor: "pointer" },
          })}
        />
      </view>
      {!!showSupportTicketModal &&
        <SupportTicketModal open={showSupportTicketModal}
          closeOnSave={true}
          supportTicket={currentSupportTicket}
          onClose={() => setShowSupportTicketModal(false) }
          onSave={(e) => onSupportTicketSave(e) } />
      }
      {!!showCommentsModal &&
        <CommentsModal open={showCommentsModal}
          closeOnSave={true}
          supportTicket={currentSupportTicket}
          onClose={() => setShowCommentsModal(false) }
          onSave={() => setReload(true)} />
      }
      {isDeleteConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Delete"
          positiveCallback={handleDeleteRequest}
          negativeCallback={() => {
            setIsDeleteConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
