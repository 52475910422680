// 3rd-party modules
import moment from "moment-timezone";
import { useEffect, useState } from "react";

// project modules
import Button from "../../shared/button";
import DataTable, { TableColumn } from "../../shared/list/dataTable";
import Loader from "../../shared/loader";
import Select from "../../shared/inputs/select";
import { apiCall } from "../../../helpers/apiHelper";

// apis
import * as AccountKiboshDeviceApi from '../../../apis/accountKiboshDeviceApi';
import * as DashApi from '../../../apis/dashApi';

// models
import { Account } from "../../../models/account";
import { AccountKiboshDevice } from "../../../models/accountKiboshDevice";
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from "../../../models/dataSourceRequest";
import { AccountDevice, AccountDeviceActivityLog } from "../../../models/accountDevice";
import Checkbox from "../../shared/inputs/checkbox";
import { message } from "antd";

// defines
import { CONSTANTS } from "../../../helpers/defines";

type Props = {
  account?: Account;
  deviceReference?: string;
  refresh?: boolean;
  kiboshDeviceReference?: string;
  hasUpperLevelControl?: boolean;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
};

export default function AccountDeviceActivityLogs({ account, deviceReference, refresh, kiboshDeviceReference, hasUpperLevelControl = false, onCancel, onSave }: Props) {
  const columns: TableColumn<AccountDeviceActivityLog>[] = [
    {
      title: 'Domain',
      dataIndex: 'domain',
      key: 'domain',
      filterable: true,
      width: 250
    },
    {
      title: 'Query Time',
      dataIndex: 'queryTime',
      key: 'queryTime',
      filterable: true,
      width: 250,
      dataType: 'datetime',
      render: (text: any) => <>{moment(text).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}</>
    }
  ];
  const [accountKiboshDevices, setAccountKiboshDevices] = useState<AccountKiboshDevice[]>([]);
  const [accountDevices, setAccountDevices] = useState<AccountDevice[]>([]);
  const [activityDays, setActivityDays] = useState<string[]>([]);
  const [currentAccountKiboshDeviceReference, setCurrentAccountKiboshDeviceReference] = useState<string>("");
  const [currentAccountDeviceReference, setCurrentAccountDeviceReference] = useState<string>("");
  const [currentActivityDay, setCurrentActivityDay] = useState<string>("");
  const [isActivityLogsEnabled, setIsActivityLogsEnabled] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAccountKiboshDevices, setLoadingAccountKiboshDevices] = useState(false);
  const [loadingIsActivityLogsEnabled, setLoadingIsActivityLogsEnabled] = useState(false);
  const [loadingAccountDevices, setLoadingAccountDevices] = useState(false);
  const [loadingActivityDays, setLoadingActivityDays] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  let abortController = new AbortController();

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  useEffect(() => {
    if (refresh) {
      if (!kiboshDeviceReference) {
        getAccountKiboshDevicesAsync();
      } else if (!deviceReference) {
        getAccountDevicesAsync();
      }
      else {
        getDeviceActivityEnableStateAsync();
      }
    }
  }, [refresh]);

  useEffect(() => {
    if (activityDays.length > 0)
      onLogDaysChange(activityDays[0]);
  }, [activityDays])

  const getAccountDevicesAsync = async (routerRef?: string) => {
    setAccountDevices(await getAccountDevices(routerRef, abortController.signal));
  }

  const getAccountDevices = async (routerRef?: string, abortSignal?: AbortSignal) => {
    setLoadingAccountDevices(true);
    const response = await apiCall(DashApi.getClientDevices(kiboshDeviceReference || currentAccountKiboshDeviceReference || routerRef!, abortSignal));
    setLoadingAccountDevices(false);

    return response.success ? AccountDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const getAccountKiboshDevicesAsync = async () => {
    setAccountKiboshDevices(await getAccountKiboshDevices({}, abortController.signal));
  }

  const getAccountKiboshDevices = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
    setLoadingAccountKiboshDevices(true);
    const response = await apiCall(AccountKiboshDeviceApi.getAccountKiboshDevices(account?.accountId!, request, abortSignal));
    setLoadingAccountKiboshDevices(false);

    return response.success ? AccountKiboshDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const getDeviceActivityEnableStateAsync = async (deviceRef?: string) => {
    const data = await getDeviceActivityLogEnableState(deviceRef, abortController.signal);
    setIsActivityLogsEnabled(data);
    if (data) getDeviceActivityDaysAsync(deviceRef);
  }

  const getDeviceActivityLogEnableState = async (deviceRef?: string, abortSignal?: AbortSignal) => {
    setLoadingIsActivityLogsEnabled(true);
    const response = await apiCall(DashApi.getDeviceActivityLogEnableState(kiboshDeviceReference || currentAccountKiboshDeviceReference, deviceRef || deviceReference || currentAccountDeviceReference, abortSignal));
    setLoadingIsActivityLogsEnabled(false);

    return response.success ? response.data?.value : false;
  };

  const getDeviceActivityDaysAsync = async (deviceRef?: string) => {
    setActivityDays(await getDeviceActivityDays(deviceRef, abortController.signal));
  }

  const getDeviceActivityDays = async (deviceRef?: string, abortSignal?: AbortSignal) => {
    setLoadingActivityDays(true);
    const response = await apiCall(DashApi.getDeviceActivityDays(kiboshDeviceReference || currentAccountKiboshDeviceReference, deviceRef || deviceReference || currentAccountDeviceReference, abortSignal));
    setLoadingActivityDays(false);

    return response.success ? response.data?.value || [] : [];
  };

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    if (!currentActivityDay)
      return [];

    setLoading(true);
    const response = await apiCall(DashApi.getDeviceActivities(kiboshDeviceReference || currentAccountKiboshDeviceReference, deviceReference || currentAccountDeviceReference, currentActivityDay, abortSignal));
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setLoading(false);

    return response.success ? AccountDeviceActivityLog.toArrayOfClass(response.data?.value || []) : [];
  };

  const onAccountKiboshDeviceChange = (kiboshDeviceReference: any) => {
    setCurrentAccountKiboshDeviceReference(kiboshDeviceReference);
    getAccountDevicesAsync(kiboshDeviceReference);
  }

  const onAccountDeviceChange = (deviceReference: any) => {
    setCurrentAccountDeviceReference(deviceReference);
    getDeviceActivityEnableStateAsync(deviceReference);
    // if (isActivityLogsEnabled) {
    //   getDeviceActivityDaysAsync(deviceReference);
    // }
  }

  const onLogDaysChange = (logDay: any) => {
    setCurrentActivityDay(logDay);
    setReload(true);
  }

  const onActivityLogsEnabledCheckboxChange = async (event: any) => {
    setLoadingIsActivityLogsEnabled(true);
    const response = await apiCall(DashApi.updateDeviceActivityLogEnableState(kiboshDeviceReference || currentAccountKiboshDeviceReference, deviceReference || currentAccountDeviceReference, event.target?.checked, abortController.signal));

    if (response.success) {
      message.success("Activity Logs enabled state changed successfully.");
      getDeviceActivityEnableStateAsync();
    } else {
      message.error(response.error?.value);
      setLoadingIsActivityLogsEnabled(false);
    }
  }

  return (
    <>
      {loading && <Loader />}
      <view>
        <group data-space="15" data-border="" data-gap="5">
          <group data-width="auto" data-gap="15">
            {(!kiboshDeviceReference || !deviceReference) && (
              <>
                {!kiboshDeviceReference && (
                  <>
                    <Select
                      autoComplete=""
                      label="Kibosh Device"
                      labelPosition="left"
                      dataLength="320"
                      onChange={onAccountKiboshDeviceChange}
                      loading={loadingAccountKiboshDevices}
                      allowSearch={true}
                      options={
                        accountKiboshDevices?.map((item) => {
                          return {
                            text: `${item.kiboshDeviceName!} (${item.kiboshDeviceReference!})`,
                            value: item.kiboshDeviceReference!,
                          };
                        }) || []
                      }
                      button={
                        <Button
                          material
                          icon="refresh"
                          onClick={getAccountKiboshDevicesAsync}
                        />
                      }
                    />
                  </>
                )}
                {!deviceReference && (
                  <>
                    <Select
                      autoComplete=""
                      disabled={
                        loadingAccountDevices ||
                        !(
                          kiboshDeviceReference ||
                          currentAccountKiboshDeviceReference
                        )
                      }
                      label="Device"
                      labelPosition="left"
                      dataLength="350"
                      onChange={onAccountDeviceChange}
                      loading={loadingAccountDevices}
                      allowSearch={true}
                      options={
                        accountDevices?.map((item) => {
                          return {
                            text: `${item.name!} (${item.id!})`,
                            value: item.id!,
                          };
                        }) || []
                      }
                      button={
                        <Button
                          material
                          icon="refresh"
                          onClick={getAccountDevicesAsync}
                        />
                      }
                    />
                  </>
                )}
              </>
            )}
            {loadingIsActivityLogsEnabled && <Loader />}
            <Checkbox
              checked={isActivityLogsEnabled}
              disabled={
                loadingIsActivityLogsEnabled ||
                !(deviceReference || currentAccountDeviceReference) || hasUpperLevelControl
              }
              label="Activity Logs Enabled"
              minimal
              dataLength="auto"
              onChange={onActivityLogsEnabledCheckboxChange}
            />
          </group>
          {isActivityLogsEnabled && (
            <group data-width="auto" data-gap="15" data-align="center">
              <separator vertical="" data-height="20"></separator>
              <Select
                value={currentActivityDay} //?
                disabled={!deviceReference && !currentAccountDeviceReference}
                autoComplete=""
                label="Display Logs For"
                labelPosition="left"
                dataLength="350"
                onChange={onLogDaysChange}
                loading={loadingActivityDays}
                allowSearch={true}
                options={activityDays || []}
                button={
                  <Button
                    disabled={
                      !deviceReference && !currentAccountDeviceReference
                    }
                    material
                    icon="refresh"
                    onClick={getDeviceActivityDaysAsync}
                  />
                }
              />
            </group>
          )}
        </group>

        {isActivityLogsEnabled && (
          <>
            <view className="table_cont" data-background="none">
              <group
                data-space="15"
                data-gap="20"
                data-border=""
                data-background="highlight"
              >
                <Button
                  data-position="right"
                  material
                  icon="refresh"
                  text="Refresh"
                  outline
                  onClick={() => setReload(true)}
                />
              </group>
              <DataTable
                columns={columns}
                bordered={true}
                customPagination={true}
                reload={reload}
                dataPagination={true}
                getPageAsync={getPage}
                loading={loading}
                showTotal={true}
                size="small"
                totalRecords={totalRecords}
              />
            </view>
          </>
        )}
      </view>
    </>
  );
}
