// 3rd-party modules
import { message, Transfer } from 'antd';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';

// project modules
import Button from '../shared/button';
import Checkbox from '../../components/shared/inputs/checkbox';
import Input, { Password } from '../../components/shared/inputs/input';
import Loader from "../../components/shared/loader";
import yup from '../../plugins/yup';
import { apiCall } from '../../helpers/apiHelper';

// apis
import * as AuthApi from '../../apis/authApi';

// models
import ChangePasswordModel from './ChangePasswordModel';
import TabStrip from '../shared/tabstrip';
import Tab from '../shared/tabstrip/tab';
import Popup from "../shared/popup/popup";
import { ApiResponse } from '../../models/response';
import { registerViewModel } from '../../models/types/auth';
import { Role, User } from '../../models/auth';

type Props = {
  closeOnSave?: boolean;
  open: boolean;
  user: User;
  onClose?: () => void;
  onSave?: (user: User) => void;
};

const getRoles = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(AuthApi.getRoles(abortSignal));

  return response.success ? Role.toArrayOfClass(response.data?.value || []) : [];
};

const getUserRoles = async (username: string, abortSignal?: AbortSignal) => {
  const response = await apiCall(AuthApi.getUserRoles(username, abortSignal));

  return response.success ? Role.toArrayOfClass(response.data?.value || []) : [];
};

export default function SystemUserModal({ closeOnSave = false, open, user, onClose, onSave }: Props) {
  const schema = yup.object().shape({
    firstName: yup.string().trim().label("First Name").max(256).required(),
    lastName: yup.string().trim().label("Last Name").max(256).required(),
    username: yup.string().trim().label("Username").max(256).required(),
    email: yup.string().trim().label("Email").max(256).required(),
    password: yup.string().label("Password").min(6).max(50).test('user-id provided', 'Please enter password', (value) => (value !== undefined && value?.length > 0) || user.userId !== undefined),
    confirmPassword: yup.string().label("Confirm Password").max(50).oneOf([yup.ref('password'), ''], 'Passwords must match'),
    lockoutEnabled: yup.bool().label("Lockout Enabled")
  });
  const { control, handleSubmit, reset } = useForm<registerViewModel | any>({
    defaultValues: useMemo(() => user, [user]),
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [userRoleIds, setUserRoleIds] = useState<string[]>([]);
  const [userRoles, setUserRoles] = useState<Role[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const abortController = new AbortController();

  useEffect(() => {
    const getRolesAsync = async () => {
      setRoles(await getRoles(abortController.signal));
    }

    getRolesAsync();
  }, []);

  useEffect(() => {
    if (open) {
      const getUserRolesAsync = async () => {
        setUserRoles(await getUserRoles(user.username!, abortController.signal));
      }

      if (user.userId)
        getUserRolesAsync();
    }
  }, [open]);

  useEffect(() => {
    const userRoleIds: string[] = [];

    for (const roleName of userRoles) {
      const role = roles.find(r => r.roleName === roleName);

      if (role) userRoleIds.push(role.roleId!.toString());
    }

    setUserRoleIds(userRoleIds);
  }, [userRoles])

  useEffect(() => {
    reset(); // this is to make sure form clears on new while undefined props ignored by react-hook-form
    reset(user);
  }, [user]);

  const filterOption = (inputValue: string, option: Role) =>
    option.roleName!.toLowerCase().indexOf(inputValue.toLowerCase()) > -1;

  const onUserRoleChange = (targetKeys: any[]) => {
    setUserRoleIds(targetKeys);
  };

  const onCancel = () => {
    abortController.abort();

    if(onClose) onClose();
  };

  const onChangePasswordClick = () => {
    setShowChangePasswordModal(true);
  };

  const onSubmit: SubmitHandler<registerViewModel> = async (formData: registerViewModel) => {
    let response: ApiResponse;

    setLoading(true);

    if (!user.userId)
      response = await apiCall(AuthApi.insertUser(formData, abortController.signal));
    else
      response = await apiCall(AuthApi.updateUser(formData, abortController.signal));

    if (response.success) {
      message.success(`User ${!user.userId ? 'added' : 'edited'} successfully.`);

      user.userId = response.data?.value?.id;

      const roleNames: string[] = [];

      for (const roleId of userRoleIds) {
          const role = roles.find(r => r.roleId!.toString() === roleId);

          if (role) roleNames.push(role.roleName!);
      }

      response = await apiCall(AuthApi.addUserToRoles(formData.username!, roleNames, abortController.signal));

      if (!response.success)
          message.error('Saving user role(s) faild.');

      if (user.userId && formData.password && formData.confirmPassword) {
        response = await apiCall(AuthApi.changePassword({ username: formData.username!, password: formData.password }, abortController.signal));

        if (response.success)
          message.success('Password changed successfully.');
        else
          message.error('Password changed faild.');
      }

      if (onSave) onSave(User.toClass(response.data?.value));

      if (closeOnSave) {
        open = false;

        onCancel();
      }
    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  const renderUserInfoTab = () => {
    return (
      <view>
        <view data-scroll="">
          <group
            data-background=""
            data-space="15"
            data-gap="10"
            data-direction="column"
            data-align="start"
          >
            <group data-direction="column" width="auto" data-gap="10">
              <group data-gap="10" width="auto">
                <Input
                  control={control}
                  name="firstName"
                  label="First Name"
                  dataLength="200"
                  size="large"
                />
                <Input
                  control={control}
                  name="lastName"
                  label="Last Name"
                  dataLength="260"
                  size="large"
                />
              </group>
              <separator horizontal=""></separator>
            </group>
            <Input
              control={control}
              name="username"
              label="Username"
              dataLength="360"
              size="large"
            />
            <Input
              control={control}
              name="email"
              label="Email"
              dataLength="360"
              size="large"
            />
            {!user?.userId && (
              <Password
                control={control}
                name="password"
                label="Password"
                dataLength="auto"
                size="large"
              />
            )}
            {!user?.userId && (
              <Password
                control={control}
                name="confirmPassword"
                label="Confirm Password"
                dataLength="auto"
                size="large"
              />
            )}
            <group data-length="360">
              <Checkbox
                control={control}
                name="lockoutEnabled"
                label="Inactive"
                minimal
              />
              {user?.userId && (
                <Button
                  data-position="right"
                  data-length="forcefit"
                  highlight
                  onClick={onChangePasswordClick}
                >
                  Change Password
                </Button>
              )}
            </group>
          </group>
        </view>
      </view>
    );
  }

  const renderUserRolesTab = () => {
    return (
      <group data-gap="15" data-direction="column">
        <group data-background="" data-space="15" data-radius="15" data-border="" data-gap="10">
          <Transfer dataSource={roles}
                    disabled={user.lockoutEnabled}
                    filterOption={filterOption}
                    render={item => item.roleName!}
                    rowKey={record => record.roleId!.toString()}
                    showSearch
                    targetKeys={userRoleIds}
                    onChange={onUserRoleChange}
          />
        </group>
      </group>
    );
  }

  return (
    <Popup
      title="System User"
      onCancel={onCancel}
      onClose={onCancel}
      fixSize="medium"
      onSave={handleSubmit(onSubmit)}
    >
      { loading &&
        <Loader />
      }
      <TabStrip
        skipSecondaryTabs={false}
        selectedIndex={0}
        id="system-users-tab">
        <Tab title='User Info.'>
          { renderUserInfoTab() }
        </Tab>
        <Tab title='User Roles' disabled={!user?.userId}>
          { !!user?.userId && renderUserRolesTab() }
        </Tab>
      </TabStrip>
      {!!showChangePasswordModal &&
        <ChangePasswordModel open={showChangePasswordModal}
                             closeOnSave={true}
                             user={user}
                             onClose={() => setShowChangePasswordModal(false) } />
      }
    </Popup>
  );
}
