// project modules
import HardwareInfo from "../../components/support/dashboard/HardwareInfo";
import ServerMonitor from "../../components/support/dashboard/ServerMonitor";
import SupportTicketStats from "../../components/support/dashboard/SupportTicketSummary";

export default function SupportDashboardPage() {
  return (
    <view data-scroll='' data-adaptive="" data-background="highlight">
      <SupportTicketStats />
      {/* <HardwareInfo /> */}
      <ServerMonitor />
    </view>
  );
}
