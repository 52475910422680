// 3rd-party modules
import { useEffect, useState } from "react";
import { Tooltip, message } from 'antd';

// project modules
import Button from '../../components/shared/button';
import CommonValueModal from "./commonValueModal";
import ConfirmationPopup from '../../components/shared/popup/confirmationPopup';
import DataTable, { TableColumn } from "../../components/shared/list/dataTable";
import { apiCall } from "../../helpers/apiHelper";

// apis
import * as CommonValueApi from '../../apis/commonValueApi';

// models
import { CommonValue } from "../../models/commonValue";

// defines
type Props = {
  commonCode: string;
  canAdd?: boolean;
  canEdit?: boolean;
  canDelete?: boolean;
  title?: string;
};

export default function CommonValuesTable({ canAdd = true, canEdit = true, canDelete = true, commonCode, title }: Props) {
  const columns: TableColumn<CommonValue>[] = [
    {
      title: 'Value Caption',
      dataIndex: 'valueCaption',
      key: 'valueCaption'
    },
    {
      title: '',
      key: 'action',
      width: 100,
      render: (_, record: any) => (
        <group data-wrap='no' data-gap="5">
          <Tooltip title="Edit"><div className='button micro' onClick={() => onEditClick(record)}><icon>edit_square</icon></div></Tooltip>
          <Tooltip title="Delete"><div className='button micro' onClick={() => onDeleteClick(record)}><icon>delete</icon></div></Tooltip>
        </group>
      ),
    }
  ];
  const abortController = new AbortController();
  const [commonValues, setCommonValues] = useState<CommonValue[]>([]);
  const [currentCommonValue, setCurrentCommonValue] = useState<CommonValue>(new CommonValue());
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showCommonValueModal, setShowCommonValueModal] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  useEffect(() => {
    const getCommonValuesAsync = async () => {
      setLoading(true);
      setCommonValues(await getCommonValues(commonCode, abortController.signal));
      setLoading(false);
    }

    getCommonValuesAsync();
  }, []);

  const getCommonValues = async (commonCode: string, abortSignal?: AbortSignal) => {
    const response = await apiCall(CommonValueApi.getCommonValues(commonCode, abortSignal));
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);

    return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
  };

  const reload = async () => {
    setLoading(true);
    setCommonValues(await getCommonValues(commonCode, abortController.signal));
    setLoading(false);
  }

  const onNewClick = () => {
    setCurrentCommonValue(new CommonValue());
    setShowCommonValueModal(true);
  };

  const onEditClick = (commonValue: CommonValue) => {
    setCurrentCommonValue({...commonValue});
    setShowCommonValueModal(true);
  };

  const onDeleteClick = (commonValue: CommonValue) => {
    setCurrentCommonValue(commonValue);
    setIsDeleteConfirmationPopupOpen(true);
  };

  const handleDeleteRequest = async () => {
    setLoading(true);
    const response = await apiCall(CommonValueApi.deleteCommonValue(currentCommonValue, abortController.signal));
    setLoading(false);

    if (response.success) {
      message.success(`Value deleted successfully.`);
      setIsDeleteConfirmationPopupOpen(false);
      reload();
    }
  };

  const onCommonValueSave = (e: CommonValue) => {
    reload();
  }

  return (
    <>
      <view className="table_cont" data-background="none">
        <group data-space="15"  data-gap="10" data-border="" data-background="highlight">
          <Button
            material
            icon="add"
            text="New"
            primary
            onClick={onNewClick}
          />
          <group data-position="right" data-width='auto' data-gap='10' data-align='center'>
            <Button
              material
              icon="refresh"
              text="Refresh"
              outline
              onClick={() => reload()}
            />
          </group>
        </group>
        <DataTable
          columns={columns}
          bordered={true}
          customPagination={true}
          dataSource={commonValues}
          loading={loading}
          rowKey={record => `${record.commonValueId}`}
          showTotal={true}
          size="small"
          totalRecords={totalRecords}
          onRow={(record) => ({
            onDoubleClick: () => onEditClick(record),
            style: { cursor: "pointer" },
          })}
        />
      </view>
      {!!showCommonValueModal &&
        <CommonValueModal
          open={showCommonValueModal}
          commonCode={commonCode}
          commonCodeTitle={title || ""}
          closeOnSave={true}
          commonValue={currentCommonValue}
          onClose={() => setShowCommonValueModal(false) }
          onSave={(e) => onCommonValueSave(e) } />
      }
      {isDeleteConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Delete"
          positiveCallback={handleDeleteRequest}
          negativeCallback={() => {
            setIsDeleteConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
