// 3rd-party modules
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { SubNavigationItem } from '../../models/types/navigation';

// defines
type Props = {
  navItems: SubNavigationItem[]
};

export default function SubNavigation({ navItems }: Props) {
  const location = useLocation();
  const [currentLink, setCurrentLink] = useState('/');

  useEffect(() => {
    setCurrentLink(`/${location.pathname.substring(1)}`);
  }, [location]);

  return (
    <>
      {navItems.length > 0 && <group data-width="auto">
        <nav className="nav_strip">
          <ul>
            {navItems.map((item) => (
              <li
                className={currentLink === item.link ? "selected" : undefined}
                key={item.text}
              >
                <Link
                  data-type="group"
                  data-space-horizontal="15"
                  to={item.link}
                >
                  <text>{item.text}</text>
                </Link>
              </li>
            ))}
          </ul>
        </nav>
      </group>}
    </>
  );
}
