// 3rd-party modules
import { CSS } from '@dnd-kit/utilities';
import type { DragEndEvent } from '@dnd-kit/core';
import { DndContext, PointerSensor, useSensor, useSensors } from '@dnd-kit/core';
import {
  arrayMove,
  SortableContext,
  useSortable,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { message, Tooltip } from 'antd';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { useEffect, useState } from 'react';

// project modules
import Button from '../../components/shared/button';
import ConfirmationPopup from '../../components/shared/popup/confirmationPopup';
import DataTable, { TableColumn } from '../../components/shared/list/dataTable';
import { apiCall } from '../../helpers/apiHelper';

// apis
import * as DashApi from '../../apis/dashApi';

// models
import AdminFilterSetElements from '../../components/support/admin-filter-set/AdminFilterSetElements';
import AdminFilterSetModal from '../../components/support/admin-filter-set/AdminFilterSetModal';
import { FilterDescriptor, PaginationDescriptor, SortDescriptor } from '../../models/dataSourceRequest';
import { AdminFilterSet } from '../../models/adminFilterSet';
import { ApiResponse } from '../../models/response';

export default function AdminFilterSetsPage() {
  const columns: TableColumn<AdminFilterSet>[] = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      filterable: true,
    },
    {
      title: 'Order',
      dataIndex: 'displayOrder',
      key: 'displayOrder',
      dataType: 'number',
      width: 65
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: 150,
      filterable: true,
    },
    {
      title: '',
      key: 'action',
      width: 60,
      render: (_, record: any, index: number) => (
        <group data-wrap='no' data-gap="5">
          <>
            <Tooltip title="Edit"><div className='button micro' onClick={() => onEditClick(record)}><icon>edit_square</icon></div></Tooltip>
            <Tooltip title="Delete"><div className='button micro' onClick={() => onDeleteClick(record)}><icon>delete</icon></div></Tooltip>
          </>
        </group>
      ),
    }
  ];
  const [currentFilterSet, setCurrentFilterSet] = useState<AdminFilterSet>(new AdminFilterSet());
  const [filterSets, setFilterSets] = useState<AdminFilterSet[]>([]);
  const [defaultFilterSets, setDefaultFilterSets] = useState<AdminFilterSet[]>([]);
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);
  const [isReorderCancelConfirmationPopupOpen, setIsReorderCancelConfirmationPopupOpen] = useState(false);
  const [isReorderModeActive, setIsReorderModeActive] = useState(false);
  const [anyOrderChanged, setAnyOrderChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  const [showFilterSetModal, setShowFilterSetModal] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const abortController = new AbortController();

  useEffect(() => {
    setReload(true)
  }, []);

  useEffect(() => {
    if (reload) {
      getPage();
      setReload(false);
    }
  }, [reload]);

  const onNewClick = () => {
    let newFilterSet = new AdminFilterSet();

    //setting default value for displayOrder, on new
    const data = filterSets.sort((a: AdminFilterSet, b: AdminFilterSet) => (b.displayOrder || 0) - (a.displayOrder || 0));
    newFilterSet.displayOrder = 1;
    if (data.length) {
      newFilterSet.displayOrder = (data[0].displayOrder || 0) + 1;
    }
    setCurrentFilterSet(newFilterSet);
    setShowFilterSetModal(true);
  };

  const onEditClick = (filterSet: AdminFilterSet) => {
    setCurrentFilterSet({ ...filterSet });
    setShowFilterSetModal(true);
  };

  const onDeleteClick = (filterSet: AdminFilterSet) => {
    setCurrentFilterSet(filterSet);
    setIsDeleteConfirmationPopupOpen(true);
  };

  const handleDeleteRequest = async () => {
    setLoading(true);
    const response = await apiCall(DashApi.deleteAdminFilterSet(currentFilterSet.id!));
    setLoading(false);

    if (response.success) {
      message.success(`Filter set deleted successfully.`);
      setIsDeleteConfirmationPopupOpen(false);
      setReload(true);
    }
  };

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    setLoading(true);
    const response = await apiCall(DashApi.getAdminFilterSets(abortSignal));
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setLoading(false);
    const data = response.success ? AdminFilterSet.toArrayOfClass(response.data?.value || []).sort((a: AdminFilterSet, b: AdminFilterSet) => (a.displayOrder || 0) - (b.displayOrder || 0)) : [];
    setFilterSets(data);
    setDefaultFilterSets(data);

    return data;
  };

  const handleCancelReorderRequest = () => {
    setIsReorderCancelConfirmationPopupOpen(false);
    setIsReorderModeActive(false);
    setReload(true);
  }

  interface RowProps extends React.HTMLAttributes<HTMLTableRowElement> {
    'data-row-key': string;
  }

  const Row = (props: RowProps) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
      id: props['data-row-key'],
    });

    const style: React.CSSProperties = {
      ...props.style,
      transform: CSS.Translate.toString(transform),
      transition,
      cursor: 'move',
      ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };

    return <tr {...props} ref={setNodeRef} style={style} {...attributes} {...listeners} />;
  };

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        // https://docs.dndkit.com/api-documentation/sensors/pointer#activation-constraints
        distance: 1,
      },
    }),
  );

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      setAnyOrderChanged(true);
      setIsReorderModeActive(true);
      setFilterSets((prev) => {
        const activeIndex = prev.findIndex((i) => i.id === active.id);
        const overIndex = prev.findIndex((i) => i.id === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  };

  const onOrderSaveClick = async () => {
    const filterSetWithRightOrders = filterSets.map((x: AdminFilterSet, index: number) => {
      return {
        ...x,
        displayOrder: defaultFilterSets[index].displayOrder
      }
    });

    const modifiedData = filterSetWithRightOrders.filter((x, index) => x.id !== defaultFilterSets[index].id);

    setLoading(true);

    let response: ApiResponse = await apiCall(DashApi.bulkUpdateAdminFilterSet(modifiedData, abortController.signal));;

    if (response.success) {
      message.success(`Filters order saved successfully`);


    } else
      message.error(response.error?.value);

    handleCancelReorderRequest();
  }


  return (
    <>
      <view className="table_cont" data-background="none">
        <group data-space="15" data-gap="20" data-border="" data-background="highlight">
          {isReorderModeActive ?
            <>
              <Button
                data-position="right"
                material
                text="Discard"
                outline
                onClick={() => anyOrderChanged ? setIsReorderCancelConfirmationPopupOpen(true) : setIsReorderModeActive(false)}
              />
              <Button
                material
                disabled={anyOrderChanged ? undefined : true}
                text="Save"
                primary
                onClick={onOrderSaveClick}
              />
            </>
            :
            <>
              <Button
                material
                icon="add"
                text="New"
                primary
                onClick={onNewClick}
              />
              <Button
                data-position="right"
                material
                icon="refresh"
                text="Refresh"
                outline
                onClick={() => setReload(true)}
              />
            </>
          }
        </group>
        <DndContext sensors={sensors} modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
          <SortableContext
            // rowKey array
            items={filterSets.map((i) => i.id!)}
            strategy={verticalListSortingStrategy}
          >
            <DataTable
              components={{
                body: {
                  row: Row,
                },
              }}
              columns={columns}
              bordered={true}
              // customPagination={true}
              // reload={reload}
              dataSource={filterSets}
              // dataPagination={true}
              // getPageAsync={getPage}
              loading={loading}
              rowKey={record => `${record.id}`}
              showTotal={true}
              size="small"
              // totalRecords={totalRecords}
              onRow={(record) => ({
                onDoubleClick: () => onEditClick(record),
                style: { cursor: "pointer" },
              })}
              expandable={{
                expandedRowRender: (record) => (
                  <AdminFilterSetElements item={record} open={true} />
                ),
              }}
            />
          </SortableContext>
        </DndContext>

      </view>
      {showFilterSetModal &&
        <AdminFilterSetModal open={showFilterSetModal}
          closeOnSave={true}
          adminFilterSet={currentFilterSet}
          onClose={() => setShowFilterSetModal(false)}
          onSave={(e: any) => setReload(true)} />
      }
      {isDeleteConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Delete"
          positiveCallback={handleDeleteRequest}
          negativeCallback={() => {
            setIsDeleteConfirmationPopupOpen(false);
          }}
        />
      )}
      {isReorderCancelConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Discard Reorder"
          positiveCallback={handleCancelReorderRequest}
          negativeCallback={() => {
            setIsReorderCancelConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
