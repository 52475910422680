import { PropsWithChildren, useState } from "react";
import Ripple from "./Ripple";

type SwitchableProps = PropsWithChildren<{
  defaultExpanded?: boolean;
  title?: string;
  togglerProps?: any;
  icon?: string;
}>;

const Switchable: React.FC<SwitchableProps> = ({
  children,
  defaultExpanded = "true",
  title,
  togglerProps,
  icon,
  ...rest
}) => {
  const [isExpanded, setIsExpanded] = useState(defaultExpanded);
  return (
    <view
      data-name="switchable"
      data-direction="column"
      data-align="start"
      data-expanded={isExpanded}
      {...rest}
    >
      <Ripple>
        <group
          data-name="switch"
          data-wrap="no"
          data-border=""
          data-index="2"
          data-interactive=""
          data-cursor="pointer"
          data-space="10"
          data-align="center"
          data-gap="5"
          onClick={() => setIsExpanded((prev) => !prev)}
          {...togglerProps}
        >
          <icon data-length="30" data-index="1">
            {icon ?? "apps"}
          </icon>

   {title && (
  <>
    <text data-ellipsis="" data-index="1" data-weight="700">
      {title}
    </text>
  </>
)}
        </group>
      </Ripple>
      <view data-scroll="" data-border="no" data-name="switch_content">
        {children}
      </view>
    </view>
  );
};

export default Switchable;
