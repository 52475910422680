import { HTMLAttributes, Key, ReactElement, useCallback, useRef } from 'react';
import useIntersectionObserver from '../../../../hooks/useIntersectionObserver';
import Loader from '../../loader';
import { ListViewType } from '../useListHook';

export interface ListViewBlockItem {
  key?: Key;
  field?: string;
  label?: string;
  icon?: string;
  type?: 'field' | 'action' | 'badge';
  onClick?: (props: any) => void;
  render?: (props: any) => ReactElement;
}
export interface LazyLoadPropTypes {
  total?: number;
  loadMoreData: Function;
}

export type ListViewProps = HTMLAttributes<HTMLDivElement> & {
  //@ts-ignore
  dataSource?: Record<Key, any>[];
  itemComponent: React.FC<{ data: any; }>;
  view?: ListViewType;
  scrollerProps?: Record<string, any>;
  listProps?: Record<string, any>;
  lazyLoadProps?: LazyLoadPropTypes;
} & ({
  keyField: Key;
  getKey?: never;
} | {
  keyField?: never;
  getKey: (data: any) => Key;
});

const ListView: React.FC<ListViewProps> = ({
  keyField,
  dataSource,
  itemComponent,
  view,
  scrollerProps,
  listProps,
  lazyLoadProps,
  getKey,
  ...rest
}) => {
  const loaderRef = useRef(null);
  const handler = useCallback((ratio: number) => ratio > 0 && lazyLoadProps?.loadMoreData(), [lazyLoadProps?.loadMoreData]);
  useIntersectionObserver(loaderRef, !!lazyLoadProps && handler, '1000px');
  const ItemComponent = itemComponent;
  return (
    <div className="list_view_container" view={view} {...rest}>
      <group className="list_view_scroller" {...scrollerProps}>
        <div className="list_view" {...listProps}>
          {dataSource?.map((item) => (
            <div
              className="item"
              //@ts-ignore
              key={keyField ? item[keyField] : getKey!(item)}
            >
              <ItemComponent data={item} />
            </div>
          ))}
        </div>
        {(lazyLoadProps?.total || 0) > (dataSource?.length || 0) && (
          <>
            <space data-height="30"></space>
            <group data-space="20" data-contain="">
              <Loader ref={loaderRef} />
            </group>
          </>
        )}
      </group>
    </div>
  );
};

export default ListView;
