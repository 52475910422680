// project modules
import { getBaseUrl, sendRequest } from '../helpers/apiHelper';

// project models
import { accountDeviceBlockViewModel, accountDeviceExcludViewModel } from '../models/types/accountDevice';

//#region Accounts

export const getAccountsKiboshDevicesAsync = async (abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Admin/Kibosh/Device/Accounts`, 'get', null, null, abortSignal);
};

//#endregion

//#region Device

export const blockClientDevice = async (deviceId: string, data: accountDeviceBlockViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/${deviceId}/Block`, 'put', data, null, abortSignal);
};

export const deleteClientDeviceStaticIp = async (kiboshDeviceReference: string, deviceId: string , abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/${deviceId}/StaticIP?kiboshDeviceReference=${kiboshDeviceReference}`, 'delete', null, null, abortSignal);
};

export const excludeClientDevice = async (deviceId: string, data: accountDeviceExcludViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/${deviceId}/Exclude`, 'put', data, null, abortSignal);
};

export const getClientDevices = async (kiboshDeviceReference: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/List?kiboshDeviceReference=${kiboshDeviceReference}`, 'get', null, null, abortSignal);
};

export const getClientDeviceById = async (kiboshDeviceReference: string, deviceId: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/${deviceId}?kiboshDeviceReference=${kiboshDeviceReference}`, 'get', null, null, abortSignal);
};

export const updateClientDevice = async (deviceId: string, data: any, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/${deviceId}`, 'put', data, null, abortSignal);
};

//#endregion

//#region Filters

export const getClientDeviceFilters = async (kiboshDeviceReference: string, deviceId: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/Filter/${deviceId}/List?kiboshDeviceReference=${kiboshDeviceReference}`, 'get', null, null, abortSignal);
};

export const insertClientDeviceFilter = async (kiboshDeviceReference: string, deviceId: string, data: any, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/Filter/${deviceId}?kiboshDeviceReference=${kiboshDeviceReference}`, 'post', data, null, abortSignal);
};

export const updateClientDeviceFilter = async (kiboshDeviceReference: string, filterId: number, data: any, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/Filter/${filterId}?kiboshDeviceReference=${kiboshDeviceReference}`, 'put', data, null, abortSignal);
};

export const deleteClientDeviceFilter = async (kiboshDeviceReference: string, deviceId: string, filterId: number, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/${deviceId}/Filter/${filterId}?kiboshDeviceReference=${kiboshDeviceReference}`, 'delete', null, null, abortSignal);
};

//#endregion

//#region Timezone

export const getTimezones = async (kiboshDeviceReference: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Kibosh/Device/Timezone/All?kiboshDeviceReference=${kiboshDeviceReference}`, 'get', null, null, abortSignal);
};

export const getPreferredTimezone = async (kiboshDeviceReference: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Kibosh/Device/Timezone?kiboshDeviceReference=${kiboshDeviceReference}`, 'get', null, null, abortSignal);
};

export const updatePreferredTimezone = async (kiboshDeviceReference: string, timezone: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Kibosh/Device/Timezone?kiboshDeviceReference=${kiboshDeviceReference}&timezone=${timezone}`, 'post', null, null, abortSignal);
};

//#endregion

//#region Activity

export const getDeviceActivityDays = async (kiboshDeviceReference: string, deviceId: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/Activity/${deviceId}/List?kiboshDeviceReference=${kiboshDeviceReference}`, 'get', null, null, abortSignal);
};

export const getDeviceActivities = async (kiboshDeviceReference: string, deviceId: string, day?: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/Activity/${deviceId}/${day}?kiboshDeviceReference=${kiboshDeviceReference}`, 'get', null, null, abortSignal);
};

export const getDeviceActivitiesByDate = async (kiboshDeviceReference: string, deviceId: string, startTime?: string, endTime?: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/Activity/${deviceId}/${startTime}/${endTime}?kiboshDeviceReference=${kiboshDeviceReference}`, 'get', null, null, abortSignal);
};

export const getDeviceActivityLogEnableState = async (kiboshDeviceReference: string, deviceId: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/Activity/${deviceId}/Enabled?kiboshDeviceReference=${kiboshDeviceReference}`, 'get', null, null, abortSignal);
};

export const updateDeviceActivityLogEnableState = async (kiboshDeviceReference: string, deviceId: string, enable: boolean, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/Activity/${deviceId}/Enabled?kiboshDeviceReference=${kiboshDeviceReference}`, 'post', enable, null, abortSignal);
};

//#endregion

//#region Bandwidth

export const getDeviceBandwidthDays = async (kiboshDeviceReference: string, deviceId: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/Bandwidth/${deviceId}/List?kiboshDeviceReference=${kiboshDeviceReference}`, 'get', null, null, abortSignal);
};

export const getDeviceBandwidths = async (kiboshDeviceReference: string, deviceId: string, day?: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/Bandwidth/${deviceId}/${day}?kiboshDeviceReference=${kiboshDeviceReference}`, 'get', null, null, abortSignal);
};

export const getDeviceBandwidthsByDate = async (kiboshDeviceReference: string, deviceId: string, startTime?: string, endTime?: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/Bandwidth/${deviceId}/${startTime}/${endTime}?kiboshDeviceReference=${kiboshDeviceReference}`, 'get', null, null, abortSignal);
};

export const getDeviceLatestBandwidths = async (kiboshDeviceReference: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/Bandwidth/latest?kiboshDeviceReference=${kiboshDeviceReference}`, 'get', null, null, abortSignal);
};

//#endregion

//#region Schedule

export const getDeviceSchedules = async (kiboshDeviceReference: string, deviceId: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/Schedule/${deviceId}/List?kiboshDeviceReference=${kiboshDeviceReference}`, 'get', null, null, abortSignal);
};

export const insertDeviceSchedule = async (deviceId: string, data: any, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/Schedule/${deviceId}`, 'post', data, null, abortSignal);
};

export const updateDeviceSchedule = async (scheduleId: string, data: any, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/Schedule/${scheduleId}`, 'put', data, null, abortSignal);
};

export const deleteDeviceSchedule = async (kiboshDeviceReference: string, deviceId: string, scheduleId: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Device/${deviceId}/Schedule/${scheduleId}?kiboshDeviceReference=${kiboshDeviceReference}`, 'delete', null, null, abortSignal);
};

//#endregion

//#region Kibosh Device

export const getKiboshDevices = async (abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Admin/Kibosh/Device`, 'get', null, null, abortSignal);
};

export const resetAccountKiboshDevicePassword = async (kiboshDeviceReference: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Admin/Kibosh/Device/ResetPassword?kiboshDeviceReference=${kiboshDeviceReference}`, 'post', null, null, abortSignal);
};

export const insertNewKiboshDeviceIds = async (deviceReferences: string[], accountId?: number, devieType: string = 'router', abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Admin/Kibosh/Device`, 'post', { deviceReferences, accountId, devieType }, null, abortSignal);
};

//#endregion

//#region Port Forwarding

export const getPortForwardings = async (kiboshDeviceReference: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Kibosh/Device/PortForward/List?kiboshDeviceReference=${kiboshDeviceReference}`, 'get', null, null, abortSignal);
};

export const insertPortForwarding = async (data: any, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Kibosh/Device/PortForward`, 'post', data, null, abortSignal);
};

export const updatePortForwarding = async (portForwardingId: string, data: any, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Kibosh/Device/PortForward/${portForwardingId}`, 'put', data, null, abortSignal);
};

export const deletePortForwarding = async (kiboshDeviceReference: string, portForwardingId: string,  abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Kibosh/Device/PortForward/${portForwardingId}?kiboshDeviceReference=${kiboshDeviceReference}`, 'delete', null, null, abortSignal);
};

//#endregion

//#region Kibosh Device Status

export const getKiboshDeviceStatus = async (kiboshDeviceReference: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Kibosh/Device/Status?kiboshDeviceReference=${kiboshDeviceReference}`, 'get', null, null, abortSignal);
};

export const updateKiboshDeviceStatus = async (kiboshDeviceReference: string, clearSettings: boolean, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Kibosh/Device/Upgrade?kiboshDeviceReference=${kiboshDeviceReference}&clearSettings=${clearSettings}`, 'post', null, null, abortSignal);
};

//#endregion

//#region Wifi Settings

export const getKiboshDeviceWifiConfig = async (kiboshDeviceReference: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Kibosh/Device/Wifi?kiboshDeviceReference=${kiboshDeviceReference}`, 'get', null, null, abortSignal);
};

export const updateKiboshDeviceWifiConfig = async (data: any, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Kibosh/Device/Wifi`, 'post', data, null, abortSignal);
};

//#endregion

//#region Admin Filter Sets
export const getAdminFilterSets = async (abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Admin/FilterSet`, 'get', null, null, abortSignal);
};

export const insertAdminFilterSet = async (data: any, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Admin/FilterSet`, 'post', data, null, abortSignal);
};

export const updateAdminFilterSet = async (setId: string, data: any, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Admin/FilterSet/${setId}`, 'put', data, null, abortSignal);
};

export const bulkUpdateAdminFilterSet = async (data: any[], abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Admin/FilterSet`, 'put', data, null, abortSignal);
};

export const deleteAdminFilterSet = async (setId: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Admin/FilterSet/${setId}`, 'delete', null, null, abortSignal);
};
//#endregion

//#region Admin Filter Set Elements
export const getAdminFilterSetElements = async (setId: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Admin/FilterSet/${setId}`, 'get', null, null, abortSignal);
};

export const insertAdminFilterSetElement = async (setId: string, data: any, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Admin/FilterSet/${setId}`, 'post', data, null, abortSignal);
};

export const updateAdminFilterSetElement = async (setId: string, id: string, data: any, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Admin/FilterSet/${setId}/${id}`, 'put', data, null, abortSignal);
};

export const deleteAdminFilterSetElement = async (setId: string, id: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Admin/FilterSet/${setId}/${id}`, 'delete', null, null, abortSignal);
};
//#endregion

//#region Groups/Profiles

export const getDeviceGroups = async (kiboshDeviceReference: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Group/List?kiboshDeviceReference=${kiboshDeviceReference}`, 'get', null, null, abortSignal);
};

export const getDeviceGroupById = async (kiboshDeviceReference: string, groupId:string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Group/${groupId}?kiboshDeviceReference=${kiboshDeviceReference}`, 'get', null, null, abortSignal);
};

export const insertDeviceGroup = async (data?: any, sourceDeviceId?: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Group${sourceDeviceId ? ("/SourceClient/" + sourceDeviceId) : ""}`, 'post', data, null, abortSignal);
};

export const updateDeviceGroup = async (groupId: string, data: any, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Group/${groupId}`, 'put', data, null, abortSignal);
};

export const deleteDeviceGroup = async (kiboshDeviceReference: string, groupId: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Group/${groupId}?kiboshDeviceReference=${kiboshDeviceReference}`, 'delete', null, null, abortSignal);
};

export const addDeviceToDeviceGroup = async (groupId: string, deviceId: string, data?: any, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Group/${groupId}/Add/${deviceId}`, 'post', data, null, abortSignal);
};

export const removeDeviceFromDeviceGroup = async (kiboshDeviceReference: string, groupId: string, deviceId: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/Dash/Client/Group/Remove/${deviceId}?kiboshDeviceReference=${kiboshDeviceReference}`, 'delete', null, null, abortSignal);
};

//#endregion
