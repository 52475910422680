// 3rd-party modules
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

// project modules
import { checkUserAccess } from '../helpers/authHelper';
import { RootState } from '../stores';

// models
import { User } from "../models/auth";

// defines
import Config from "../config";

export function useAuthorize(roles: string[] | undefined = undefined) {
  const authToken = useSelector((state: RootState) => state.user.authToken);
  const user = useSelector((state: RootState) => state.user.currentUser) as User;
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!authToken) {
      navigate(`${Config.loginPageUrl}?returnUrl=${location.pathname}`);
    }

    if (roles && !checkUserAccess(user, roles)) {
      navigate(Config.loginPageUrl);
    }
  });
}
