import React from 'react';
import Button from '../../components/shared/button';
import ListView from '../../components/shared/list/listView';
import {TopToolbar} from '../../components/shared/list/topToolbar';
import {ListViewType, useList} from '../../components/shared/list/useListHook';
import {Text} from '../../components/shared/text';
import BackImage from '../../assets/images/listViewBack.jpg';

type DemoItem = {
  key: number;
  name: string;
  age: number;
  address: string;
  description: string;
};
const dataSource: DemoItem[] = [];

for (let i = 1; i <= 15; i++) {
  dataSource.push({
    key: i,
    name: `John Brown ${i}`,
    age: Number(`${i}2`),
    address: `${i}720 Goodrich Ct Platteville, Colorado`,
    description: `Lorem Ipsum is simply dummy text of the printing and
                typesetting industry. Lorem Ipsum has been the
                industry's standard dummy`,
  });
}

const BlockItem: React.FC<{data: DemoItem}> = () => (
  <group data-direction="column" data-border="" data-radius="15">
    <group data-ratio="1:1" data-space="10" data-shrink="no">
      <picture data-radius="5" data-mask="">
        <img src={BackImage} alt="" />
      </picture>
    </group>
    <group data-gap="5" data-space="10" data-align="center">
      <Button mini material icon="arrow_outward" />
      <separator vertical=""></separator>
      <Button material text="Reject" />
    </group>
  </group>
);

const ListItem: React.FC<{data: DemoItem}> = ({data}) => (
  <group data-align="center" data-space="10" data-gap="10" data-wrap="no">
    <group data-ratio="1:1" data-length="60" data-shrink="no">
      <picture data-radis>
        <img src={BackImage} alt="" />
      </picture>
    </group>
    <group data-direction="column" data-contain="">
      <Text>{data.name} </Text>
      <Text data-ellipsis="">{data.description}</Text>
    </group>
    <separator vertical=""></separator>
    <group
      data-align="center"
      data-wrap="no"
      data-position="right"
      data-width="auto"
    >
      <Button mini rounded>
        <icon data-icon-weight="300">more_vert</icon>
      </Button>
    </group>
  </group>
);

const GridItem: React.FC<{data: DemoItem}> = ({data}) => (
  <group data-align="center" data-space="10" data-gap="5" data-wrap="no">
    <group data-ratio="1:1" data-length="60" data-shrink="no">
      <picture>
        <img src={BackImage} alt="" />
      </picture>
    </group>
    <group data-direction="column" data-contain="">
      <Text>
        {data.name} {data.key}
      </Text>
      <Text data-ellipsis="">{data.description}</Text>
    </group>
    <separator vertical=""></separator>
    <group
      data-align="center"
      data-wrap="no"
      data-position="right"
      data-width="auto"
    >
      <Button mini rounded>
        <icon data-icon-weight="300">more_vert</icon>
      </Button>
    </group>
  </group>
);

const ListViewDemo: React.FC = () => {
  const {toolbarProps} = useList({
    viewTypes: [ListViewType.Block, ListViewType.List, ListViewType.Grid],
    defaultViewType: ListViewType.Grid,
  });
  return (
    <view data-vertical="" data-adaptive="">
      <view data-vertical="true" data-space="30" data-background="highlight">
        <group
          data-border=""
          data-radius="10"
          data-contain=""
          data-background="main-background"
        >
          <TopToolbar {...toolbarProps} />
          {toolbarProps.viewType === ListViewType.Block && (
            <ListView
              dataSource={dataSource}
              view={ListViewType.Block}
              keyField="key"
              data-template="150"
              scrollerProps={{'data-space': '10'}}
              listProps={{'data-gap': '10'}}
              itemComponent={BlockItem}
            />
          )}
          {toolbarProps.viewType === ListViewType.Grid && (
            <ListView
              view={ListViewType.Grid}
              dataSource={dataSource}
              keyField="key"
              data-space="10"
              itemComponent={GridItem}
            />
          )}
          {toolbarProps.viewType === ListViewType.List && (
            <ListView
              view={ListViewType.List}
              dataSource={dataSource}
              keyField="key"
              data-space="5"
              listProps={{'data-space': '5', 'data-gap': '10'}}
              itemComponent={ListItem}
            />
          )}
        </group>
      </view>
      <view data-space="30"></view>
    </view>
  );
};
export default ListViewDemo;
