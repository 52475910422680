// 3rd-party modules
import { message } from 'antd';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";

// project modules
import { apiCall } from "../../../helpers/apiHelper";
import AccountDeviceActivityLogs from '../device-activity-logs/AccountDeviceActivityLogs';
import AccountDeviceBandwidthLogs from '../device-bandwidth-logs/AccountDeviceBandwidthLogs';
import AccountBlockedSites from '../blocked-sites/AccountBlockedSites';
import AccountInternetSchedules from '../internet-schedules/AccountInternetSchedules';
import Button from "../../shared/button";
import Checkbox from '../../shared/inputs/checkbox';
import { convertToSnakecase } from '../../../helpers/objectHelper';
import ConfirmationPopup from '../../shared/popup/confirmationPopup';
import { getDeviceSignalRateClassIcon } from '../../../helpers';
import Input from "../../shared/inputs/input";
import Loader from "../../shared/loader";
import Popup from '../../shared/popup/popup';
import TabStrip from '../../shared/tabstrip';
import Tab from '../../shared/tabstrip/tab';
import yup from "../../../plugins/yup";

// apis
import * as AccountDeviceApi from '../../../apis/accountDeviceApi';
import * as DashApi from '../../../apis/dashApi';

// models
import { Account } from '../../../models/account';
import { AccountDevice } from '../../../models/accountDevice';
import { AccountKiboshDevice } from '../../../models/accountKiboshDevice';
import { ApiResponse } from "../../../models/response";
import { accountDeviceBlockViewModel, accountDeviceInsertViewModel } from '../../../models/types/accountDevice';
import { AccountDeviceGroup } from '../../../models/accountDeviceGroup';
import AccountDeviceGroupModal from '../device-groups/AccountDeviceGroupModal';
import AccountDeviceGroupCreationModal from '../device-groups/AccountDeviceGroupCreationModal';
import AccountDeviceBlockingModal from './AccountDeviceBlockingModal';
import moment from 'moment-timezone';
import { CONSTANTS } from '../../../helpers/defines';

type Props = {
  account: Account;
  accountDevice: AccountDevice;
  accountKiboshDevice: AccountKiboshDevice;
  closeOnSave?: boolean;
  preSelectedTab?: string;
  open: boolean;
  onClose?: () => void;
  onSave?: (accountDevice?: any) => void;
  onGroupSave?: () => void;
};

// const getDeviceTypes = async (abortSignal?: AbortSignal) => {
//   const response = await apiCall(CommonValueApi.getDeviceTypes(abortSignal));

//   return response.success ? CommonValue.toArrayOfClass(response.data?.value || []) : [];
// };

export default function AccountDeviceModal({ account, accountDevice, accountKiboshDevice, preSelectedTab, closeOnSave = false, open, onClose, onSave, onGroupSave }: Props) {
  const schema = yup.object().shape({
    name: yup.string().label("Device Name").max(256).required(),
    staticIp: yup.string().label("Device Static IP").max(16).nullable()
  });
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [isClearStaticIPConfirmationPopupOpen, setIsClearStaticIPConfirmationPopupOpen] = useState(false);
  // const [blockingData, setBlockingDate] = useState<accountDeviceBlockViewModel | null>(null);
  const [internetData, setInternetData] = useState<accountDeviceBlockViewModel | null>(null);
  // const [isBlockingConfirmationPopupOpen, setBlockingConfirmationPopupOpen] = useState(false);
  const [isHasInternetConfirmationPopupOpen, setHasInternetConfirmationPopupOpen] = useState(false);
  // const [isUnblocked, setIsUnblocked] = useState<boolean>(false);
  const [hasInternet, setHasInternet] = useState<boolean>(false);
  const [showAccountDeviceGroupModal, setShowAccountDeviceGroupModal] = useState(false);
  const [showAccountDeviceGroupCreationModal, setShowAccountDeviceGroupCreationModal] = useState(false);
  const [currentAccountDeviceGroup, setCurrentAccountDeviceGroup] = useState<AccountDeviceGroup | null>(null);
  const [currentAccountDevice, setCurrentAccountDevice] = useState<AccountDevice>(accountDevice);
  const [deviceGroupName, setDeviceGroupName] = useState<string>("");

  const [accountDeviceGroupLoading, setAccountDeviceGroupLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const { control, handleSubmit, setValue } = useForm<accountDeviceInsertViewModel | any>({
    defaultValues: {...currentAccountDevice},
    resolver: yupResolver(schema),
  });

  const abortController = new AbortController();

  useEffect(() => {
    if (open) {
      if (preSelectedTab) {
        switch (preSelectedTab) {
          case 'details':
            setCurrentTab(0)
            break;
          case 'blocked-sites':
            setCurrentTab(1)
            break;
          case 'internet-schedules':
            setCurrentTab(2)
            break;
          case 'activity-logs':
            setCurrentTab(3)
            break;
          case 'bandwidth-logs':
            setCurrentTab(4)
            break;
        }
      }
    }
  }, [open]);

  useEffect(() => {
    setHasInternet(!currentAccountDevice.isBlocked);
    // setIsUnblocked(!accountDevice.isBlocked);
    if (currentAccountDevice.groupId) {
      getAccountDeviceGroupAsync();
    }
  }, [currentAccountDevice]);

  useEffect(() => {
    setDeviceGroupName(currentAccountDeviceGroup?.name || "")
  }, [currentAccountDeviceGroup]);

  const onCancel = () => {
    abortController.abort();

    if(onClose) onClose();
  };

  const onSubmit: SubmitHandler<accountDeviceInsertViewModel> = async (formData: accountDeviceInsertViewModel) => {
    let response: ApiResponse;

    setLoading(true);

    formData.kiboshDeviceReference = accountKiboshDevice.kiboshDeviceReference!;
    const data = convertToSnakecase(formData, [], ["kiboshDeviceReference"]);

    if (!currentAccountDevice?.id)
      response = await apiCall(AccountDeviceApi.insertAccountDevice(formData, abortController.signal));
    else
      response = await apiCall(DashApi.updateClientDevice(currentAccountDevice.id, data, abortController.signal));

    if (response.success) {
      message.success(`Device ${!currentAccountDevice?.id ? 'added' : 'edited'} successfully.`);

      if (onSave) onSave(response.data?.value);

      if (closeOnSave) {
        open = false;

        onCancel();
      }

    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  // const onBlockingCheckboxChange = async (event: any) => {
  //   const data: accountDeviceBlockViewModel = {
  //     blocked: !event.target?.checked,
  //     deviceId: currentAccountDevice.id!,
  //     kiboshDeviceReference: accountKiboshDevice.kiboshDeviceReference!,
  //     minutesBeforeExpiration: 0
  //   }
  //   setBlockingDate(data);
  //   setBlockingConfirmationPopupOpen(true);
  // }

  // const handleBlocking = async () => {
  //   setLoading(true);

  //   let response: ApiResponse = await apiCall(DashApi.blockClientDevice(currentAccountDevice.id!, blockingData!, abortController.signal));

  //   if (response.success) {
  //     message.success(`Device blocked status changed successfully.`);
  //     setIsUnblocked(!blockingData?.blocked!);
  //     setBlockingConfirmationPopupOpen(false);

  //     if (onSave) onSave(response.data?.value);
  //   } else
  //     message.error(response.error?.value);

  //   setLoading(false);
  // }

  const onInternetCheckboxChange = async (event: any) => {
    const data: accountDeviceBlockViewModel = {
      blocked: !event.target?.checked,
      deviceId: currentAccountDevice.id!,
      kiboshDeviceReference: accountKiboshDevice.kiboshDeviceReference!,
      minutesBeforeExpiration: 0
    }
    setInternetData(data);
    setHasInternetConfirmationPopupOpen(true);
  }

  // const handleInternetCheckboxChange = async () => {
  //   setLoading(true);

  //   let response: ApiResponse = await apiCall(DashApi.blockClientDevice(currentAccountDevice.id!, internetData!, abortController.signal));

  //   if (response.success) {
  //     message.success(`Device internet status changed successfully.`);
  //     setHasInternet(!internetData!.blocked);
  //     setHasInternetConfirmationPopupOpen(false);

  //     if (onSave) onSave(response.data?.value);
  //   } else
  //     message.error(response.error?.value);

  //   setLoading(false);
  // }

  const onClearStaticIpClick = () => {
    setIsClearStaticIPConfirmationPopupOpen(true)
  }

  const handleClearStaticIp = async () => {
    setLoading(true);

    let response: ApiResponse = await apiCall(DashApi.deleteClientDeviceStaticIp(accountKiboshDevice.kiboshDeviceReference!, currentAccountDevice.id!, abortController.signal));

    if (response.success) {
      message.success(`Device Static IP deleted successfully.`);
      setValue("staticIp", "");
      setIsClearStaticIPConfirmationPopupOpen(false);

      if (onSave) onSave(response.data?.value);
    } else
      message.error(response.error?.value);

    setLoading(false);
  }

  const getAccountDevice = async () => {
    if (!accountKiboshDevice.kiboshDeviceReference || !currentAccountDevice.id) {
      return;
    }

    setLoading(true);
    const response = await apiCall(DashApi.getClientDeviceById(accountKiboshDevice.kiboshDeviceReference!, currentAccountDevice.id!, abortController.signal));
    setLoading(false);

    if (response.success) {
      const data = response.data?.value?.length ? response.data?.value?.filter((x: any) => x.id == currentAccountDevice.id) : response.data?.value;

      return AccountDevice.toClass(data.length ? data[0] : data);
    }
    return currentAccountDevice;

  };

  const onAccountDeviceGroupSave = async (e?: AccountDeviceGroup) => {
    getAccountDeviceGroupAsync();
    setCurrentAccountDevice(await getAccountDevice() || currentAccountDevice);
    if (onSave) {
      onSave();
    }
    if (onGroupSave) {
      onGroupSave();
    }
  }

  const onAccountDeviceBlockingSave = async () => {
    setHasInternet(!hasInternet)
    const data = await getAccountDevice() || currentAccountDevice
    console.log(data)
    setCurrentAccountDevice(data);
    if (onSave) {
      onSave();
    }
  }

  const onEditGroupClick = () => {
    setShowAccountDeviceGroupModal(true);
  };

  const onCreateGroupClick = () => {
    setShowAccountDeviceGroupCreationModal(true);
  };

  const getAccountDeviceGroupAsync = async () => {
    setCurrentAccountDeviceGroup(await getAccountDeviceGroup() || null);
  }

  const getAccountDeviceGroup = async () => {
    if (!accountKiboshDevice.kiboshDeviceReference || !currentAccountDevice.groupId) {
      return;
    }

    setAccountDeviceGroupLoading(true);
    const response = await apiCall(DashApi.getDeviceGroupById(accountKiboshDevice.kiboshDeviceReference!, currentAccountDevice.groupId!, abortController.signal));
    setAccountDeviceGroupLoading(false);

    return response.success ? AccountDeviceGroup.toClass(response.data?.value) : null;
  };

  const renderDetailsTab = () => {
    return (
      <group>
        {accountDeviceGroupLoading && <Loader/>}
        <group data-direction="column" width='auto' data-border="">
            <group data-space="15" data-gap="10" data-direction="column">
              <Input
                control={control}
                label="Device Name"
                dataLength="auto"
                size="large"
                name="name"
              />
              <Input
                control={control}
                label="Device Static IP"
                dataLength="auto"
                size="large"
                name="staticIp"
                button={
                  <Button primary material  onClick={onClearStaticIpClick}>
                    <text>Clear Static IP</text>
                  </Button>
                }
              />
            </group>
            <group
              data-space="10"
              data-align="center"
              data-gap="10"
              data-border="top"
            >
              <Button  material primary onClick={handleSubmit(onSubmit)}>
                <text>Save Changes</text>
              </Button>
              {/* <Button large material outline onClick={onClose}>
                <text>Cancel</text>
              </Button> */}
            </group>
            <group
              data-space="10"
              data-align="center"
              data-gap="10"
              data-border="top"
            >
              <Input
                label="Profile Membership"
                dataLength="auto"
                size="large"
                value={deviceGroupName}
                disabled
                button={
                  <Button primary material disabled={!currentAccountDeviceGroup ? true : undefined} onClick={onEditGroupClick}>
                    <text>Edit Profile</text>
                  </Button>
                }
              />
            </group>
            <group
              data-space="10"
              data-align="center"
              data-gap="10"
            >
              <Button  material primary icon="add" disabled={currentAccountDeviceGroup ? true : undefined} onClick={onCreateGroupClick}>
                <text>Create a new Profile</text>
              </Button>
              {/* <Button large material outline onClick={onClose}>
                <text>Cancel</text>
              </Button> */}
            </group>
          </group>
        <group data-width="auto">
          <group data-space="15" data-gap="20" data-direction="column">
            <group data-direction="column">
              <group data-justify='center'>
                <icon large='' data-color='main'>{getDeviceSignalRateClassIcon(currentAccountDevice.pingMs!)}</icon>
              </group>
              <group data-justify='center'>
                <text>{currentAccountDevice?.pingMs! < 0 ? "Disconnected" : `Latency: ${currentAccountDevice?.pingMs?.toFixed(2)} ms`}</text>
              </group>
            </group>
            <group data-direction="column">
              <Checkbox
                label={hasInternet ? "Internet Enabled for device" : `Internet Disabled for device until ${moment(currentAccountDevice.blockedUntil).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}`}
                minimal
                dataLength="auto"
                disabled={!!currentAccountDeviceGroup}
                onChange={onInternetCheckboxChange}
                checked={hasInternet}/>
              {/* <Checkbox
                label="Blocking Enabled for Device"
                minimal
                dataLength="auto"
                onChange={onBlockingCheckboxChange}
                checked={isUnblocked}/> */}
            </group>
          </group>
        </group>
      </group>
    );
  }

  const onStepSave = (acc: Account, step: string) => {
    switch (step) {
      default:
        break;
    }

    // if (onSave) {
    //   onSave(account);
    // }
  };

  return (
    <Popup
      title={`Account: ${account.accountName} - Kibosh Device: ${accountKiboshDevice.kiboshDeviceName} - ${(!currentAccountDevice.id ? "New Device" : `Device Name: ${currentAccountDevice.name}`)}`}
      onCancel={onCancel}
      onClose={onCancel}
      noCommandbar={true}
      fixSize="large"
    >
      { loading &&
        <Loader />
      }
      <TabStrip
        skipSecondaryTabs={false}
        selectedIndex={currentTab}
        onChange={setCurrentTab}
        id="device-tab">
        <Tab title='Details'>
          {renderDetailsTab()}
        </Tab>
        <Tab title='Blocked Sites' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference || !currentAccountDevice.id || !!currentAccountDevice.groupId}>
          {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference && !!currentAccountDevice.id && !currentAccountDevice.groupId &&
            <AccountBlockedSites refresh={!!account.accountId} account={account} kiboshDeviceReference={accountKiboshDevice.kiboshDeviceReference} deviceReference={currentAccountDevice.id} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'blockedSites')} />
          }
        </Tab>
        <Tab title='Internet Schedules' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference || !currentAccountDevice.id || !!currentAccountDevice.groupId}>
          {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference && !!currentAccountDevice.id && !currentAccountDevice.groupId &&
            <AccountInternetSchedules refresh={!!account.accountId} account={account} kiboshDeviceReference={accountKiboshDevice.kiboshDeviceReference} deviceReference={currentAccountDevice.id} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'internetSchedules')} />
          }
        </Tab>
        <Tab title='Activity Logs' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference || !currentAccountDevice.id}>
          {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference && !!currentAccountDevice.id &&
            <AccountDeviceActivityLogs refresh={!!account.accountId} account={account} kiboshDeviceReference={accountKiboshDevice.kiboshDeviceReference} deviceReference={currentAccountDevice.id} hasUpperLevelControl={!!currentAccountDeviceGroup} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'activityLogs')} />
          }
        </Tab>
        <Tab title='Bandwidth Logs' disabled={!account.accountId || !accountKiboshDevice.kiboshDeviceReference || !currentAccountDevice.id}>
          {!!account.accountId && !!accountKiboshDevice.kiboshDeviceReference && !!currentAccountDevice.id &&
            <AccountDeviceBandwidthLogs refresh={!!account.accountId} account={account} kiboshDeviceReference={accountKiboshDevice.kiboshDeviceReference} deviceReference={currentAccountDevice.id} onCancel={onCancel} onSave={(acc) => onStepSave(acc, 'bandwidthLogs')} />
          }
        </Tab>
      </TabStrip>
      {/* {isBlockingConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText={blockingData?.blocked ? "Unblock" : "Block"}
          positiveCallback={handleBlocking}
          negativeCallback={() => {
            setBlockingConfirmationPopupOpen(false);
          }}
        />
      )} */}
      {isClearStaticIPConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Clear Static IP"
          positiveCallback={handleClearStaticIp}
          negativeCallback={() => {
            setIsClearStaticIPConfirmationPopupOpen(false);
          }}
        />
      )}
      {/* {isHasInternetConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText={internetData?.blocked ? "Disable Internet" : "Enabled Internet"}
          positiveCallback={handleInternetCheckboxChange}
          negativeCallback={() => {
            setHasInternetConfirmationPopupOpen(false);
          }}
        />
      )} */}
      {!!showAccountDeviceGroupCreationModal && (
        <AccountDeviceGroupCreationModal
          open={showAccountDeviceGroupCreationModal}
          closeOnSave={true}
          accountDevice={currentAccountDevice!}
          kiboshDeviceReference={accountKiboshDevice.kiboshDeviceReference!}
          onClose={() => setShowAccountDeviceGroupCreationModal(false)}
          onSave={(e) => onAccountDeviceGroupSave(e)}
        />
      )}
      {!!showAccountDeviceGroupModal && (
        <AccountDeviceGroupModal
          open={showAccountDeviceGroupModal}
          account={account!}
          accountDeviceGroup={currentAccountDeviceGroup!}
          accountKiboshDevice={accountKiboshDevice}
          preSelectedTab=""
          onClose={() => setShowAccountDeviceGroupModal(false)}
          onSave={(e) => onAccountDeviceGroupSave(e)}
        />
      )}
      {!!isHasInternetConfirmationPopupOpen && (
        <AccountDeviceBlockingModal
          open={isHasInternetConfirmationPopupOpen}
          accountDeviceId={currentAccountDevice.id!}
          accountDeviceName={currentAccountDevice.name!}
          blockingData={internetData!}
          closeOnSave={true}
          onClose={() => setHasInternetConfirmationPopupOpen(false)}
          onSave={onAccountDeviceBlockingSave}
        />
      )}
    </Popup>
  );
}
