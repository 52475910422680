export const CONSTANTS = {
  DEFAULT_TIME_FORMAT: "HH:mm",
  DEFAULT_DATETIME_FORMAT: "DD/MM/YYYY HH:mm",
  DEFAULT_DATE_FORMAT: "DD/MM/YYYY",
  DEFAULT_PHONE_NUMBER_MASK: "____________________"
};

export const MESSAGES = {
  MANUAL_REQUEST_CANCELLATION: "Manual request cancellation."
};

export const ENTITY_CODES = {
  SUPPORT_TICKET_ENTITY_CODE: "support-ticket",
  SUPPORT_TICKET_NOTE_ENTITY_CODE: "support-ticket-note"
}
