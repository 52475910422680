// 3rd-party modules
import moment from "moment-timezone";
import { useEffect, useState } from "react";

// project modules
import Button from "../../shared/button";
import CustomChart from "../../shared/chart/chart";
import DataTable, { TableColumn } from "../../shared/list/dataTable";
import DashboardWidget from "../../global/dashboardWidget";
import Loader from "../../shared/loader";
import Select from "../../shared/inputs/select";
import { apiCall } from "../../../helpers/apiHelper";

// apis
import * as AccountKiboshDeviceApi from '../../../apis/accountKiboshDeviceApi';
import * as DashApi from '../../../apis/dashApi';

// models
import { Account } from "../../../models/account";
import { AccountKiboshDevice } from "../../../models/accountKiboshDevice";
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from "../../../models/dataSourceRequest";
import { AccountDevice, AccountDeviceBandwidthLog } from "../../../models/accountDevice";
import { Tooltip } from "antd";

// defines
import { CONSTANTS } from "../../../helpers/defines";

type Props = {
  account?: Account;
  deviceReference?: string;
  refresh?: boolean;
  kiboshDeviceReference?: string;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
};

export default function AccountDeviceBandwidthLogs({ account, deviceReference, refresh, kiboshDeviceReference, onCancel, onSave }: Props) {
  const columns: TableColumn<AccountDeviceBandwidthLog>[] = [
    {
      title: 'Download',
      dataIndex: 'intervalDownloadBandwidth',
      key: 'intervalDownloadBandwidth',
      filterable: true,
      width: 250
    },
    {
      title: 'Upload',
      dataIndex: 'intervalUploadBandwidth',
      key: 'intervalUploadBandwidth',
      filterable: true,
      width: 250
    },
    {
      title: 'Interval End',
      dataIndex: 'intervalEnd',
      key: 'intervalEnd',
      filterable: true,
      width: 250,
      dataType: 'datetime',
      render: (text: any) => <>{moment(text).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}</>
    }
  ];
  const chartOptions: any = {
    fill: {
      colors: ['#4c9484']
    },
    colors: '#4c9484',
    chart: {
      toolbar: {
        show: false
      }
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top', // top, center, bottom
        },
      }
    },
    dataLabels: {
      enabled: false,
      formatter: function (val: any) {
        return `${Number(val).toFixed(2)} MB`;
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#304758"],
        fill: ['#000']
      }
    },

    xaxis: {
      position: 'bottom',
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      tooltip: {
        enabled: false,
      },
      tickAmount: 10,

    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: function (val: any) {
          return `${Number(val).toFixed(2)} MB`;
        }
      },
    }
  };
  const [accountKiboshDevices, setAccountKiboshDevices] = useState<AccountKiboshDevice[]>([]);
  const [currentAccountKiboshDeviceReference, setCurrentAccountKiboshDeviceReference] = useState<string>("");
  const [accountDevices, setAccountDevices] = useState<AccountDevice[]>([]);
  const [bandwidthDays, setBandwidthDays] = useState<string[]>([]);
  const [bandwidthLogs, setBandwidthLogs] = useState<AccountDeviceBandwidthLog[]>([]);
  const [currentAccountDeviceReference, setCurrentAccountDeviceReference] = useState<string>("");
  const [currentBandwidthDay, setCurrentBandwidthDay] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [loadingAccountKiboshDevices, setLoadingAccountKiboshDevices] = useState(false);
  const [loadingAccountDevices, setLoadingAccountDevices] = useState(false);
  const [loadingBandwidthDays, setLoadingBandwidthDays] = useState(false);
  const [openDownloadChartInModal, setOpenDownloadChartInModal] = useState(false);
  const [openTotalChartInModal, setOpenTotalChartInModal] = useState(false);
  const [openUploadChartInModal, setOpenUploadChartInModal] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  let abortController = new AbortController();

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  useEffect(() => {
    if (refresh) {
      if (!kiboshDeviceReference) {
        getAccountKiboshDevicesAsync();
      } else if (!deviceReference) {
        getAccountDevicesAsync();
      } else {
        getDeviceBandwidthDaysAsync();
      }
    }
  }, [refresh]);

  useEffect(() => {
    if (bandwidthDays.length > 0)
      onLogDaysChange(bandwidthDays[0]);
  }, [bandwidthDays])

  const getAccountDevicesAsync = async (routerRef?: string) => {
    setAccountDevices(await getAccountDevices(routerRef, abortController.signal));
  }

  const getAccountDevices = async (routerRef?: string, abortSignal?: AbortSignal) => {
    setLoadingAccountDevices(true);
    const response = await apiCall(DashApi.getClientDevices(kiboshDeviceReference || currentAccountKiboshDeviceReference || routerRef!, abortSignal));
    setLoadingAccountDevices(false);

    return response.success ? AccountDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const getAccountKiboshDevicesAsync = async () => {
    setAccountKiboshDevices(await getAccountKiboshDevices({}, abortController.signal));
  }

  const getAccountKiboshDevices = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
    setLoadingAccountKiboshDevices(true);
    const response = await apiCall(AccountKiboshDeviceApi.getAccountKiboshDevices(account?.accountId!, request, abortSignal));
    setLoadingAccountKiboshDevices(false);

    return response.success ? AccountKiboshDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const getDeviceBandwidthDaysAsync = async (deviceRef?: string) => {
    setBandwidthDays(await getDeviceBandwidthDays(deviceRef, abortController.signal));
  }

  const getDeviceBandwidthDays = async (deviceRef?: string, abortSignal?: AbortSignal) => {
    setLoadingBandwidthDays(true);
    const response = await apiCall(DashApi.getDeviceBandwidthDays(kiboshDeviceReference || currentAccountKiboshDeviceReference, deviceReference || currentAccountDeviceReference || deviceRef!, abortSignal));
    setLoadingBandwidthDays(false);

    return response.success ? response.data?.value || [] : [];
  };

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    if (!currentBandwidthDay)
      return [];

    setLoading(true);
    const response = await apiCall(DashApi.getDeviceBandwidths(kiboshDeviceReference || currentAccountKiboshDeviceReference, deviceReference || currentAccountDeviceReference, currentBandwidthDay, abortSignal));
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setBandwidthLogs(response.success ? AccountDeviceBandwidthLog.toArrayOfClass(response.data?.value || []) : []);
    setLoading(false);

    return response.success ? AccountDeviceBandwidthLog.toArrayOfClass(response.data?.value || []) : [];
  };

  const onAccountKiboshDeviceChange = (kiboshDeviceReference: any) => {
    setCurrentAccountKiboshDeviceReference(kiboshDeviceReference);
    getAccountDevicesAsync(kiboshDeviceReference);
  }

  const onAccountDeviceChange = (deviceReference: any) => {
    setCurrentAccountDeviceReference(deviceReference);
    getDeviceBandwidthDaysAsync(deviceReference);
  }

  const onLogDaysChange = (logDay: any) => {
    setCurrentBandwidthDay(logDay);
    setReload(true);
  }

  return (
    <>
      {loading && <Loader />}
      <view data-scroll="" >
        <group data-space="15" data-gap="15" data-border="" data-align="center">
          {(!kiboshDeviceReference || !deviceReference) && (
            <>
              {!kiboshDeviceReference && (
                <>
                  <Select
                    autoComplete=""
                    label="Kibosh Device"
                    labelPosition="left"
                    dataLength="350"
                    onChange={onAccountKiboshDeviceChange}
                    loading={loadingAccountKiboshDevices}
                    allowSearch={true}
                    options={
                      accountKiboshDevices?.map((item) => {
                        return {
                          text: `${item.kiboshDeviceName!} (${item.kiboshDeviceReference!})`,
                          value: item.kiboshDeviceReference!,
                        };
                      }) || []
                    }
                    button={
                      <Button
                        material
                        icon="refresh"
                        onClick={getAccountKiboshDevicesAsync}
                      />
                    }
                  />
                </>
              )}
              {!deviceReference && (
                <>
                  <Select
                    autoComplete=""
                    label="Device"
                    labelPosition="left"
                    dataLength="360"
                    onChange={onAccountDeviceChange}
                    loading={loadingAccountDevices}
                    allowSearch={true}
                    options={
                      accountDevices?.map((item) => {
                        return {
                          text: `${item.name!} (${item.id!})`,
                          value: item.id!,
                        };
                      }) || []
                    }
                    button={
                      <Button
                        material
                        icon="refresh"
                        onClick={getAccountDevicesAsync}
                      />
                    }
                  />
                </>
              )}
              <separator vertical="" data-height="20"></separator>
            </>
          )}
          <Select
            value={currentBandwidthDay} //?
            disabled={!deviceReference && !currentAccountDeviceReference}
            autoComplete=""
            label="Display Logs For"
            labelPosition="left"
            dataLength="320"
            onChange={onLogDaysChange}
            loading={loadingBandwidthDays}
            allowSearch={true}
            options={bandwidthDays || []}
            button={
              <Button
                disabled={!deviceReference && !currentAccountDeviceReference}
                material
                icon="refresh"
                onClick={getDeviceBandwidthDaysAsync}
              />
            }
          />
          <Button
            data-position="right"
            material
            icon="refresh"
            text="Refresh"
            outline
            onClick={() => setReload(true)}
          />
        </group>
        {!!bandwidthLogs.length && (
          <group
            data-type="grid"
            data-gap="10"
            data-space="15"
            data-grid-template="400"
            data-background="highlight"
            data-border=""
          >
            <DashboardWidget
              title="Download"
              height=""
              headerButtonsContainer={
                <>
                  <Tooltip title="Maximize">
                    <div
                      className="button micro"
                      data-position="right"
                      onClick={() => setOpenDownloadChartInModal(true)}
                    >
                      <icon>fullscreen</icon>
                    </div>
                  </Tooltip>
                </>
              }
            >
              <CustomChart
                title="Download"
                options={{
                  ...chartOptions,
                  xaxis: {
                    ...chartOptions.xaxis,
                    categories: bandwidthLogs
                      .filter((x) => x.clientMac !== "combined")
                      .map((logs: any) => {
                        return {
                          ...logs,
                          intervalEnd: new Date(logs.intervalEnd),
                        };
                      })
                      .sort(
                        (a: any, b: any) =>
                          a.intervalEnd.getTime() - b.intervalEnd.getTime()
                      )
                      .map((logs: any) =>
                        moment(logs.intervalEnd).format(
                          CONSTANTS.DEFAULT_TIME_FORMAT
                        )
                      ),
                  },
                }}
                series={[
                  {
                    name: "Download",
                    data: bandwidthLogs
                      .filter((x) => x.clientMac !== "combined")
                      .map((logs: any) => {
                        return {
                          ...logs,
                          intervalEnd: new Date(logs.intervalEnd),
                        };
                      })
                      .sort(
                        (a: any, b: any) =>
                          a.intervalEnd.getTime() - b.intervalEnd.getTime()
                      )
                      .map(
                        (logs: any) => logs.intervalDownloadBandwidth / 1048576
                      ),
                  },
                ]}
                type="line"
                width="100%"
                openInModal={openDownloadChartInModal}
                onClose={() => setOpenDownloadChartInModal(false)}
              />
            </DashboardWidget>
            <DashboardWidget
              title="Upload"
              height=""
              headerButtonsContainer={
                <>
                  <Tooltip title="Maximize">
                    <div
                      className="button micro"
                      data-position="right"
                      onClick={() => setOpenUploadChartInModal(true)}
                    >
                      <icon>fullscreen</icon>
                    </div>
                  </Tooltip>
                </>
              }
            >
              <CustomChart
                title="Upload"
                options={{
                  ...chartOptions,
                  xaxis: {
                    ...chartOptions.xaxis,
                    categories: bandwidthLogs
                      .filter((x) => x.clientMac !== "combined")
                      .map((logs: any) => {
                        return {
                          ...logs,
                          intervalEnd: new Date(logs.intervalEnd),
                        };
                      })
                      .sort(
                        (a: any, b: any) =>
                          a.intervalEnd.getTime() - b.intervalEnd.getTime()
                      )
                      .map((logs: any) =>
                        moment(logs.intervalEnd).format(
                          CONSTANTS.DEFAULT_TIME_FORMAT
                        )
                      ),
                  },
                }}
                series={[
                  {
                    name: "Upload",
                    data: bandwidthLogs
                      .filter((x) => x.clientMac !== "combined")
                      .map((logs: any) => {
                        return {
                          ...logs,
                          intervalEnd: new Date(logs.intervalEnd),
                        };
                      })
                      .sort(
                        (a: any, b: any) =>
                          a.intervalEnd.getTime() - b.intervalEnd.getTime()
                      )
                      .map(
                        (logs: any) => logs.intervalUploadBandwidth / 1048576
                      ),
                  },
                ]}
                type="bar"
                width="100%"
                openInModal={openUploadChartInModal}
                onClose={() => setOpenUploadChartInModal(false)}
              />
            </DashboardWidget>
            <DashboardWidget
              title="Total"
              height=""
              headerButtonsContainer={
                <>
                  <Tooltip title="Maximize">
                    <div
                      className="button micro"
                      data-position="right"
                      onClick={() => setOpenTotalChartInModal(true)}
                    >
                      <icon>fullscreen</icon>
                    </div>
                  </Tooltip>
                </>
              }
            >
              <CustomChart
                title="Total"
                options={{
                  ...chartOptions,
                  xaxis: {
                    ...chartOptions.xaxis,
                    categories: bandwidthLogs
                      .filter((x) => x.clientMac !== "combined")
                      .map((logs: any) => {
                        return {
                          ...logs,
                          intervalEnd: new Date(logs.intervalEnd),
                        };
                      })
                      .sort(
                        (a: any, b: any) =>
                          a.intervalEnd.getTime() - b.intervalEnd.getTime()
                      )
                      .map((logs: any) =>
                        moment(logs.intervalEnd).format(
                          CONSTANTS.DEFAULT_TIME_FORMAT
                        )
                      ),
                  },
                }}
                series={[
                  {
                    name: "Total",
                    data: bandwidthLogs
                      .filter((x) => x.clientMac !== "combined")
                      .map((logs: any) => {
                        return {
                          ...logs,
                          intervalEnd: new Date(logs.intervalEnd),
                        };
                      })
                      .sort(
                        (a: any, b: any) =>
                          a.intervalEnd.getTime() - b.intervalEnd.getTime()
                      )
                      .map(
                        (logs: any) =>
                          (Number(logs.intervalDownloadBandwidth) +
                            Number(logs.intervalUploadBandwidth)) /
                          1048576
                      ),
                  },
                ]}
                type="bar"
                width="100%"
                openInModal={openTotalChartInModal}
                onClose={() => setOpenTotalChartInModal(false)}
              />
            </DashboardWidget>
          </group>
        )}
        <group
          data-space="15"
          //data-background="highlight"
          //data-border="no"
        >
          <view  className="table_cont" data-border="no">
            <DataTable
              columns={columns}
              bordered={true}
              customPagination={true}
              reload={reload}
              rowKey={record => `${record.clientMac}-${record.intervalEnd}`}
              dataPagination={true}
              getPageAsync={getPage}
              loading={loading}
              showTotal={true}
              size="small"
              totalRecords={totalRecords}
            />
          </view>

        </group>
      </view>
    </>
  );
}
