// 3rd-party modules
import { Tooltip, message } from "antd";
import { useEffect, useState } from "react";

// project modules
import Button from "../../shared/button";
import ConfirmationPopup from "../../shared/popup/confirmationPopup";
import DataTable, { TableColumn } from "../../shared/list/dataTable";
import Loader from "../../shared/loader";
import Select from "../../shared/inputs/select";
import { apiCall } from "../../../helpers/apiHelper";

// apis
import * as AccountKiboshDeviceApi from '../../../apis/accountKiboshDeviceApi';
import * as DashApi from '../../../apis/dashApi';

// models
import AccountBlockedSiteModal from "./AccountBlockedSiteModal";
import Checkbox from "../../shared/inputs/checkbox";
import { Account } from "../../../models/account";
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from "../../../models/dataSourceRequest";
import { AccountBlockedSite } from "../../../models/accountBlockedSite";
import { AccountKiboshDevice } from "../../../models/accountKiboshDevice";
import { AccountDevice } from "../../../models/accountDevice";
import { AdminFilterSet } from "../../../models/adminFilterSet";
import { ApiResponse } from "../../../models/response";
import { accountBlockedSiteViewModel } from "../../../models/types/accountBlockedSite";
import { convertToSnakecase } from "../../../helpers/objectHelper";
import Switchable from "../../shared/additionalComponents/Switchable";

type Props = {
  account?: Account;
  deviceReference?: string;
  refresh?: boolean;
  kiboshDeviceReference?: string;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
};

export default function AccountBlockedSites({ account, deviceReference, refresh, kiboshDeviceReference, onCancel, onSave }: Props) {
  const columns: TableColumn<AccountBlockedSite>[] = [
    {
      title: 'Match',
      dataIndex: 'match',
      key: 'match',
      filterable: true,

    },
    {
      title: '',
      key: 'action',
      width: 60,
      render: (_, record: any) => (
        <group data-wrap='no' data-gap="5">
          <Tooltip title="Edit"><div className='button micro' onClick={() => onEditClick(record)}><icon>edit_square</icon></div></Tooltip>
          <Tooltip title="Delete"><div className='button micro' onClick={() => onDeleteClick(record)}><icon>delete</icon></div></Tooltip>
        </group>
      ),
    }
  ];
  const [accountKiboshDevices, setAccountKiboshDevices] = useState<AccountKiboshDevice[]>([]);
  const [currentAccountKiboshDeviceReference, setCurrentAccountKiboshDeviceReference] = useState<string>("");
  const [accountDevices, setAccountDevices] = useState<AccountDevice[]>([]);
  const [adminFilterSets, setAdminFilterSets] = useState<AdminFilterSet[]>([]);
  const [accountSetBlockedSites, setAccountSetBlockedSites] = useState<AccountBlockedSite[]>([]);
  const [currentAccountDeviceReference, setCurrentAccountDeviceReference] = useState<string>("");
  const [currentAccountBlockedSite, setCurrentAccountBlockedSite] = useState<AccountBlockedSite>(new AccountBlockedSite());
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAccountKiboshDevices, setLoadingAccountKiboshDevices] = useState(false);
  const [loadingAccountDevices, setLoadingAccountDevices] = useState(false);
  const [loadingAdminFilterSets, setLoadingAdminFilterSets] = useState(false);
  const [loadingAccountSetBlockedSites, setLoadingAccountSetBlockedSites] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  const [showAccountBlockedSiteModal, setShowAccountBlockedSiteModal] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  let abortController = new AbortController();

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  useEffect(() => {
    if (refresh) {
      if (!kiboshDeviceReference) {
        getAccountKiboshDevicesAsync();
      } else if (!deviceReference) {
        getAccountDevicesAsync();
      } else {
        getAdminFilterSetsAsync();
      }
    }
  }, [refresh]);





  const getAdminFilterSetsAsync = async () => {
    setAdminFilterSets(await getAdminFilterSets(abortController.signal));
    getAccountSetBlockedSitesAsync();
  }

  const getAdminFilterSets = async (abortSignal?: AbortSignal) => {
    setLoadingAdminFilterSets(true);
    const response = await apiCall(DashApi.getAdminFilterSets(abortSignal));
    setLoadingAdminFilterSets(false);

    return response.success ? AdminFilterSet.toArrayOfClass(response.data?.value || []) : [];
  };

  const getAccountSetBlockedSitesAsync = async () => {
    setAccountSetBlockedSites(await getAccountSetBlockedSites(abortController.signal));
  }

  const getAccountSetBlockedSites = async (abortSignal?: AbortSignal) => {
    setLoadingAccountSetBlockedSites(true);
    const response = await apiCall(DashApi.getClientDeviceFilters(kiboshDeviceReference ? kiboshDeviceReference : currentAccountKiboshDeviceReference, deviceReference ? deviceReference : currentAccountDeviceReference, abortSignal));
    setLoadingAccountSetBlockedSites(false);

    return response.success ? AccountBlockedSite.toArrayOfClass(response.data?.value || []).filter(x => x.matchType === "set") : [];
  };

  const getAccountDevicesAsync = async (routerRef?: string) => {
    const allOption = new AccountDevice();
    allOption.name = "All";
    allOption.id = "All";
    setAccountDevices([allOption, ...await getAccountDevices(routerRef, abortController.signal)]);
  }

  const getAccountDevices = async (routerRef?: string, abortSignal?: AbortSignal) => {
    setLoadingAccountDevices(true);
    const response = await apiCall(DashApi.getClientDevices(kiboshDeviceReference || currentAccountKiboshDeviceReference || routerRef!, abortSignal));
    setLoadingAccountDevices(false);

    return response.success ? AccountDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const getAccountKiboshDevicesAsync = async () => {
    setAccountKiboshDevices(await getAccountKiboshDevices({}, abortController.signal));
  }

  const getAccountKiboshDevices = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
    setLoadingAccountKiboshDevices(true);
    const response = await apiCall(AccountKiboshDeviceApi.getAccountKiboshDevices(account?.accountId!, request, abortSignal));
    setLoadingAccountKiboshDevices(false);

    return response.success ? AccountKiboshDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    if (!currentAccountDeviceReference && !deviceReference)
      return [];

    setLoading(true);
    const response = await apiCall(DashApi.getClientDeviceFilters(kiboshDeviceReference ? kiboshDeviceReference : currentAccountKiboshDeviceReference, deviceReference ? deviceReference : currentAccountDeviceReference, abortSignal));
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setLoading(false);

    return response.success ? AccountBlockedSite.toArrayOfClass(response.data?.value || []).filter(x => x.matchType !== "set") : [];
  };

  const onAccountBlockedSiteSave = (e: Account) => {
    setReload(true);
  }

  const onAccountKiboshDeviceChange = (kiboshDeviceReference: any) => {
    setCurrentAccountKiboshDeviceReference(kiboshDeviceReference);
    getAccountDevicesAsync(kiboshDeviceReference);
  }

  const onAccountDeviceChange = (deviceReference: any) => {
    setCurrentAccountDeviceReference(deviceReference);
    getAdminFilterSetsAsync();
    setReload(true);
  }

  const onEditClick = (accountBlockedSite: AccountBlockedSite) => {
    setCurrentAccountBlockedSite({ ...accountBlockedSite });
    setShowAccountBlockedSiteModal(true);
  };

  const onNewClick = () => {
    setCurrentAccountBlockedSite(new AccountBlockedSite());
    setShowAccountBlockedSiteModal(true);
  };

  const onDeleteClick = (accountBlockedSite: AccountBlockedSite) => {
    setCurrentAccountBlockedSite(accountBlockedSite);
    setIsDeleteConfirmationPopupOpen(true);
  };

  const handleDeleteRequest = async () => {
    setLoading(true);
    const response = await apiCall(DashApi.deleteClientDeviceFilter(kiboshDeviceReference || currentAccountKiboshDeviceReference!, deviceReference || currentAccountDeviceReference, currentAccountBlockedSite.id!));
    setLoading(false);

    if (response.success) {
      message.success(`Account Blocked Site deleted successfully.`);
      setIsDeleteConfirmationPopupOpen(false);
      setReload(true);
    }
  };

  const onFilterCheckboxChange = async (item: AdminFilterSet, event: any) => {
    if (event.target?.checked) {
      const formData: accountBlockedSiteViewModel = {
        matchType: "set",
        deviceId: deviceReference || currentAccountDeviceReference,
        kiboshDeviceReference: kiboshDeviceReference || currentAccountKiboshDeviceReference,
        match: item.id!
      }

      let response: ApiResponse;

      setLoadingAccountSetBlockedSites(true);
      const data = convertToSnakecase(formData, [], ["kiboshDeviceReference", "deviceId"]);

      response = await apiCall(DashApi.insertClientDeviceFilter(formData.kiboshDeviceReference, formData.deviceId, data, abortController.signal));

      if (response.success) {
        message.success(`Blocked Site setted successfully.`);
        getAccountSetBlockedSitesAsync();

      } else
        message.error(response.error?.value);

      setLoadingAccountSetBlockedSites(false);
    } else {
      setLoadingAccountSetBlockedSites(true);
      const formData = accountSetBlockedSites.find(x => x.match?.toLocaleLowerCase() === item.id?.toLocaleLowerCase());
      if (!formData) {
        message.error(`Item not found.`);
        setLoadingAccountSetBlockedSites(false);
        return;
      }
      const response = await apiCall(DashApi.deleteClientDeviceFilter(kiboshDeviceReference || currentAccountKiboshDeviceReference!, deviceReference || currentAccountDeviceReference, formData.id!));
      setLoadingAccountSetBlockedSites(false);

      if (response.success) {
        message.success(`Account Blocked Site deleted successfully.`);
        setLoadingAccountSetBlockedSites(true);
        getAccountSetBlockedSitesAsync();
      }
    }
  }

  return (
    <>
      {loading &&
        <Loader />
      }
      <view data-direction="column">
        {(!kiboshDeviceReference || !deviceReference) &&
          <group data-space="15" data-gap="20" data-border="">
            {!kiboshDeviceReference && <>
              <Select
                autoComplete=""
                label="Kibosh Device"
                labelPosition="left"
                dataLength="320"
                onChange={onAccountKiboshDeviceChange}
                loading={loadingAccountKiboshDevices}
                allowSearch={true}
                options={
                  accountKiboshDevices?.map((item) => {
                    return {
                      text: `${item.kiboshDeviceName!} (${item.kiboshDeviceReference!})`,
                      value: item.kiboshDeviceReference!,
                    };
                  }) || []
                }
                button={
                  <Button
                    material
                    icon="refresh"
                    onClick={getAccountKiboshDevicesAsync}
                  />
                }
              />
            </>}
            {!deviceReference && <>
              <Select
                autoComplete=""
                label="Device"
                labelPosition="left"
                dataLength="320"
                onChange={onAccountDeviceChange}
                loading={loadingAccountDevices}
                allowSearch={true}
                options={
                  accountDevices?.map((item) => {
                    return {
                      text: `${item.name!} (${item.id!})`,
                      value: item.id!,
                    };
                  }) || []
                }
                button={
                  <Button
                    material
                    icon="refresh"
                    onClick={getAccountDevicesAsync}
                  />
                }
              />
            </>}
          </group>}

        <view data-test="" data-direction="row">
          <Switchable
            // defaultExpanded={false}
            icon="tune"
            data-type="overlap"
            data-size="small"
            title={"Filters"}
            togglerProps={{ "data-order": "2" }}>
            {/* <group data-space='10'>


              {(loadingAdminFilterSets || loadingAccountSetBlockedSites) &&
                <Loader />
              }
              <group data-wrap="no" data-space="10" data-align="center" data-gap="5" ><text data-ellipsis="" data-index="1" data-weight="700">Admin defined filters</text></group>
              <group className="options" data-name="custom_checkbox">
                {adminFilterSets.map((item) =>
                  <>
                    <Checkbox
                      checkboxType={CheckboxType.Button}
                      data-interactive=""
                      icon={item.icon && <picture data-object-fit='contain'><img src={`data:image/png;base64,${item.icon}`} alt="Filter Set Icon" /></picture>}
                      checked={accountSetBlockedSites.map(x => x.match?.toLocaleLowerCase()).includes(item.id)}
                      onChange={(event: any) => onFilterCheckboxChange(item, event)}
                      label={item.name}
                      dataLength="auto" />
                  </>
                )}</group>
            </group>
            <group data-space-horizontal="10"><separator horizontal="" data-margin='none'></separator></group> */}
            <group data-space='10'>
              <group data-wrap="no" data-space="10" data-align="center" data-gap="5" ><text data-ellipsis="" data-index="1" data-weight="700">Admin Defined Site Filters</text></group>
              {(loadingAdminFilterSets || loadingAccountSetBlockedSites) &&
                <Loader />
              }<group>
                {adminFilterSets.map((item) =>
                  <>
                  <label className={accountSetBlockedSites.map(x => x.match?.toLocaleLowerCase()).includes(item.id) ? "checked" : "unchecked"} data-type="group" data-wrap="no" data-space="10" data-space-horizontal="15" data-align="center" data-cursor="pointer" data-interactive="" data-contain="">
                    <group data-position="left" data-align="center" data-wrap="no" data-contain="" data-gap="10">
                      {item.icon && <group data-length="30" data-radius="30" data-contain=""><picture data-object-fit='cover'><img src={item.icon && item.icon.toString().startsWith('data:image') ? item.icon : `data:image/png;base64,${item.icon}`} alt="Filter Set Icon" /></picture></group>}
                      <group data-length="autofit" data-direction="column" data-contain="" >
                        <text data-weight="700" data-text-size="" data-ellipsis="">{item.name}</text>
                        {
                          !!accountSetBlockedSites.map(x => x.match?.toLocaleLowerCase()).includes(item.id) &&
                          <text data-weight="700" data-color="main" data-text-size="10" >Blocked</text>
                        }
                      </group>
                    </group>
                    <Checkbox
                      data-position="right"
                      checked={accountSetBlockedSites.map(x => x.match?.toLocaleLowerCase()).includes(item.id)}
                      onChange={(event: any) => onFilterCheckboxChange(item, event)}
                      simple
                       />
                  </label>
                  <separator horizontal="" data-margin="none"></separator>
                  </>
                )}</group>
            </group>
          </Switchable>
          <view data-name="switchable" data-direction="column" data-align="start">
            <view
            //data-scroll=""
            >
              <group
                data-align="center"
                data-gap="20"
                data-elevation="1"
                data-index="2"
                data-backdrop=""
                data-wrap="no"
                data-sticky="top"
                data-space="15"
              >
                <Button
                  material
                  icon="add"
                  text="New"
                  primary
                  onClick={onNewClick}
                />
                <Button
                  data-position="right"
                  material
                  icon="refresh"
                  text="Refresh"
                  outline
                  onClick={() => setReload(true)}
                />
              </group>
              <view className="table_cont">
                <DataTable columns={columns}
                  bordered={true}
                  customPagination={true}
                  reload={reload}
                  dataPagination={true}
                  getPageAsync={getPage}
                  loading={loading}
                  rowKey={record => `${record.id}`}
                  showTotal={true}
                  size="small"
                  totalRecords={totalRecords}
                  onRow={(record) => ({
                    onDoubleClick: () => onEditClick(record),
                    style: { cursor: "pointer" },
                  })}
                />
              </view>
            </view>
          </view>
        </view>
      </view>
      {!!showAccountBlockedSiteModal &&
        <AccountBlockedSiteModal open={showAccountBlockedSiteModal}
          account={account!}
          closeOnSave={true}
          deviceReference={deviceReference || currentAccountDeviceReference}
          kiboshDeviceReference={kiboshDeviceReference || currentAccountKiboshDeviceReference}
          accountBlockedSite={currentAccountBlockedSite}
          onClose={() => setShowAccountBlockedSiteModal(false)}
          onSave={(e) => onAccountBlockedSiteSave(e)} />
      }
      {isDeleteConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Delete"
          positiveCallback={handleDeleteRequest}
          negativeCallback={() => {
            setIsDeleteConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
