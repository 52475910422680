// defines
const {
  REACT_APP_REQUEST_TIMEOUT,
  REACT_APP_DEFAULT_TABLE_PAGE_SIZE,
  REACT_APP_API_URL,
  REACT_APP_LOGIN_PAGE_URL,
  REACT_APP_GOOGLE_MAPS_KEY,
  REACT_APP_GOOGLE_MAP_ID,
} = process.env;

if (!REACT_APP_REQUEST_TIMEOUT) throw Error('REACT_APP_REQUEST_TIMEOUT env. var. is required.');
if (!REACT_APP_API_URL) throw Error('REACT_APP_API_URL env. var. is required.');
if (!REACT_APP_LOGIN_PAGE_URL) throw Error('REACT_APP_LOGIN_PAGE_URL env. var. is required.');

export interface IConfig {
  apiUrl: string;
  defaultTablePageSize: number;
  loginPageUrl: string;
  requestTimeout: number;
  googleMapsKey: string;
  googleMapId: string;
}

const Config: IConfig = {
  apiUrl: REACT_APP_API_URL,
  defaultTablePageSize: parseInt(REACT_APP_DEFAULT_TABLE_PAGE_SIZE || '50'),
  loginPageUrl: REACT_APP_LOGIN_PAGE_URL,
  requestTimeout: parseInt(REACT_APP_REQUEST_TIMEOUT),
  googleMapsKey: REACT_APP_GOOGLE_MAPS_KEY || '',
  googleMapId: REACT_APP_GOOGLE_MAP_ID || ''
}

export default Config;
