// 3rd-party modules
import { message } from 'antd';
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useState } from "react";

// project modules
import Input from "../shared/inputs/input";
import Loader from "../shared/loader";
import Popup from '../shared/popup/popup';
import yup from "../../plugins/yup";
import { apiCall } from "../../helpers/apiHelper";

// apis
import * as CommonValueApi from '../../apis/commonValueApi';

// models
import { ApiResponse } from '../../models/response';
import { CommonValue } from "../../models/commonValue";
import { commonValueViewModel } from '../../models/types/commonValue';

// defines
type Props = {
  closeOnSave?: boolean;
  open: boolean;
  commonCode: string;
  commonCodeTitle: string;
  commonValue: CommonValue;
  onClose?: () => void;
  onSave?: (commonValue: CommonValue) => void;
};

export default function CommonValueModal({ closeOnSave = false, commonValue, commonCode, commonCodeTitle, open, onClose, onSave }: Props) {
  const schema = yup.object().shape({
    value: yup.string().label("Value").required(),
    valueCaption: yup.string().label("Value Caption").required(),
  });
  const { control, handleSubmit, setValue, getValues } = useForm<commonValueViewModel | any>({
    defaultValues: { ...commonValue },
    resolver: yupResolver(schema),
  });
  const [loading, setLoading] = useState(false);
  const abortController = new AbortController();

  const onCancel = () => {
    abortController.abort();

    if(onClose) onClose();
  };

  const onValueCaptionFocus = (e: any) => {
    if (!e.target.value) {
      const value = getValues("value");
      setValue("valueCaption" , value?.toString()?.toUpperCase())
    }
  }

  const onSubmit: SubmitHandler<commonValueViewModel> = async (formData: commonValueViewModel) => {
    let response: ApiResponse;

    setLoading(true);

    formData.commonCode = commonCode;
    formData.type = "string";

    if (!commonValue?.commonValueId && commonValue?.commonValueId !== 0)
      response = await apiCall(CommonValueApi.createCommonValue(formData, abortController.signal));
    else {
      formData.commonValueId = commonValue.commonValueId!;
      response = await apiCall(CommonValueApi.updateCommonValue(formData, abortController.signal));
    }

    if (response.success) {
      message.success(`Setting ${!commonValue?.commonValueId && commonValue?.commonValueId !== 0 ? 'added' : 'edited'} successfully.`);

      if (onSave) onSave(CommonValue.toClass(response.data?.value));

      if (closeOnSave) {
        open = false;

        onCancel();
      }

    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  return (
    <Popup
      title={`${commonCodeTitle ? "Setting: " + commonCodeTitle + " - " : ""}${(!commonValue.commonValueId && commonValue.commonValueId !== 0 ? "New Item Setting" : `${commonValue.valueCaption}`)}`}
      onCancel={onCancel}
      onClose={onCancel}
      onSave={handleSubmit(onSubmit)}
      fixSize="small"
    >
      { loading &&
        <Loader />
      }
      <group data-space="15" data-gap="10" data-direction="column">
        <Input
          control={control}
          name="value"
          label="Value"
          dataLength="auto"
          size="large"
        />
        <Input
          control={control}
          name="valueCaption"
          label="Value Caption"
          dataLength="auto"
          size="large"
          onFocus={onValueCaptionFocus}
        />
      </group>
    </Popup>
  );
}
