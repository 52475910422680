//3rd-party modules
import { useEffect, useState } from "react";

// project modules
import Loader from "../../shared/loader";
import { apiCall } from "../../../helpers/apiHelper";

// apis
import * as ReportApi from '../../../apis/reportApi';

const getAccountSummary = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(ReportApi.getAccountSummary(abortSignal));

  return response.success ? response.data?.value :null;
};

export default function AccountSummary() {
  // const [accountSummary, setAccountSummary] = useState<any | null>(null);
  const [datasource, setDatasource] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const abortController = new AbortController();

  useEffect(() => {
    const getAccountSummaryAsync = async () => {
      setLoading(true);
      const data = await getAccountSummary(abortController.signal)
      // setAccountSummary(data);

      let dataItems: any[] = [];
      dataItems.push({
        title: "Active",
        value: data?.active_accounts_count || "0"
      })
      dataItems.push({
        title: "24 Hours",
        value: data?.accounts_signedup_last24hours || "0"
      })
      dataItems.push({
        title: "7 Days",
        value: data?.accounts_signedup_last7days || "0"
      })
      dataItems.push({
        title: "30 Days",
        value: data?.accounts_signedup_last_month || "0"
      })
      dataItems.push({
        title: "12 Months",
        value: data?.accounts_signedup_last_year || "0"
      })

      setDatasource(dataItems);


      setLoading(false);
    }

    getAccountSummaryAsync();
  }, []);

  return (
    <>
    {loading && <Loader />}
    <group data-space="20" data-border="" data-sticky="top" data-backdrop="" data-gap="30">
      <group data-contain="" data-width="auto" data-space="5">
        <text data-ellipsis="" data-index="1" data-weight="700" data-text-size="large">Account Summary</text>
      </group>
      {datasource.map(item =>
        (
            // <group data-background="main-background" data-border="" data-contain="" data-width="auto" data-space-vertical="5" data-radius="5">
          //   <group data-gap="10" data-width="auto" data-space-horizontal="10">
          //     <group data-align="center" data-width="auto" data-gap="10" data-wrap="no">
          //       <text data-index="1" data-light="">{item.title}</text>
          //       <separator vertical="dotted"></separator>
          //       <text data-weight="700">{item.value}</text>
          //     </group>
          //   </group>
          // </group>
          <group data-contain="" data-width="auto" data-space-vertical="5" data-radius="5">
            <group data-width="auto" data-space-horizontal="10">
              <group data-width="auto">
                <group data-align="center" data-justify="center">
                  <text data-weight="700" data-align="center" data-text-size="large">{item.value}</text>
                </group>
                <group data-align="center" data-justify="center">
                  <text data-index="1" data-light="" data-align="center">{item.title}</text>
                </group>
              </group>
            </group>
          </group>
        )
      )}
    </group>



      {/* <group data-space='20' data-border='' data-sticky="top" data-backdrop=''><text data-ellipsis="" data-index="1" data-weight="700">Account Summary</text></group>
      {loading && <Loader />}
      <group data-gap="10" data-space="20">
        <DashboardWidget title="Active Accounts" value={accountSummary?.active_accounts_count || "0"} height="400" minimized={true}>
        </DashboardWidget>
        <DashboardWidget title="Accounts Last 24 Hours" value={accountSummary?.accounts_signedup_last24hours || "0"} description="Accounts signed up last 24 hours" height="400" minimized={true}>
        </DashboardWidget>
        <DashboardWidget title="Accounts Last 7 Days" value={accountSummary?.accounts_signedup_last7days || "0"} description="Accounts signed up last 7 days" height="400" minimized={true}>
        </DashboardWidget>
        <DashboardWidget title="Accounts Last 30 Days" value={accountSummary?.accounts_signedup_last_month || "0"} description="Accounts signed up last 30 days" height="400" minimized={true}>
        </DashboardWidget>
        <DashboardWidget title="Accounts Last 12 Months" value={accountSummary?.accounts_signedup_last_year || "0"} description="Accounts signed up last 12 months" height="400" minimized={true}>
        </DashboardWidget>
      </group> */}
    </>
  );
}
