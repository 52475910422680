// 3rd-party modules
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

// project modules
import { checkUserAccess } from '../../helpers/authHelper';
import { RootState } from '../../stores';

// models
import { SubNavigationItem, TopNavigationItem } from '../../models/types/navigation';
import { User } from '../../models/auth';

// defines
const topNavigationItems: TopNavigationItem[] = [
  {
    text: 'Sales',
    link: '/sales',
    children: [
      {
        text: 'Dashboard',
        link: '/sales'
      },
      {
        text: 'Accounts',
        link: '/accounts'
      },
      {
        text: 'Billing',
        link: '/sales/billing'
      }
    ]
  },
  {
    text: 'Support',
    link: '/support',
    children: [
      {
        text: 'Dashboard',
        link: '/support'
      },
      {
        text: 'Filter Sets',
        link: '/support/filter-sets'
      },
      {
        text: 'Help Desk',
        link: '/support/help-desk'
      },
      {
        text: 'Archive',
        link: '/support/archive'
      }
    ]
  },
  {
    text: 'Accounts',
    link: '/accounts',
  },
  {
    text: 'Admin',
    roles: ['System-Admin'],
    children: [
      {
        text: 'Web Content',
        link: '/admin/posts'
      },
      {
        text: 'System Users',
        link: '/admin/system-users'
      },
      {
        text: 'Settings',
        link: '/admin/settings'
      },
      {
        text: 'Revenue Summary',
        link: '/admin/revenue-summary'
      },
      {
        text: "Import Devices",
        link: '/admin/import-devices'
      }
    ]
  }
];

type Props = {
  onNavItemSelect?: (navItems: SubNavigationItem[]) => void;
};

export default function TopNavigation({ onNavItemSelect }: Props) {
  const location = useLocation();
  const [currentSelectedItem, setCurrentSelectedItem] = useState('');
  const [currentLink, setCurrentLink] = useState('/');
  const user = useSelector((state: RootState) => state.user.currentUser) as User;

  // filter navigation items based on user roles
  let userNavigationItems: TopNavigationItem[] = topNavigationItems.filter(item => checkUserAccess(user, item.roles || ['*']));
  userNavigationItems.forEach(navItem => navItem.children?.filter(item => checkUserAccess(user, item.roles || ['*'])));

  for (let navItem of userNavigationItems) {
    navItem.children = navItem.children?.filter(item => checkUserAccess(user, item.roles || ['*']))
  }

  useEffect(() => {
    const current = `/${location.pathname.substring(1)}`;
    const selectedItems = topNavigationItems.filter(x => x.link === current || x.children?.filter(y => y.link === current).length);

    if (selectedItems.length) {
      onNavItemClick(selectedItems[0]);
    }
  }, []);

  useEffect(() => {
    setCurrentLink(`/${location.pathname.substring(1)}`);
  }, [location]);

  const isSelectedItem = (item: TopNavigationItem): Boolean => {
    return (
      currentSelectedItem &&
      currentSelectedItem === item.text
    ) ||
    (
      !currentSelectedItem &&
      (
        currentLink === item.link || !!item.children?.filter(x => x.link === currentLink).length
      )
    );
  }

  const onNavItemClick = (item: TopNavigationItem) => {
    setCurrentSelectedItem(item.text);

    if (onNavItemSelect) {
      onNavItemSelect(item.children || [])
    }
  };

  return (
    <>
      <group
        data-width="auto"
        data-align="center"
        data-gap="15"
        data-contain=""
      >
        <nav launcher="" >
          <ul>
            {userNavigationItems.map(item =>
            <li className={isSelectedItem(item) ? 'selected' : undefined} key={item.text}>
              <Link to={item.link || ""} data-gap="5" onClick={() => onNavItemClick(item)}>
                <text data-space="15" data-ellipsis="">{item.text}</text>
              </Link>
            </li>)
            }
          </ul>
        </nav>
      </group>
    </>
  );
}
