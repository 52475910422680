// 3rd-party modules
import { message } from "antd";
import { useEffect, useState } from "react";

// project modules
import { apiCall } from "../../../../../helpers/apiHelper";
import Button from "../../../../shared/button";
import ConfirmationPopup from "../../../../shared/popup/confirmationPopup";
import Loader from "../../../../shared/loader";

// apis
import * as DashApi from '../../../../../apis/dashApi';

// models
import { Account } from "../../../../../models/account";
import { AccountKiboshDevice } from "../../../../../models/accountKiboshDevice";

type Props = {
  account?: Account;
  accountKiboshDevice?: AccountKiboshDevice;
  refresh?: boolean;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
};

export default function KiboshDeviceFirmwareUpgrade({ account, refresh, accountKiboshDevice, onCancel, onSave }: Props) {
  const [isUpgradeConfirmationPopupOpen, setIsUpgradeConfirmationPopupOpen] = useState(false);
  const [needsClearSettings, setNeedsClearSettings] = useState<boolean>(false);
  const [currentVersion, setCurrentVersion] = useState<string>("");
  const [latestVersion, setLatestVersion] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const abortController = new AbortController();

  useEffect(() => {
    getAccountKiboshDeviceStatusAsync();
  }, []);

  const getAccountKiboshDeviceStatusAsync = async () => {
    const data = await getAccountKiboshDeviceStatus();
    setCurrentVersion(data?.kiboshDevice?.current_firmware_version);
    setLatestVersion(data?.latestFirmwareVersion);
  }

  const getAccountKiboshDeviceStatus = async () => {
    setLoading(true);
    const response = await apiCall(DashApi.getKiboshDeviceStatus(accountKiboshDevice?.kiboshDeviceReference!, abortController.signal));
    setLoading(false);

    return response.success ? response.data?.value : null;
  };

  const onUpgradeClick = () => {
    setNeedsClearSettings(false);
    setIsUpgradeConfirmationPopupOpen(true);
  };

  const onResetFactoryClick = () => {
    setNeedsClearSettings(true);
    setIsUpgradeConfirmationPopupOpen(true);
  }

  const handleUpgradeRequest = async () => {
    setLoading(true);
    const response = await apiCall(DashApi.updateKiboshDeviceStatus(accountKiboshDevice?.kiboshDeviceReference!, needsClearSettings, abortController.signal));
    setLoading(false);

    if (response.success) {
      message.success(`Device Firmware upgraded successfully.`);
      setIsUpgradeConfirmationPopupOpen(false);
      getAccountKiboshDeviceStatusAsync();
    } else
      message.error(response.error?.value);
  };

  return (
    <>
      {loading &&
        <Loader />
      }
        <view>
          <view data-scroll="">
            <group data-space='15'>
              <group data-direction="column" data-gap='10'>
                <group data-direction="column" data-gap='10' data-length='400'>
                  <group data-gap='10' data-wrap="no" data-border='' data-radius='5' data-background="main-lighter" data-align='center' data-space='10' style={{backgroundColor: currentVersion !== latestVersion ? "#fde7e4" : null}}><text>Current Version</text><separator horizontal="dotted"></separator><text>{currentVersion}</text></group>
                  <group data-gap='10' data-wrap="no" data-border='' data-radius='5' data-background="main-lighter" data-align='center' data-space='10'><text>Latest Version</text><separator horizontal="dotted"></separator><text>{latestVersion}</text></group>
                </group>
                {/* <group data-direction="column" data-gap='10'>
                  <Checkbox
                      disabled={currentVersion === latestVersion}
                      label="Clear Settings"
                      dataLength="auto"
                      checked={needsClearSettings}
                      onChange={onClearSettingsCheckboxChange} />
                  { !!currentVersion && currentVersion === latestVersion &&<group data-align='center' data-gap='5'><icon data-color='main'>info</icon><text data-color='main' data-weight='600'>Your Firmware is up to date.</text></group>}
                </group> */}
              </group>
            </group>
          </view>
          <group data-space="10" data-gap="10">
            <Button disabled={currentVersion === latestVersion} data-length="forcefit" primary onClick={onUpgradeClick}>
              <text>Upgrade Firmware to Latest Version</text>
            </Button>
            <Button data-length="forcefit" outline style={{backgroundColor: "#f26f5f", color: "#fff"}} onClick={onResetFactoryClick}>
              <text>Reset to Factory Clearing All Settings</text>
            </Button>
            <Button data-length="forcefit" outline onClick={getAccountKiboshDeviceStatusAsync}>
              <text>Refresh</text>
            </Button>
          </group>
      </view>
      {isUpgradeConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          message={`Do you want to upgrade this device ${needsClearSettings ? "with" : "without"} clear the settings?`}
          positiveButtonText="Upgrade"
          positiveCallback={handleUpgradeRequest}
          negativeCallback={() => {
            setIsUpgradeConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
