//3rd-party modules
import { useEffect, useState } from "react";

// project modules
import DashboardWidget from "../../global/dashboardWidget";
import DataTable, { TableColumn } from "../../shared/list/dataTable";
import Loader from "../../shared/loader";
import { apiCall } from "../../../helpers/apiHelper";
import { formatMoney } from "../../../helpers/objectHelper";

// apis
import * as ReportApi from '../../../apis/reportApi';

const getRevenueSummary = async (abortSignal?: AbortSignal) => {
  const response = await apiCall(ReportApi.getRevenueSummary(abortSignal));

  return response.success ? response.data?.value :null;
};

export default function RevenueSummary() {
  const columns: TableColumn<any>[] = [
    {
      title: <text data-ellipsis="" data-weight="700" data-space-horizontal="20">Total</text>,
      dataIndex: 'sum',
      key: 'sum',
      render: text => <text data-ellipsis="" data-weight="700" data-space-horizontal="20">{formatMoney(Number(text) / 100, 2)}</text>
    },
    {
      title: <text data-ellipsis="" data-weight="700" data-space-horizontal="20">Currency</text>,
      dataIndex: 'currency',
      key: 'currency',
      //width: 100,
      render: text => <text data-ellipsis="" data-weight="700" data-space-horizontal="20">{text.toUpperCase()}</text>
    },
  ];
  const [loading, setLoading] = useState(false);
  const [revenueSummary, setRevenueSummary] = useState<any | null>(null);
  const abortController = new AbortController();

  useEffect(() => {
    const getRevenueSummaryAsync = async () => {
      setLoading(true);
      setRevenueSummary(await getRevenueSummary(abortController.signal));
      setLoading(false);
    }

    getRevenueSummaryAsync();
  }, []);

  return (
    <>
      <group data-space='20' data-border='' data-sticky="top" data-backdrop=''><text data-ellipsis="" data-index="1" data-weight="700">Revenue Summary</text></group>
      {loading && <Loader />}
      <group data-type="grid" data-gap="10" data-space="20" data-grid-template="300">
        <DashboardWidget title="Weekly Revenue" withoutSpaces={true}>
          <DataTable
            columns={columns}
            bordered={false}
            pagination={false}
            dataSource={revenueSummary?.accountWeeklyRevenueReport}
            loading={loading}
            rowKey={record => `${record.currency}`}
            showTotal={false}
            size="small"
          />
        </DashboardWidget>
        <DashboardWidget title="Monthly Revenue" withoutSpaces={true}>
          <DataTable
            columns={columns}
            bordered={false}
            pagination={false}
            dataSource={revenueSummary?.accountMonthlyRevenueReport}
            loading={loading}
            rowKey={record => `${record.currency}`}
            showTotal={false}
            size="small"
            />
        </DashboardWidget>
        <DashboardWidget title="Yearly Revenue" withoutSpaces={true}>
          <DataTable
            columns={columns}
            bordered={false}
            pagination={false}
            dataSource={revenueSummary?.accountYearlyRevenueReport}
            loading={loading}
            rowKey={record => `${record.currency}`}
            showTotal={false}
            size="small"
          />
        </DashboardWidget>
      </group>
    </>
  );
}
