// project modules
import { getBaseUrl, sendRequest } from '../helpers/apiHelper';

// models
import { DataSourceRequest } from '../models/dataSourceRequest';
import { changePasswordViewModel, loginViewModel, registerViewModel, roleCrudViewModel, updateViewModel, userRolesViewModel } from "../models/types/auth";
import { User } from '../models/auth';

export const loginUser = async (user: loginViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/auth/login`, 'post', user, null, abortSignal);
};

export const changePassword = async (user: changePasswordViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/auth/changePassword`, 'post', user, null, abortSignal);
};

export const getUsers = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/auth/user/list`, 'get', request, null, abortSignal);
};

export const insertUser = async (user: registerViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/auth/register`, 'post', user, null, abortSignal);
};

export const updateUser = async (user: updateViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/auth/user`, 'put', user, null, abortSignal);
};

export const deleteUser = async (user: User, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/auth/user`, 'delete', user.username, null, abortSignal);
};

export const createRole = async (role: roleCrudViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/auth/role`, 'post', role, null, abortSignal);
};

export const updateRole = async (role: roleCrudViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/auth/role`, 'put', role, null, abortSignal);
};

export const deleteRole = async (role: roleCrudViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/auth/role`, 'delete', role, null, abortSignal);
};

export const addUserToRoles = async (username: string, roleNames: string[], abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/auth/user/role`, 'post', {
    username,
    roleNames
  }, null, abortSignal);
};

export const removeUserFromRoles = async (userRoles: userRolesViewModel, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/auth/user/role`, 'delete', userRoles, null, abortSignal);
};

export const getRoles = async (abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/auth/role/list`, 'get', null, null, abortSignal);
};

export const getUserRoles = async (username: string, abortSignal?: AbortSignal) => {
  return await sendRequest(`${getBaseUrl()}/auth/user/roles/${username}`, 'get', null, null, abortSignal);
};
