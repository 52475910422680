import { Pagination, TablePaginationConfig } from 'antd';
import Select from '../inputs/select';
import { Text } from '../text';

export const PaginationToolbar: React.FC<{
  total?: number;
  pagination: TablePaginationConfig;
  setPagination: Function;
  summaryList?: any[];
  onChange?: (pagination: TablePaginationConfig) => void;
}> = ({ total = 0, pagination, setPagination, summaryList = [], onChange }) => (
  <group>
    {(total > 0 || summaryList.length) && (
      <group
        data-align="center"
        data-space="5"
        data-border=""
        data-gap="10">
        { total > 0 &&
        <>
          <Pagination
            {...pagination}
            size="small"
            showSizeChanger={false}
            total={total}
            onChange={(current) => {
              setPagination({ ...pagination, current });

              if (onChange)
                onChange({ ...pagination, current });
            }}
          />

          <group data-width="auto" data-align="center">
            <Text light>Size</Text>

            <Select
              showAction={['focus']}
              popupMatchSelectWidth={false}
              borderLess
              bordered={false}
              options={[10, 25, 50, 100, 200, 500]}
              value={pagination.pageSize}
              onChange={(value: any) => {
                setPagination({ ...pagination, pageSize: value });

                if (onChange)
                  onChange({ ...pagination, pageSize: value });
              }}
            />
          </group>
          <separator vertical="dotted" data-height="20"></separator>

          <Text light>Total</Text>
          <Text dataWeight="700">{total}</Text>

          {!!summaryList.length && <separator vertical="dotted" data-height="20"></separator>}
        </>}
        {summaryList.map(summary => <>
          <Text light>{summary.text}</Text>
          <Text dataWeight="700">{summary.value}</Text>
        </>)}
      </group>
    )}
  </group>
);
