// 3rd-party modules
import { Tooltip, message } from 'antd';
import { useEffect, useState } from "react";

// project modules
import Button from '../../shared/button';
import ConfirmationPopup from '../../shared/popup/confirmationPopup';
import DataTable, { TableColumn } from '../../shared/list/dataTable';
import { apiCall } from "../../../helpers/apiHelper";

// apis
import * as DashApi from '../../../apis/dashApi';

// models
import AccountBlockedSiteModal from './AdminFilterSetElementModal';
import { AdminFilterSet, AdminFilterSetElement } from '../../../models/adminFilterSet';
import { FilterDescriptor, PaginationDescriptor, SortDescriptor } from '../../../models/dataSourceRequest';

type Props = {
  item?: AdminFilterSet;
  open: boolean;
};

export default function AdminFilterSetElements({ open = false, item }: Props) {
  useEffect(() => {
    if (open) {

    }
  }, [open]);

  const columns: TableColumn<AdminFilterSetElement>[] = [
    {
      title: 'Match',
      dataIndex: 'match',
      key: 'match',
      filterable: true,
    },
    {
      title: '',
      key: 'action',
      width: 60,
      render: (_, record: any) => (
        <group data-wrap='no' data-gap="5">
          <Tooltip title="Delete"><div className='button micro' onClick={() => onDeleteClick(record)}><icon>delete</icon></div></Tooltip>
        </group>
      ),
    }
  ];
  const [currentFilterSetElement, setCurrentFilterSetElement] = useState<AdminFilterSetElement>(new AdminFilterSetElement());
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  const [showFilterSetElementModal, setShowFilterSetElementModal] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  const onNewClick = () => {
    setCurrentFilterSetElement(new AdminFilterSetElement());
    setShowFilterSetElementModal(true);
  };

  const onDeleteClick = (filterSetElement: AdminFilterSetElement) => {
    setCurrentFilterSetElement(filterSetElement);
    setIsDeleteConfirmationPopupOpen(true);
  };

  const handleDeleteRequest = async () => {
    setLoading(true);
    const response = await apiCall(DashApi.deleteAdminFilterSetElement(item?.id!, currentFilterSetElement.id!));
    setLoading(false);

    if (response.success) {
      message.success(`Filter set element deleted successfully.`);
      setIsDeleteConfirmationPopupOpen(false);
      setReload(true);
    }
  };

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    if (!item?.id || !open) {
      return [];
    }
    setLoading(true);
    const response = await apiCall(DashApi.getAdminFilterSetElements(item?.id!, abortSignal));
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setLoading(false);

    return response.success ? AdminFilterSetElement.toArrayOfClass(response.data?.value?.filter_set_elements || []) : [];
  };

  return (
    <>
      <view data-space="10"  className="table_cont" data-background="none">
        <group data-space="15" data-gap="20" data-border="" data-background="highlight">
          <Button
            material
            icon="add"
            text="New"
            primary
            onClick={onNewClick}
          />
          <Button
            data-position="right"
            material
            icon="refresh"
            text="Refresh"
            outline
            onClick={() => setReload(true)}
          />
        </group>
        <DataTable columns={columns}
          bordered={true}
          customPagination={true}
          reload={reload}
          dataPagination={true}
          getPageAsync={getPage}
          loading={loading}
          rowKey={record => `${record.id}`}
          showTotal={true}
          size="small"
          totalRecords={totalRecords}
        />
      </view>
      {showFilterSetElementModal &&
        <AccountBlockedSiteModal open={showFilterSetElementModal}
          closeOnSave={true}
          adminFilterSet={item!}
          adminFilterSetElement={currentFilterSetElement}
          onClose={() => setShowFilterSetElementModal(false)}
          onSave={(e: any) => setReload(true)} />
      }
      {isDeleteConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Delete"
          positiveCallback={handleDeleteRequest}
          negativeCallback={() => {
            setIsDeleteConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
