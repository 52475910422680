// 3rd-party modules
import { Tooltip, message } from "antd";
import { useEffect, useState } from "react";

// project modules
import AccountDeviceModal from "./AccountDeviceModal";
import Button from "../../shared/button";
import DataTable, { TableColumn } from "../../shared/list/dataTable";
import Loader from "../../shared/loader";
import Select from "../../shared/inputs/select";
import { apiCall } from "../../../helpers/apiHelper";
import { getDeviceSignalRateClassIcon } from "../../../helpers";

// apis
import * as AccountDeviceApi from '../../../apis/accountDeviceApi';
import * as AccountKiboshDeviceApi from '../../../apis/accountKiboshDeviceApi';
import * as DashApi from '../../../apis/dashApi';

// models
import ConfirmationPopup from "../../shared/popup/confirmationPopup";
import ContextMenu from "../../shared/contextMenu/contextMenu";
import { Account } from "../../../models/account";
import { AccountDevice } from "../../../models/accountDevice";
import { AccountKiboshDevice } from "../../../models/accountKiboshDevice";
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from "../../../models/dataSourceRequest";

type Props = {
  account?: Account;
  accountKiboshDevice?: AccountKiboshDevice;
  refresh?: boolean;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
  onGroupSave?: () => void;
};

export default function AccountDevices({ account, refresh, accountKiboshDevice, onCancel, onSave, onGroupSave }: Props) {
  const columns: TableColumn<AccountDevice>[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      filterable: true,
      width: 150
    },
    {
      title: 'Device Name',
      dataIndex: 'name',
      key: 'name',
      filterable: true,
      render: (text:any , record:any) => (
        <>
          <group data-align="center">
            <text>{text}</text>
            {!!record.numSchedules &&

              <Tooltip title={`${record.numSchedules} Schedules`}><div className='button micro' onClick={() => onSelectedTab(record, "internet-schedules")}><icon>schedule</icon></div></Tooltip>
            }
          </group>
        </>)
    },
    {
      title: 'Device IP',
      dataIndex: 'ip',
      key: 'ip',
      filterable: true,
      width: 110
    },
    {
      title: 'Static IP',
      dataIndex: 'staticIp',
      key: 'staticIp',
      filterable: true,
      width: 110
    },
    {
      title: 'WiFi Signal',
      dataIndex: 'wifiSignal',
      key: 'wifiSignal',
      dataType: 'number',
      width: 90
    },
    {
      title: 'Ping',
      dataIndex: 'pingMs',
      key: 'pingMs',
      filterable: true,
      width: 100,
      render: (text: number) => text > 0 ? `${text.toFixed(2)}ms` : "disconnected"
    },
    {
      title: 'Connection Quality',
      key: 'connectionQuality',
      width: 100,
      render: (_, record: any) => (
        <group data-justify='center'>
          <icon>{getDeviceSignalRateClassIcon(record.pingMs!)}</icon>
        </group>
      )
    },
    // {
    //   title: 'Active Connections',
    //   dataIndex: 'activeConnections',
    //   key: 'activeConnections',
    //   filterable: true,
    //   dataType: 'number',
    //   width: 170
    // },
    {
      title: 'Connection',
      dataIndex: 'connectionStatus',
      key: 'connectionStatus',
      width: 100
    },
    {
      title: 'Activity Logs',
      dataIndex: 'loggingEnabled',
      key: 'loggingEnabled',
      dataType: 'bool',
      width: 110
    },
    {
      title: '',
      key: 'action',
      width: 60,
      render: (_, record: any) => (
        <group data-wrap='no' data-gap="5">
          <Tooltip title="Edit"><div className='button micro' onClick={() => onEditClick(record)}><icon>edit_square</icon></div></Tooltip>
          {/* <Tooltip title="Delete"><div className='button micro' onClick={() => onDeleteClick(record)}><icon>delete</icon></div></Tooltip> */}
          <separator vertical="" data-adaptive="desktop"></separator>
          <ContextMenu placement="bottomLeft" items={
            [
              {
                text: "Details",
                onClick: () => onSelectedTab(record, "details")
              },
              {
                text: "Blocked Sites",
                onClick: () => onSelectedTab(record, "blocked-sites")
              },
              {
                text: "Internet Schedules",
                onClick: () => onSelectedTab(record, "internet-schedules")
              },
              {
                text: "Activity Logs",
                onClick: () => onSelectedTab(record, "activity-logs")
              },
              {
                text: "Bandwidth Logs",
                onClick: () => onSelectedTab(record, "bandwidth-logs")
              }
            ]
          }>
            <Tooltip title="Tabs"><div className='button micro'><icon>more_vert</icon></div></Tooltip>
          </ContextMenu>
        </group>
      ),
    }
  ];
  const [accountKiboshDevices, setAccountKiboshDevices] = useState<AccountKiboshDevice[]>([]);
  const [currentAccountKiboshDevice, setCurrentAccountKiboshDevice] = useState<AccountKiboshDevice | null>(null);
  const [currentAccountDevice, setCurrentAccountDevice] = useState<AccountDevice>(new AccountDevice());
  const [isDeleteConfirmationPopupOpen, setIsDeleteConfirmationPopupOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingAccountKiboshDevices, setLoadingAccountKiboshDevices] = useState(false);
  const [preSelectedTab, setPreSelectedTab] = useState<string>("");
  const [reload, setReload] = useState<boolean>(false);
  const [showAccountDeviceModal, setShowAccountDeviceModal] = useState(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  let abortController = new AbortController();

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  useEffect(() => {
    if (refresh) {
      if (!accountKiboshDevice?.kiboshDeviceReference) {
        getAccountKiboshDevicesAsync();
      } else {
        setReload(true);
      }
    }
  }, [refresh]);

  const getAccountKiboshDevicesAsync = async () => {
    setAccountKiboshDevices(await getAccountKiboshDevices({}, abortController.signal));
  }

  const getAccountKiboshDevices = async (request?: DataSourceRequest, abortSignal?: AbortSignal) => {
    setLoadingAccountKiboshDevices(true);
    const response = await apiCall(AccountKiboshDeviceApi.getAccountKiboshDevices(account?.accountId!, request, abortSignal));
    setLoadingAccountKiboshDevices(false);

    return response.success ? AccountKiboshDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    if (!currentAccountKiboshDevice?.kiboshDeviceReference && !accountKiboshDevice?.kiboshDeviceReference)
      return [];

    setLoading(true);
    const response = await apiCall(DashApi.getClientDevices(accountKiboshDevice?.kiboshDeviceReference ? accountKiboshDevice?.kiboshDeviceReference : currentAccountKiboshDevice?.kiboshDeviceReference!, abortSignal));
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setLoading(false);

    return response.success ? AccountDevice.toArrayOfClass(response.data?.value || []) : [];
  };

  const handleDeleteRequest = async () => {
    setLoading(true);
    const response = await apiCall(AccountDeviceApi.deleteAccountDevice(currentAccountDevice));
    setLoading(false);

    if (response.success) {
      message.success(`Account Device deleted successfully.`);
      setIsDeleteConfirmationPopupOpen(false);
      setReload(true);
    }
  };

  const onAccountDeviceSave = (e: Account) => {
    setReload(true);
  }

  const onAccountKiboshDeviceChange = (kiboshDeviceReference: any) => {
    setCurrentAccountKiboshDevice(accountKiboshDevices.filter(x => x.kiboshDeviceReference === kiboshDeviceReference)[0]);
    setReload(true)
  }

  const onEditClick = (accountDevice: AccountDevice) => {
    setCurrentAccountDevice({...accountDevice});
    setPreSelectedTab("");
    setShowAccountDeviceModal(true);
  };

  // const onDeleteClick = (accountDevice: AccountDevice) => {
  //   setCurrentAccountDevice(accountDevice);
  //   setIsDeleteConfirmationPopupOpen(true);
  // };

  const onSelectedTab = (accountDevice: AccountDevice, selectedTab: any) => {
    if (selectedTab) {
      setCurrentAccountDevice({...accountDevice});
      setPreSelectedTab(selectedTab);
      setShowAccountDeviceModal(true);
    }
  };

  return (
    <>
      {loading && <Loader />}
      <view>
        {!accountKiboshDevice?.kiboshDeviceReference && (
          <group data-space="15" data-gap="20" data-border="">
            <Select
              autoComplete=""
              label="Kibosh Device"
              labelPosition="left"
              dataLength="320"
              onChange={onAccountKiboshDeviceChange}
              loading={loadingAccountKiboshDevices}
              allowSearch={true}
              options={
                accountKiboshDevices?.map((item) => {
                  return {
                    text: `${item.kiboshDeviceName!} (${item.kiboshDeviceReference!})`,
                    value: item.kiboshDeviceReference!,
                  };
                }) || []
              }
              button={
                <Button
                  material
                  icon="refresh"
                  onClick={getAccountKiboshDevicesAsync}
                />
              }
            />
          </group>
        )}

        <view className="table_cont" data-background="none">
          <group
            data-space="15"
            data-gap="20"
            data-border=""
            data-background="highlight"
          >
            {/* <Button
                    material
                    icon="add"
                    text="New"
                    primary
                    onClick={onNewClick}
                  /> */}
            <Button
              data-position="right"
              material
              icon="refresh"
              text="Refresh"
              outline
              onClick={() => setReload(true)}
            />
          </group>
          <DataTable
            columns={columns}
            bordered={true}
            customPagination={true}
            reload={reload}
            dataPagination={true}
            getPageAsync={getPage}
            loading={loading}
            rowKey={(record) => `${record.id}`}
            showTotal={true}
            size="small"
            totalRecords={totalRecords}
            onRow={(record) => ({
              onDoubleClick: () => onEditClick(record),
              style: { cursor: "pointer" },
            })}
          />
        </view>
      </view>
      {!!showAccountDeviceModal && (
        <AccountDeviceModal
          open={showAccountDeviceModal}
          account={account!}
          accountDevice={currentAccountDevice}
          accountKiboshDevice={
            accountKiboshDevice || currentAccountKiboshDevice!
          }
          preSelectedTab={preSelectedTab}
          onClose={() => setShowAccountDeviceModal(false)}
          onSave={(e) => onAccountDeviceSave(e)}
          onGroupSave={() => {if(onGroupSave) onGroupSave()}}
        />
      )}
      {isDeleteConfirmationPopupOpen && (
        <ConfirmationPopup
          showButton={false}
          positiveButtonText="Delete"
          positiveCallback={handleDeleteRequest}
          negativeCallback={() => {
            setIsDeleteConfirmationPopupOpen(false);
          }}
        />
      )}
    </>
  );
}
