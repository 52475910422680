// 3rd-party modules
import { Tooltip, message } from "antd";
import moment from "moment";
import { useEffect, useState } from "react";

// project modules
import { apiCall } from "../../../helpers/apiHelper";
import Button from "../../shared/button";
import { CONSTANTS } from "../../../helpers/defines";
import ConfirmationPopup from "../../shared/popup/confirmationPopup";
import { DataSourceRequest, FilterDescriptor, PaginationDescriptor, SortDescriptor } from "../../../models/dataSourceRequest";
import DataTable, { TableColumn } from "../../shared/list/dataTable";
import Loader from "../../shared/loader";

// apis
import * as AccountSubscriptionApi from '../../../apis/accountSubscriptionApi';

// models
import { Account } from "../../../models/account";
import { AccountSubscription } from "../../../models/accountSubscription";

// defines
type Props = {
  account?: Account;
  refresh?: boolean;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
};

export default function AccountSubscriptions({ account, refresh, onCancel, onSave }: Props) {
  const columns: TableColumn<AccountSubscription>[] = [
    {
      title: 'Customer id.',
      dataIndex: 'customerId',
      key: 'customerId',
      filterable: true,
      width: 250
    },
    {
      title: 'Kibosh Device id.',
      dataIndex: 'kiboshDeviceReference',
      key: 'kiboshDevicereference',
      filterable: true,
      width: 200
    },
    {
      title: 'Interval',
      dataIndex: 'interval',
      key: 'interval',
      filterable: true,
      width: 100
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      filterable: true,
      width: 100,
      render: text => text.toUpperCase()
    },
    {
      title: 'Start Date',
      dataIndex: 'currentPeriodStart',
      key: 'currentPeriodStart',
      filterable: true,
      dataType: 'datetime',
      render: (text: any) => <>{moment(text).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}</>,
      width: 120
    },
    {
      title: 'End Date',
      dataIndex: 'currentPeriodEnd',
      key: 'currentPeriodEnd',
      filterable: true,
      dataType: 'datetime',
      render: (text: any) => <>{moment(text).format(CONSTANTS.DEFAULT_DATETIME_FORMAT)}</>,
      width: 120
    },
    {
      title: 'Cancelation Date',
      dataIndex: 'cancelDate',
      key: 'cancelDate',
      filterable: true,
      dataType: 'datetime',
      render: (text: any) => <>{text ? moment(text).format(CONSTANTS.DEFAULT_DATETIME_FORMAT) : ""}</>,
      width: 130
    },
    {
      title: 'Canceled',
      dataIndex: 'canceled',
      key: 'canceled',
      filterable: true,
      dataType: 'bool',
      width: 100
    },
    {
      title: '',
      key: 'action',
      width: 30,
      render: (_, record: any) => (
        <group data-wrap='no' data-gap="5">
          <Tooltip title="Cancel Subscription">
            <Button
              micro
              icon="delete_history"
              disabled={record.canceled || undefined}
              onClick={() => onCancelClick(record)}/>
          </Tooltip>
        </group>
      ),
    }
  ];
  const [currentAccountSubscription, setCurrentAccountSubscription] = useState<AccountSubscription>(new AccountSubscription());
  const [loading, setLoading] = useState(false);
  const [isCancelConfirmationPopupOpen, setIsCancelCConfirmationPopupOpen] = useState(false);
  const [reload, setReload] = useState<boolean>(false);
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const abortController = new AbortController();

  useEffect(() => {
    if (reload) setReload(false);
  }, [reload]);

  const getPage = async (conditionList?: FilterDescriptor[], sortList?: SortDescriptor[], pagination?: PaginationDescriptor, abortSignal?: AbortSignal) => {
    const request = { conditionList, sortList, pagination } as DataSourceRequest;

    setLoading(true);
    const response = await apiCall(AccountSubscriptionApi.getAccountSubscriptions(account?.accountId!, request, abortSignal));
    setTotalRecords(response.success ? response.extra?.totalCount || 0 : 0);
    setLoading(false);

    return response.success ?
      AccountSubscription.toArrayOfClass(response.data?.value || []).map((item: AccountSubscription) => {
        let interval = "";

        if (item.currentPeriodStart && item.currentPeriodEnd) {
          const start = moment(item.currentPeriodStart);
          const end = moment(item.currentPeriodEnd);

          if (end.diff(start, "years") > 0) {
            interval = "Annual"
          } else if (end.diff(start, "months") > 0) {
            interval = "Monthly"
          } else if (end.diff(start, "weeks") > 0) {
            interval = "Weekly"
          } else if (end.diff(start, "days") > 0) {
            interval = "Daily"
          }
        }

        return {
          ...item,
          canceled: !!(moment() > moment(item.currentPeriodEnd) || item.cancelDate),
          interval
        }
      }) :
      [];
  };

  const onStripeCustomerPortalClick = async () => {
    setLoading(true);
    const response = await apiCall(AccountSubscriptionApi.getCustomerPortalSession(account?.accountId!, abortController.signal));
    setLoading(false);

    if (response.success) {
      const newWindow = window.open(response.data?.value.url, '_blank');

      newWindow?.focus();
    } else
      message.error(response.error?.value);
  };

  const onCancelClick = (accountSubscription: AccountSubscription) => {
    setCurrentAccountSubscription(accountSubscription);
    setIsCancelCConfirmationPopupOpen(true);
  };

  const handleCancelRequest = async () => {
    setLoading(true);
    const response = await apiCall(AccountSubscriptionApi.cancelSubscription(currentAccountSubscription));
    setLoading(false);

    if (response.success) {
      message.success(`Subscription canceled successfully.`);
      setReload(true);
    }
    setIsCancelCConfirmationPopupOpen(false);

    setReload(true);
  };

  return (
    <>
      {loading && <Loader />}
      <view>
        <view className="table_cont" data-background="none">
          <group
            data-space="15"
            data-gap="20"
            data-border=""
            data-background="highlight"
          >
            <group data-position="right" data-width='auto' data-gap='10' data-align='center'>
              <Button
                material
                text="Manage Subscriptions"
                outline
                disabled={totalRecords === 0}
                onClick={() => onStripeCustomerPortalClick()}
              />
              <separator vertical="" data-height="20"></separator>
              <Button
                data-position="right"
                material
                icon="refresh"
                text="Refresh"
                outline
                onClick={() => setReload(true)}
              />
            </group>
          </group>
          <DataTable
            columns={columns}
            bordered={true}
            customPagination={true}
            reload={reload}
            dataPagination={true}
            getPageAsync={getPage}
            loading={loading}
            rowKey={(record) => `${record.accountSubscriptionId}`}
            showTotal={true}
            size="small"
            totalRecords={totalRecords}
          />
        </view>
      </view>
        {isCancelConfirmationPopupOpen && (
          <ConfirmationPopup
            showButton={false}
            positiveButtonText="Cancel Subscription"
            positiveCallback={handleCancelRequest}
            negativeCallback={() => {
              setIsCancelCConfirmationPopupOpen(false);
            }}
          />
        )}
    </>
  );
}
