// 3rd-party modules
import { message } from "antd";
import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";

// project modules
import { apiCall } from "../../../../../helpers/apiHelper";
import Button from "../../../../shared/button";
import Checkbox from "../../../../shared/inputs/checkbox";
import { convertToSnakecase } from "../../../../../helpers/objectHelper";
import Loader from "../../../../shared/loader";
import Input, { Password } from "../../../../shared/inputs/input";
import yup from "../../../../../plugins/yup";

// apis
import * as DashApi from '../../../../../apis/dashApi';

// models
import { Account } from "../../../../../models/account";
import { AccountKiboshDevice } from "../../../../../models/accountKiboshDevice";
import { kiboshDeviceWifiConfigUpdateViewModel } from "../../../../../models/types/kiboshDeviceWifiConfig";
import { KiboshDeviceWifiConfig } from "../../../../../models/kiboshDeviceWifiConfig";
import { ApiResponse } from "../../../../../models/response";

type Props = {
  account?: Account;
  accountKiboshDevice: AccountKiboshDevice;
  refresh?: boolean;
  onCancel?: () => void;
  onSave?: (account: Account) => void;
};

export default function KiboshDeviceWifiSettings({ account, refresh, accountKiboshDevice, onCancel, onSave }: Props) {
  const [loading, setLoading] = useState(false);
  const abortController = new AbortController();

  const schema = yup.object().shape({
    radio24GhzEnabled: yup.bool().label("2.4GHz").required(),
    radio24GhzName: yup.string().label("2.4GHz Name").max(256),
    radio24GhzKey: yup.string().label("2.4GHz Key").max(256),
    radio5GhzEnabled: yup.bool().label("5GHz"),
    radio5GhzName: yup.string().label("2.4GHz Name").max(256),
    radio5GhzKey: yup.string().label("2.4GHz Key").max(256)
  });
  const { control, handleSubmit, reset } = useForm<kiboshDeviceWifiConfigUpdateViewModel | any>({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    getKiboshDeviceWifiConfigAsync();
  }, []);

  const getKiboshDeviceWifiConfigAsync = async () => {
    const data = await getKiboshDeviceWifiConfig();
    reset(data);
  }

  const getKiboshDeviceWifiConfig = async () => {
    setLoading(true);
    const response = await apiCall(DashApi.getKiboshDeviceWifiConfig(accountKiboshDevice?.kiboshDeviceReference!, abortController.signal));
    setLoading(false);

    return response.success && response.data?.value ? KiboshDeviceWifiConfig.toClass(response.data?.value) : null;
  };

  const onSubmit: SubmitHandler<kiboshDeviceWifiConfigUpdateViewModel> = async (formData: kiboshDeviceWifiConfigUpdateViewModel) => {
    setLoading(true);

    formData.kiboshDeviceReference = accountKiboshDevice.kiboshDeviceReference!;
    const data = convertToSnakecase(formData, [], ["kiboshDeviceReference"]);

    for (let prop of Object.keys(data)) {
      if (prop.includes("radio5")) {
        const newKey = prop.replace("radio5_", "radio_5");
        if (newKey !== prop) {
          data[newKey as keyof object] = data[prop as keyof object];
          delete data[prop as keyof object];
        }
      } else if (prop.includes("radio24")) {
        const newKey = prop.replace("radio24_", "radio_2_4");
        if (newKey !== prop) {
          data[newKey as keyof object] = data[prop as keyof object];
          delete data[prop as keyof object];
        }
      }
    }

    const response: ApiResponse = await apiCall(DashApi.updateKiboshDeviceWifiConfig(data, abortController.signal));

    if (response.success) {
      message.success(`Wifi setting updated successfully.`);

      getKiboshDeviceWifiConfigAsync();

      if (onSave) onSave(response.data?.value);

    } else
      message.error(response.error?.value);

    setLoading(false);
  };

  return (
    <>
      {loading &&
        <Loader />
      }
      <view>
        <view data-scroll="">
        <group data-gap="15" data-space='15'>
            <group data-space-right='20'  data-gap="10" data-direction="column" width='auto'>
              <Checkbox
                control={control}
                name="radio24GhzEnabled"
                label="2.4Ghz WiFi"
                minimal
                dataLength="auto" />
              <Input
                control={control}
                name="radio24GhzName"
                label="2.4Ghz WiFi Name"
                dataLength="auto"
                size="large"
              />
              <Password
                control={control}
                name="radio24GhzKey"
                label="2.4Ghz WiFi Passsword"
                dataLength="auto"
                size="large"
              />
            </group>
            <group  data-gap="10" data-direction="column" width='auto'>
              <Checkbox
                control={control}
                name="radio5GhzEnabled"
                label="5Ghz WiFi"
                minimal
                dataLength="auto" />
              <Input
                control={control}
                name="radio5GhzName"
                label="5Ghz WiFi Name"
                dataLength="auto"
                size="large"
              />
              <Password
                control={control}
                name="radio5GhzKey"
                label="5Ghz WiFi Passsword"
                dataLength="auto"
                size="large"
              />
            </group>
        </group>
        </view>
        <group data-space="10" data-gap="10">
          <Button data-length="forcefit" primary onClick={handleSubmit(onSubmit)}>
            <text>Save Changes</text>
          </Button>
        </group>
      </view>
    </>
  );
}
